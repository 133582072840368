
export const ManagmentErrors = (id) => {
    const listErrors = JSON.parse(localStorage.getItem("Errores"));
    let result = "Error inesperado."
    listErrors.forEach(element => {
        if (element.id === id) {
            result = element.descripcion;
            return;
        }
    });
    return result

}
