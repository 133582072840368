import React, { /* useEffect,  useState*/ } from 'react';
import { Row, Col/* , Button, Modal */ } from 'react-bootstrap';
//import { FormatMoney } from '../../../utilities/formats';
//import { ListPrices } from '../../../shared/components/list-prices';
//import NumberFormat from 'react-number-format';
/* import { Transaction } from '../../../services/product.service'
import { ManagmentErrors } from '../../../shared/components/managmentErrors';
import { useToasts } from 'react-toast-notifications'
import { getBox } from '../../../services/caja.service'; */
//import  DateTimePicker  from  'react-datetime-picker' ;
//import RNDateTimePicker from '@react-native-community/datetimepicker'; //modal fecha

export const DetailBox = (props) => { 
  
    const {detailBox} = props
       
    return (
        <>
            < style> {`.titleCol {font-weight:bold;} `} </ style>
            <div style={{ marginTop: 20, }} className='container'>
                <Row>
                    <Col>
                        <h4 className='titleCol'>Nombre caja: </h4>
                        <h5>{detailBox.nombre_caja}</h5>
                    </Col>
                    <Col>
                        <h4 className='titleCol'>Estado: </h4>
                        <h5>{detailBox.estado}</h5>
                    </Col>                   
                </Row>
                <Row>
                    <Col>
                        <h4 className='titleCol'>Balance inicial: </h4>
                        {/* <h5>{FormatMoney(detailBox.caja.balanceInicial)}</h5> */}
                        <h5>{detailBox.balanceInicial}</h5>
                    </Col>
                    <Col>
                        <h4 className='titleCol'>Balance final: </h4>
                        {/* <h5>{FormatMoney(detailBox.caja.balanceFinal)}</h5> */}
                        <h5>{detailBox.balanceFinal}</h5>
                    </Col>
                    <Col>
                        <h4 className='titleCol'> Total a cobrar: </h4>
                            {/* <h5>{FormatMoney(detailBox.caja.debito)}</h5> */}
                        <h5>{detailBox.debito}</h5>
                    </Col>                    
                    <Col>
                        <h4 className='titleCol'> Total ganancia: </h4>
                            {/* <h5>{FormatMoney(detailBox.caja.debito)}</h5> */}
                        <h5>{detailBox.ganancia}</h5>
                    </Col>
                    <Col>
                        <h4 className='titleCol'>Total a pagar: </h4>
                        {/* <h5>{FormatMoney(detailBox.caja.credito)}</h5> */}
                        <h5>{detailBox.credito}</h5>
                    </Col>
                </Row>
            </div>                    
        </>
    )
}

//const Prices = [ 10000, 20000,  30000, 50000, 'Otro valor' ]


