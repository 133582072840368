import React, { PureComponent } from 'react';
import { ListOperator, ListTypes } from '../../shared/components/list-operator';
import { Summary } from './components/summary';
import { Row, Col, Form, Card, Button, Image } from 'react-bootstrap';
import { Transaction } from '../../services/product.service';
import { Carrusel } from '../../shared/components/carousel';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ManagmentErrors } from '../../shared/components/managmentErrors';
import { LoadingMovilGo } from '../../shared/components/loadingMovilGo';
import { ModalNotificationTransaction } from '../../shared/components/modalNotificationTransaction';
import { jsPdfGenerator } from '../../shared/components/pdfTransaction';
import { FormatMoney } from '../../utilities/formats';

class PaquetesComponent extends PureComponent {

    constructor(props) {
        super(props);
        this.operators = [];
        this.listPackages = [];

        //Prueba
        //this.listPackages = this.props.listPackages;

        this.listCharges = [];
        this.state = {
            idPackage: -1,
            visible: false,
            loading: true,
            selectedOperator: { id: -1, image_medium: '', operador: '', },
            paquetes: [],
            selectedType: '',
            tagList: [],
            operators: [],
            precio: '',
            phone: '',
            vigencia: '',
            name: '',
            carrouselView: false,
            opacity: 1,
            modalNotification: false
        }
        this.packagesByOperator = {};
        this.response = [];
        this.phone = '';
        this.valor = '';
        this.operador = '';
        this.namePacket = '';
        this.clean = ''
    }

    cleanField = () => {

        this.setState({
            clean: false,
            name: '',
            price: '',
            phone: '',
            selectedOperator: { id: -1, image_medium: '', operador: '', },
            carrouselView: false
            //selectedOperator: {},        
        })
        //this.operador= '';
    }

    componentDidMount = () => {

        this.listPackages = this.props.listPackages;
        this.listCharges = this.props.listCharges;

        console.log("DID: listPackages", this.listPackages)

        if (this.listPackages) {
            /* if (this.listPackages||this.listCharges) { */
            // console.log("did")
            this.setState({ loading: false })
            /* PRUEBA this.setState({listPackages:this.listPackages, loading: false }) */
            this.initialice();
        }
    }

    initialice = () => {

        let operators = [];

        //console.log("initialice OPERATORS", operators)
        this.listPackages.forEach(element => {
            if (!this.packagesByOperator[element.operador]) {
                this.packagesByOperator[element.operador] = {};

                //console.log("ENTRA")
                for (let i = 0; i < this.listCharges.length; i++) {
                    if (this.listCharges[i].operador === element.operador) {
                        operators.push(this.listCharges[i]);
                    }
                }
            }
            if (!this.packagesByOperator[element.operador][element.categ_id_name]) {
                this.packagesByOperator[element.operador][element.categ_id_name] = [];
            }
            this.packagesByOperator[element.operador][element.categ_id_name].push(element);
        });

        this.setState({ operators });
    }

    componentWillUnmount = () => {
        this.packagesByOperator = {};
    }

    getResponse = (flag, response) => {
        // console.log("este es el response de paquetes ",response)
        if (flag) {
            this.response = response
            const success = () => toast.success("Paquete comprado exitosamente");
            success();
            this.setState({ loading: false, opacity: 1, modalNotification: true })
        } else {
            const message = ManagmentErrors(response.errores.id);
            const error = () => toast.error(message);
            error();
            this.setState({ loading: false, opacity: 1 })
        }
    }

    sendData = async () => {
        this.phone = this.state.phone;
        this.valor = this.state.price;
        this.namePacket = this.state.name;

        this.setState({ loading: true, opacity: 0.4 })
        const product = {
            product_id: this.state.idPackage,
            atributes: {
                numero: this.state.phone,
                precio: this.state.price,

            }
        }
        await Transaction(product, this.getResponse);
    }

    selectOperator = (operator) => {
        // console.log("PASO 1 RECARGA OPERADOR", operator.operador)
        this.cleanField();
        this.setState({
            selectedOperator: operator,
            tagList: Object.keys(this.packagesByOperator[operator.operador])
        });
        /* console.log("taglist:",this.packagesByOperator[operator.operador]) */
        //console.log("taglist2:",this.packagesByOperator) 
        //console.log("selectedOperator:",this.state.selectedOperator);
        this.setState({ carrouselView: false })

    }

    selectType = (type) => {
        /* console.log("PASO 2 TYPE: ", type)
        console.log("PAQUETES:",this.state.tagList); */
        //console.log("selectedOperator.operador: ", this.state.selectedOperator.operador)
        this.operador = type
        this.setState({
            paquetes:
                this.packagesByOperator[this.state.selectedOperator.operador][type]
        });
        this.setState({ carrouselView: true })
    }

    closeModal = (flag) => {
        this.cleanField()
        // console.log("la flag es", flag)
        this.setState({ modalNotification: flag });
        //console.log("response", this.response)

        const data = [
            "Factura No: " + this.response.valida.id,
            "Fecha:" + this.response.valida.fecha,
            "No.Aprobacion:" + this.response.valida.numero_aprobacion,
            "Producto: Paquete  " + this.state.name,
            "Celular: " + this.phone,
            "Valor: " + FormatMoney(this.valor)
        ]
        jsPdfGenerator(data);
    }

    render() {
        return (
            <>
                <style>
                    {` .my-auto {
                    margin-top: auto;
                    margin-bottom: auto;
                    } 
                    .reloadsCard {
                    box-shadow: 0px 2px 4px 1px rgba(0,0,0,0.17);
                    border-radius: 1em;
                    margin: 5px;
                    weigth: 50 ;
                    }
                    .contenedor{
                    padding-left: 10%;
                    padding-right: 10%;
                    }
                    ` }
                </style>
                <div className="contenedor" style={{ opacity: this.state.opacity }}>
                    <div className="col-md-12">
                        <h2 className={'defaultTitle'}>Paquetes</h2>
                        <ToastContainer />
                        <Row className="justify-content-md-center">
                            <Col>
                                {this.state.operators &&
                                    <ListOperator
                                        onClick={(operador) => this.selectOperator(operador)}
                                        operators={this.state.operators}
                                    />}
                            </Col>
                        </Row>
                        <Card className='reloadsCard'>
                            <Card.Body>
                                <Row className="justify-content-md-center">
                                    {<Col xs lg="2">
                                        {this.state.selectedOperator.image_medium &&
                                            <Image src={'data:image/jpeg;base64,' + this.state.selectedOperator.image_medium}
                                                style={{ maxHeight: '80px' }} />}
                                    </Col>}
                                    {<Col md="auto">
                                        <ListTypes clean={(flag) => this.setState({ clean: flag })}
                                            limpiar={this.state.clean}
                                            onClick={(type) => { this.selectType(type) }}
                                            types={this.state.tagList} />
                                    </Col>}
                                </Row><hr />
                                <Row>
                                    <Col xs={12} md={8} style={{ padding: "10px" }}>
                                        {this.state.carrouselView &&
                                            <Carrusel onClick={(namePacket, pricePacket, id) => {
                                                this.setState({ name: namePacket, price: pricePacket, idPackage: id })
                                            }}
                                                packages={this.state.paquetes}
                                                operador={this.state.selectedOperator.name}
                                                type={this.state.selectedType} />}
                                    </Col>
                                    <Col xs={12} md={4}>
                                        <Form.Group >
                                            <h4 className={'defaultTitle'}>Celular</h4>
                                            <Form.Control
                                                className="text-center"
                                                placeholder='Celular'
                                                arial-label="Amount (to the nearest dollar)"
                                                value={this.state.phone}
                                                maxLength={10}
                                                onChange={(event) => this.setState({ phone: event.target.value.replace(/\D/, '') })}
                                            />
                                        </Form.Group>
                                        <Summary
                                            operator={this.state.selectedOperator.operador}
                                            price={this.state.price}
                                            phone={this.state.phone}
                                            packet={this.state.name}
                                        />
                                    </Col>
                                </Row>
                            </Card.Body>
                            <Col>
                                <Card.Footer className="text-muted">
                                    <Button size="lg" variant={'info'}
                                        disabled={!this.state.phone || !this.state.selectedOperator.operador || !this.state.name}
                                        onClick={this.sendData}>Finalizar</Button>
                                </Card.Footer>
                            </Col>
                        </Card>
                    </div>

                </div>
                {this.state.modalNotification &&

                    <ModalNotificationTransaction
                        closeModal={(flag) => this.closeModal(flag)}
                        type={"Paquete"}
                        message={"Paquete comprado exitosamente"} />}
                {this.state.loading && <LoadingMovilGo />}
            </>
        )
    }
}

export default PaquetesComponent;