import React from 'react';
import { Image} from 'react-bootstrap';

export const ButtonImage = (props) => {
    const { image, onClick } = props;

    return (
        <button onClick={onClick} style={{border:'none',backgroundColor:'white',marginTop:5}}>
            <Image 
            style={{width: '250px', height: '150px', margin:'2%'}} 
            onClick={onClick} 
            src={image}            
        /> 
            </button>
    );
}
