import React, { PureComponent } from 'react';
import { Button, Card, Row, Container, FormControl } from 'react-bootstrap';
import { searchPolisaSoat } from '../../../services/soat.service';
import { DateToString } from '../../../utilities/formats';
import { LoadingMovilGo } from '../../../shared/components/loadingMovilGo';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LinkSoat } from './link';

export class SoatBuscar extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            /* Numberplate: "FUD873",
            email: "jaimealbertogaviria@reactiva.com.co", */
            Numberplate: "",
            email: "",
            InsurancePolicyNumber: "",
            date: this.getDate(1),
            loading: false,
            soats: [{ id: -1 }],
            source_request: "W",
            linkres: "",
            modalLink: false,
        }
        this.minDate = this.getDate(1);
    }

    getDate = (addDays = 0) => {
        let date = new Date();
        date.setDate(date.getDate() + addDays);
        return DateToString(date, true);
    }

    clearData = () => {
        this.setState({
            loading: false,
            date: this.getDate(1),
            Numberplate: "",
            licensePlate: "",
            email: ""
        })
    }

    verifyFields = (Numberplate, InsurancePolicyNumber, email) => {

        const emailValid = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        //console.log("Entro a verify", Numberplate, email,InsurancePolicyNumber)
        if (Numberplate === "") {
            const error = () => toast.error("Error, El número de placa o póliza es obligatorio");
            error();   /* alert("Error","El numero de Poliza es obligatorio"); */
        } else if (email === "") {
            const error = () => toast.error("Error, El E-mail es obligatorio");
            error();  /* alert("Error","El Email es obligatorio"); */
        } else if (!emailValid) {
            const error = () => toast.error("Error, El E-mail es incorrecto");
            error();  /* alert("Error","El Email es incorrecto"); */
        } else {
            //console.log("Entro a verify ELSE", Numberplate, email)
            /* {this.setState({loading: true})
                searchPolisaSoat(this.state.Numberplate, this.state.InsurancePolicyNumber, this.state.soats[0].id,
                    this.state.email,  this.setSearchedData)} */
            const info = () => toast.info("Los datos de la placa " + Numberplate + " están siendo verificados");
            info();

            if (info) {
                searchPolisaSoat(
                    this.state.source_request,
                    this.state.Numberplate.trim(),
                    this.state.InsurancePolicyNumber.trim(),
                    this.props.soats[0].id,
                    this.state.email.trim(),
                    this.setSearchedData)
            }
        }
    }


    setSearchedData = (res, flag) => {
        // console.log("esto es lo que devuelve de la consutl",flag,res)
        if (flag) {
            //   console.log("RESPUESTA BACK ERROR",res.errores )
            if (res.errores) {
                const error = () => toast.error("Error, Por favor verifique los datos. Si el error persiste comuníquese con MóvilGo" /* + res.errores.observacion */);
                error();  /* alert("Error, ", res.errores.observacion); */
            } else {
                //    console.log("RESPUESTA BACK ERROR",res.policyData)
                this.setState({ linkres: res.policyData })
                /* const success = () => toast.success("Móvilgo, Cosulta exitosa, Verifique el Email ingresado."); */
                this.setState({ modalLink: true })
                this.clearData();
                /* success();  
                    if(success){  this.clearData(); }                 */
            }
        } else {
            const error = () => toast.error("Error, Por favor verifique los datos. Si el error persiste comuníquese con MóvilGo");
            error();
            if (error) { this.clearData(); }
            /* alert("Error","Por favor verifique los datos. Si el error persiste comuniquese con MovilGo",[{text:"Volver",
                onPress:()=>this.clearData()
                }]); */
        }
    }

    render() {
        return (
            <Container >
                <ToastContainer />
                <Row className="justify-content-md-center">
                    <Card className='reloadsCard' style={{ minWidth: '40%' }}>
                        <Card.Body>
                            <div>
                                <div> <h3>Ingrese número de placa</h3> </div>
                                <FormControl value={this.state.Numberplate}
                                    onChange={e => this.setState({ Numberplate: e.target.value })}
                                />
                            </div>
                            <div>
                                <div> <h3>Ingrese número de póliza</h3> </div>
                                <FormControl value={this.state.InsurancePolicyNumber}
                                    onChange={(event) => this.setState({ InsurancePolicyNumber: event.target.value })}
                                />
                            </div>
                            <div>
                                <div> <h3>E-mail</h3> </div>
                                <FormControl value={this.state.email}
                                    onChange={(event) => this.setState({ email: event.target.value })}
                                />
                            </div>
                            <Button variant={'info'} className="mt-2"
                                onClick={() => {
                                    this.verifyFields(//es necesario mandar estos 3 valores a la función?
                                        this.state.Numberplate,
                                        this.state.InsurancePolicyNumber,
                                        this.state.email)
                                }}
                            > Buscar
                            </Button>
                        </Card.Body>
                    </Card>
                    {this.state.loading && <LoadingMovilGo />}
                    {this.state.modalLink && <LinkSoat name="Descargue su SOAT"
                        closeModal={() => this.setState({ modalLink: false })}
                        response={this.state.linkres}
                        msj="Presiona aquí para descargar tu SOAT. Recuerda usar el número de placa en Mayúscula para abrirlo"
                    /* closeModal={(flag) => this.closeModal(flag)}  */
                    />}
                </Row>
            </Container>
        )
    }
}
