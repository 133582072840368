import Odoo from 'odoo-xmlrpc';
import { OdooConfig } from '../utilities/odoo-config';
import { Desencrypt } from '../utilities/cipher';

export const searchClientBingo = async (product_id, cedula, callback) => {
    const config = await OdooConfig();
    const data = await Desencrypt();
    const args = [[0, { product_id: product_id, cedula: cedula }]]

    const params = {
        model: "movilgo.webservice",
        method: "bingoBuscarCliente",
    }
    const client = new Odoo({
        url: config.host,
        port: config.port,
        db: config.database,
        username: data.user,
        password: data.password
    });
    client.connect((err, response) => {
        if (err) {

            return callback(response, false)
        } else {

            client.execute_kw(params.model, params.method, args, (err2, response2) => {

                if (err2) {
                    callback(err2, false);
                    return false;
                }
                callback(response2, true);

            });
        }
        //console.log('error',err, response)
    })
}

export const getBingo = async (product_id, callback) => {
    const config = await OdooConfig();
    const data = await Desencrypt();
    const args = [[
        0, { product_id }
    ]]

    const params = {
        model: "movilgo.webservice",
        method: "bingoProximoSorteo",
    }
    const client = new Odoo({
        url: config.host,
        port: config.port,
        db: config.database,
        username: data.user,
        password: data.password
    });
    client.connect((err, response) => {

        if (err) {
            //console.log('error', response)
            return callback(response, false)
        } else {
            client.execute_kw(params.model, params.method, args, (err2, response2) => {
                if (err2) {
                    callback(err2, false);
                    return false;
                }
                callback(response2, true);

            });
        }

    })
}

