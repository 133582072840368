import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react'
import { Button, Modal } from "react-bootstrap";


export const Config = (props) => {
    const { close, conexion } = props
    const [configuracionList ] = useState([{ value: 'pruebas', title: 'Pruebas' }, { value: 'produccion', title: 'Producción' }]);
    const [conexionSelect, setConexionSelect] = useState(conexion)

    const saveConfiguration = async () => {
        localStorage.setItem("conexion", conexionSelect);
        close();
    }

    const onChange = (value) => {
        setConexionSelect(value);
    }


    return (
        <Modal
            centered
            show={true}
            onHide={() => close()}
        >
            <Modal.Header>
                <Modal.Title className={'defaultTitle'} style={{ color: 'black' }}>Configuración</Modal.Title>
                <Button onClick={() => close()} type="button" style={{ backgroundColor: 'rgba(138,221,45,0)', border: 'none' }}>
                    <FontAwesomeIcon color={'black'} className="mr-2 align-self-center" icon={faTimes} />
                </Button>
            </Modal.Header>
            <Modal.Body>
                <div className="form-group">
                    <h5> Conexión</h5>
                    <div className="form-group">
                        <select className="custom-select" onChange={(event) => { onChange(event.target.value) }} defaultValue={conexionSelect}>

                            {configuracionList.map((option, i) => {
                                return (
                                    <option key={i} value={option.value}>{option.title}</option>
                                )
                            })}
                        </select>

                    </div>

                </div>

            </Modal.Body>
            <Modal.Footer>
                <Button variant='info' onClick={() => { saveConfiguration() }} >Guardar</Button>
            </Modal.Footer>

        </Modal >
    )
}