import Odoo from 'odoo-xmlrpc';
import { OdooConfig } from '../utilities/odoo-config';
import { Desencrypt } from '../utilities/cipher';

export const getDebts = async ( callback) => {
    const config = await OdooConfig();
    let finish = false;
    const args = [[ 0 ]]

    const data = await Desencrypt();
    const params = {
        model: "movilgo.webservice",
        method: "listarDeudaRuta",
        args:args,
        kwargs: {}
    }
    setTimeout(() => {
        if (finish) {
            return;
        }
        alert("Tiempo limite excedidoo");
        callback(false, "");
        return;
    }, 30000);

    const client = new Odoo({
        url: config.host,
        port: config.port,
        db: config.database,
        username: data.user,
        password: data.password
    });

    client.connect((err, response) => {
        if (err) {
            return callback(response, false)
        } else {
            client.execute_kw(params.model, params.method, params.args, (err, response) => {
                finish = true
                if (err) {
                    callback(err, false);
                    return false;
                }
                callback(response, true);
            });
        }
    })

}

export const payDebt = async (amount,partner_id, call) => {

    const config = await OdooConfig();
    const user = Desencrypt();
    let finish = false;
    const args = [[0, {amount,partner_id}]];

    const params = {
        model: "movilgo.webservice",
        method: "pagarDeuda",
    };

    setTimeout(() => {
        if (finish) {
            return;
        }
        alert("Tiempo limite excedidoo");
        call(false, "");
        return;
    }, 30000);


    const odoo = new Odoo({
        url: config.host,
        port: config.port,
        db: config.database,
        username: user.user,
        password: user.password
    });

    odoo.connect((errLogin, responseLogin) => {
        if (errLogin) {
            return;
        }
        odoo.execute_kw(params.model, params.method, args, (err, response) => {
            finish = true;
            if (err) {
                call(false, err);
                return false;
            }

            if (response) {
                if (response.errores && response.errores.id) {

                    call(false, response);
                    return false;
                }
                call(true, response);
                return true;
            }
            return false;

        });
    })
}
