import React, { useState } from 'react';
import { Modal, Card, Button, Col, Row } from 'react-bootstrap';
import { FormatMoney } from '../../../utilities/formats';
import NumberFormat from 'react-number-format';
import { toast } from 'react-toastify';
import { ManagmentErrors } from '../../../shared/components/managmentErrors';
import { payDebt } from '../../../services/recaudo.service';

export const ModalPay = (props) => {
    const { show, data, close, abono, pay } = props
    const [price, setPrice] = useState('');

    const callBack = (flag, data) => {
        if (flag) {
            const success = () => toast.success("Transacción realizada");
            success();
        } else {
            const message = ManagmentErrors(data.errores.id);
            const error = () => toast.error(message);
            error();
        }
    }

    const handlePayDebt = () => {
        if (!pay) {
            if (price <= data.deuda && price > 0) {

                if (window.confirm("¿Desea abonar " + FormatMoney(price) + " a la deuda?")) {
                    abono(data.cliente_id, price);
                    payDebt(price, data.cliente_id, callBack);
                    setPrice('')
                    close();
                }
            } else {
                alert("¡Atención! " + 'No se puede abonar este monto')
            }
        } else {
            abono(data.cliente_id, data.deuda);
            payDebt(data.deuda, data.cliente_id, callBack);
            setPrice('')
            close();
        }
    }

    return (
        <>
            <Modal show={show} onHide={close} centered >
                <Modal.Header closeButton onClick={() => setPrice('')} />
                <Modal.Body>
                    <Card className="text-center" >
                        <Card.Header>Abonar al crédito </Card.Header>
                        <Card.Body>
                            {!pay && <Card.Text>
                                <label>Código: {data.cliente_id}</label><br />
                                <label>Nombre: {data.nombre}</label><br />
                                <label>Deuda: {FormatMoney(parseFloat(data.deuda))}</label><br />
                                <label>Vencido: {FormatMoney(parseFloat(data.vencido))}</label><br />
                            </Card.Text>}
                            {pay && <Card.Text>
                                <label>
                                    ¿Desea pagar la totalidad del monto {FormatMoney(data.deuda)} de {data.nombre} ?
                                </label>
                            </Card.Text>}
                            <hr />
                            {!pay && <div className="form-group" tyle={{ padding: "0 30% 0 30%" }}>
                                <h5>Ingrese un valor</h5>
                                <NumberFormat className="form-control mt-1"
                                    style={{ width: '70%', marginLeft: '15%' }}
                                    thousandSeparator={true}
                                    prefix='$ '
                                    placeholder='$ Montos de Mil'
                                    value={price}
                                    maxLength={9}
                                    onChange={(event) => setPrice(parseInt(event.target.value.replace("$", "").replace(",", "")))}
                                />
                            </div>}
                        </Card.Body>
                        <Card.Footer>
                            <Row>
                                <Col>
                                    <Button variant="outline-secondary" onClick={close}>Cancelar</Button>
                                </Col>
                                <Col>
                                    <Button variant="outline-info" onClick={handlePayDebt}>Abonar</Button>
                                </Col>
                            </Row>
                        </Card.Footer>
                    </Card>
                </Modal.Body>
            </Modal>
        </>
    )
}