import React from 'react';
//import { FormatMoney } from '../../../utilities/formats';

export const Summary = (props) => {
    const { phone, operator, email, price, vigencia,pinPackage } = props

    return (
        <>
            <div>
                {/* <h3 className={'defaultTitle'}>Producto:</h3>
                <h4>{operator}</h4> */}
                <h3 className={'defaultTitle'}>Paquete:</h3>
                <h4>{pinPackage}</h4>
                <h3 className={'defaultTitle'}>Celular:</h3>
                <h4>{phone}</h4>
                <h3 className={'defaultTitle'}>Correo:</h3>
                <h4>{email}</h4>
                {vigencia > 0 && <h3 className={'defaultTitle'}>Vigencia:</h3>}
                {vigencia > 0 && <h4>{vigencia} días</h4>}
                <h3 className={'defaultTitle'}>Precio:</h3>
                <h4>{price}</h4>
                {/* <h4>{FormatMoney(price)}</h4> */}
            </div>        
        </>
    );
}
