import React, { useState, useEffect } from 'react';
import { ButtonImage } from './buttons';
import { Button } from 'react-bootstrap';

export const ListOperator = (props) => {
    const { operators = [], onClick } = props;
   
    return (
        <div>
            {operators && operators.map((operator, i) => {
                    return (
                        <ButtonImage image={'data:image/jpeg;base64,' + operator.image_medium} 
                        onClick={() => onClick(operator)}  key={i} />
                    )
                })
            }
        </div>
    )
}


export const ListTypes = (props) => {
    //console.log("ListTypesPROPS2 list-operator: ", props)
    const { types = [], onClick,clean, limpiar  } = props;
    
   // console.log("types",types)

    const [clicked, setClickedBtn] = useState(false);
    const [indexBtn, setIndexBtn] = useState(-1);

    const handleClick = (index) => {
        clean(true);
        setClickedBtn(true);
        setIndexBtn(index);
    }
    
    useEffect(() => {
        setClickedBtn(limpiar) }, [limpiar]);
    
    return (
        <div>
            {types &&  types.map((type, i) => {
                let btn_class;
                if(indexBtn!==-1){
                    if(indexBtn===i){ btn_class = clicked ? "#bedb02" : ""  }
                }
                return (
                    <Button onClick={() => { onClick(type);handleClick(i) }} variant='info' key={i}
                        style={{ margin: 20, minWidth: 130,backgroundColor:btn_class }}>{type}
                    </Button>
                )
            })}
        </div>
    )

}



