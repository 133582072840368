import React from 'react';
import { Button,  Modal } from 'react-bootstrap';

export const ModalNotificationTransaction = (props) => {

    const { message, type, closeModal } = props
    return (
        <Modal centered show={true} backdrop="static" keyboard={false} >
            <Modal.Header  >
                <Modal.Title style={{ color: '#07A2BA' }} className={'defaultTitle'}>Transacción {type}</Modal.Title>
            </Modal.Header>
            <Modal.Body >
                <div style={{ textAlign: 'center' }}>
                    <h3 style={{ color: '#07A2BA' }}>{message} </h3>
                </div>
            </Modal.Body >
            <Modal.Footer >
                <Button variant='info' onClick={() => closeModal(false)}>Aceptar</Button>
            </Modal.Footer>
        </Modal>
    )

}

export const ModalNotificationTransactionSoat = (props) => {
    const { message, type, closeModal } = props
    return (
        <Modal centered show={true}  backdrop="static" keyboard={false} style={{width:'24%',left:'38%',top:'2%'}} >
            <Modal.Header  >
                <Modal.Title style={{ color: '#07A2BA' ,align: 'center'}} className={'defaultTitle'}>Transacción {type}</Modal.Title>
            </Modal.Header>
            <Modal.Body >
                <div style={{ textAlign: 'center' }}>
                    <h5 style={{ color: '#07A2BA' }}>{message} </h5>
                </div>
            </Modal.Body >
            <Modal.Footer >
                <Button variant='info' onClick={() => closeModal(false)}>Aceptar</Button>
            </Modal.Footer>
        </Modal>
    )

}