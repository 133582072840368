import React, { PureComponent } from 'react';
import { Button, Card, Row, Container, FormControl, Modal } from 'react-bootstrap';
import { searchSoat } from '../../../services/soat.service';
import { DateToString } from '../../../utilities/formats';
import { ClientInfo } from './componets/client-info';
import { VehicleInfo } from './componets/vehicle-info';
import { SendPolicy } from './componets/send-policy'
import { LoadingMovilGo } from '../../../shared/components/loadingMovilGo';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { /* faHome , */faBackspace } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



export class SoatComprar extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showClientInfo: false,
            showVehicleInfo: false,
            showPaymentInfo: false,
            documentNumber: "",
            licensePlate: "",
            /* documentNumber: "15428863",
            licensePlate: "BAQ976", */
            documentType: 1,
            insurance: 1,
            date: this.getDate(1),
            date2: this.getDate(1),
            showDate: false,
            loading: false,
            soats: [{ id: -1 }],
            datepolicy: this.getDate(1),
            dataContact: {},
            dataVehicle: {},
            getVehiculo: {},
            temps: {},
            idSoat: {}
            /* endDatesend:{}, */
        }
        this.minDate = this.getDate(1);
        //Modal.setAppElement('div');

    }

    /* componentDidMount = () => {
 
         
          let soatInfo = this.props.soats/* .replace(/\\/g, '')  ;
         clean = clean.replace('[', '');
         clean = clean.replace(']', '');
         console.log("PROPS SOAT", this.props.soats) 
         console.log("PROPS SOAT soatinfo", soatInfo) 
       }
        /* this.setState=({temps:this.props.soats[0].id});    */

    getDate = (addDays = 0) => {
        let date = new Date();
        date.setDate(date.getDate() + addDays);
        return DateToString(date, true);
    }

    clearData = () => {
        this.setState({
            loading: false,
            date: this.getDate(1),
            date2: this.getDate(1),
            documentNumber: "",
            licensePlate: "",
            dataContact: {},
            getVehiculo: {},
            showClientInfo: false,
            showPaymentInfo: false,
            showVehicleInfo: false,
        })
    }


    setSearchedData = (res, flag) => {
        // console.log("PROPS SOAT", this.props.soats)      
        // console.log("ENVIAR 2 setSearchedData: res de soat(compra) ",res)
        // console.log("1setSearchData - flag, : ", flag)
        // console.log("tempsWEB, : ", this.state.temps)
        /*      console.log("1setSearchData - , res: ", res) */
        if (flag) {
            //console.log("RES", res)
            if (res.errores) {
                if (res.errores.observacion === "'NoneType' object has no attribute 'update'") {
                    const error = () => toast.error('Error, No existe informacion de estos datos');
                    error();
                    if (error) {
                        this.clearData();
                    }
                } else if (res.errores.observacion) {
                    const error = () => toast.error(res.errores.observacion);
                    error();
                    if (error) {
                        this.clearData();
                    }
                }
                else {
                    const error = () => toast.error("Error, Por favor verifique los datos. Si el error persiste comuniquese con MóvilGo");
                    error();
                }
            } else {

                let str = JSON.stringify(res).replace(/\\/g, '');
                str = str.replace('""""', '""');
                str = str.replace('"{', '{');
                str = str.replace('}"', '}');
                str = str.replace('""""', '""');
                str = str.replace('"{', '{');
                str = str.replace('}"', '}');

                const json = JSON.parse(str);
                const TarifaError = json.soat.respuesta_tarifa.error;
                const Vehicle = json.soat.respuesta_vehiculo.VehicleBodyTypeId
                const Poliza = json.soat.respuesta_poliza_vigente.Message
                const Mesajetarifa = json.soat.respuesta_tarifa.Message

                //console.log("Mesajetarifa", Mesajetarifa);   

                /* //const end = json.soat.respuesta_poliza_vigente.DueValidateDate
                //const endDate = json.soat.respuesta_poliza_vigente.DueValidateDate
                const formatDate = (endDate)=>{
                    let formatted_date = endDate.getFullYear() + "-" + (endDate.getMonth() + 2) + "-" + endDate.getDate()
                     return formatted_date;
                    }                           
                //console.log("json de rspuesta soat",endDate)*/

                if (Vehicle === null) {
                    const error = () => toast.error("Error, Algunos datos vienen incompletos. Comuniquese con MovilGo");
                    error();
                    if (error) {
                        this.clearData();
                    }
                } else if (TarifaError) {
                    const error = () => toast.error("Error, No se puede realizar la compra dirijasea ISOAT ");
                    error();
                    if (error) {
                        this.clearData();
                    }
                } else if (Poliza !== "") {

                    const error = () => toast.error("Error, Existe una o varias polizas vigentes coincidente con los datos de la placa ");
                    error();
                    if (error) {
                        this.clearData();
                    }
                } else if (Mesajetarifa) {
                    const error = () => toast.error("Error, La tarifa Viene incompleta");
                    error();
                    if (error) {
                        this.clearData();
                    }
                } else if (json) {
                    this.setState({
                        dataVehicle: json,
                        showVehicleInfo: true,
                        loading: false,
                        //endDatesend:endDate,
                    });
                } else {
                    const error = () => toast.error("Error, Ocurrio un error al obtener datos, verfique la Cédula ingresada.");
                    error();
                    if (error) {
                        this.clearData();
                    }
                    /*  this.setState({  dataVehicle: json, 
                      showVehicleInfo: true, 
                      loading: false, 
                      soats: this.props.soats[0].id });  
                     console.log("ELSE soats: ",this.state.dataVehicle ) */
                }
            }
        } else {
            const error = () => toast.error("Error, Por favor verifique los datos. Si el error persiste comuníquese con MóvilGo");
            error();
            /*console.log("ERRORERER", error()) 
             alert("Error"+"Por favor verifique los datos. Si el error persiste comuníquese con MovilGo")
                if(alert){ */
            if (error) {
                this.clearData();
            }
        }
    }

    sendData = () => {
        /* console.log("PORACÁ: ", this.state.temps[0].id)   */
        //this.setState({idSoat: this.state.temps[0].id });            

        if (this.state.licensePlate.match(/[^a-zA-Z0-9\s/]/) !== null || this.state.licensePlate === '') {
            this.setState({ loading: false });
            const error = () => toast.error("Error, La placa no debe contener caracteres especiales o estar vacia");
            error();
        } /* else if (this.state.documentNumber === '') {
            //console.log("CÉDULA1: ",this.state.documentNumber)            
            this.setState({ loading: false });
            const error = () => toast.error("Error, Debe ingresar un N° de identificación");
            error();
        } */ else {


            //const {idSoat}= this.props.soats[0]

            let str = JSON.stringify(this.props.soats)
            str = str.replace('[{"image_medium":false,"name":"SOAT","tipo":"soat","write_date":"2022-07-06 12:33:27","active":true,"categ_id_name":"Soat","id":', '');
            str = str.replace('}]', '');

            console.log("str", str)




            let date = this.state.date.split("-");
            const year = parseInt(date[0]) + 1;

            //console.log("LET YEAR: ",year)
            searchSoat(
                /* PENDIENTE */
                this.state.documentNumber.trim(),
                this.state.licensePlate.trim(),
                str,
                //idSoat,
                //this.state.idSoat,
                //this.state.temps,//[0].id,
                this.state.date,
                year + "-" + date[1] + "-" + date[2],
                //this.props.soats[0].id,  //this.state.soats[0].id,
                /* DateToString(this.state.date, true, 0, 0, 1), DateToString(this.state.date, true),*/
                this.setSearchedData,
            );
            // console.log("DATE 1: ",  idsoat)
            //console.log("DATE 2: ",  year + "-" + date[1] + "-" + date[2])
            //console.log("PENDIENTE: ", this.props.soats[0].id)
            this.setState({ loading: true, temps: this.state.soats[0].id,/* this.props.soats[0].id */ })
        }
    }

    contact = (response) => {
        this.setState({ dataContact: response })
    };
    vehicle = (response) => {
        this.setState({ getVehiculo: response })
        //console.log("VEHICLE",this.state.getVehiculo)
    }

    render() {
        return (
            <Container >
                <div><h2>Comprar Soat</h2></div>
                <ToastContainer />
                <Row className="justify-content-md-center">
                    <Card className='reloadsCard' style={{ minWidth: '40%' }}>
                        <Card.Body>
                            <div> <h3>Ingrese número de documento</h3> </div>
                            <FormControl placeholder="Cédula"
                                arial-label="Numero de identificación de la persona"
                                value={this.state.documentNumber}
                                onChange={e => this.setState({ documentNumber: e.target.value.replace(/\D/, '') })} />
                            <div> <h3>Ingrese placa del vehiculo</h3> </div>
                            <FormControl placeholder='Placa'
                                arial-label="Numero de identificación del vehiculo"
                                value={this.state.licensePlate}
                                onChange={(event) => this.setState({ licensePlate: event.target.value })} />
                            <div> <h3>Seleccione fecha de inicio</h3> </div>
                            <div>
                                <input type="date" name="trip-start"
                                    value={this.state.date} min={this.minDate}//value={this.state.date2} 
                                    onChange={e => { this.setState({ date: e.target.value },); console.log(e.target.value) }}  /* { date2: e.target.value } */
                                />
                            </div>
                            <Button /* disabled={this.state.loading} */ variant={'info'} className="mt-2"
                                onClick={() => { this.sendData(); }}>
                                Buscar
                            </Button>
                        </Card.Body>
                    </Card>

                    {this.state.loading && <LoadingMovilGo />}{/* logo de carga movilgo */}

                    {/* MODAL DE VEHICULO */}
                    <Modal show={this.state.showVehicleInfo} onHide={() => this.clearData()} backdrop="static">
                        <Modal.Header closeButton >
                            <Modal.Title >Compra Soat</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <VehicleInfo
                                data={this.state.dataVehicle}
                                infoVehicle={() => this.vehicle()}
                                dataVehicle={this.state.getVehiculo}
                                closeAction={() => this.clearData()}
                                confirmAction={() => this.setState({
                                    showVehicleInfo: false, showClientInfo: true
                                })} >
                            </VehicleInfo>
                        </Modal.Body>
                    </Modal>

                    {/* MODAL DE DATOS CLIENTE */}
                    <Modal show={this.state.showClientInfo} onHide={() => this.clearData()} backdrop="static">
                        <Modal.Header closeButton >
                            <FontAwesomeIcon style={{ marginTop: 10 }} icon={faBackspace} size="lg" color='#BDBDBD'
                                onClick={() => this.setState({ showClientInfo: false, showVehicleInfo: true })} />
                        </Modal.Header>
                        <Modal.Body>
                            <ClientInfo
                                data={this.state.dataVehicle}
                                dataContact={this.state.dataContact}
                                back={() => this.setState({ showVehicleInfo: true, showClientInfo: false, })}
                                infoContact={this.contact}
                                confirmAction={() => this.setState({ showClientInfo: false, showPaymentInfo: true })}
                            />
                        </Modal.Body>
                    </Modal>

                    {/* MODAL DE ENVIO PÓLIZA */}
                    <Modal show={this.state.showPaymentInfo} onHide={() => this.clearData()} backdrop="static">
                        <Modal.Header closeButton >
                            <FontAwesomeIcon style={{ marginTop: 10 }} icon={faBackspace} size="lg" color='#BDBDBD'
                                onClick={() => this.setState({ showClientInfo: true, showPaymentInfo: false })} />
                        </Modal.Header>
                        <Modal.Body>
                            <SendPolicy
                                data={this.state.dataVehicle}
                                date={this.state.date2}
                                //EndDatePolicy={this.state.endDatesend}
                                back={() => this.setState({ showClientInfo: true, showPaymentInfo: false })}
                                dataContact={this.state.dataContact}
                                infoVehicle={this.state.getVehiculo}
                                productId={this.state.temps}/* 
                                productId={this.state.soats} */
                                closeAction={() => this.clearData()}
                                loading={this.state.loading}
                                setLoading={(loading) => this.setState({ loading })}
                            />
                        </Modal.Body>
                    </Modal>
                </Row>
            </Container>
        )
    }
}

