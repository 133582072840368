import React, { useState } from 'react';
import { Card, Col, Row, Form, InputGroup, FormControl } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { searchPayments } from '../../../services/rifa.service'
import { LoadingMovilGo } from '../../../shared/components/loadingMovilGo';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const SearchBoletas = (props) => {
    const { listaAbonos, deleteListAbonos } = props
    const [typeSearch, setTypeSearch] = useState('cedula');
    const [searchValue, setSearchValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [opacity, setOpacity] = useState(1);
    
    const foundPayments = (response, flag) => {
      
        if (flag) {
            let _list = { abonos: [] }
            if (typeSearch === "boleta") {
                response.abonos.forEach(element => {
                    const _element = element;
                    _element['boleta'] = searchValue;
                    _list.abonos.push(_element)
                });
                listaAbonos(_list);
            } else if (typeSearch === "celular") {
                response.abonos.forEach(element => {
                    const _element = element;
                    _element['celular'] = searchValue;
                    _list.abonos.push(_element)
                });
                listaAbonos(_list);
            } else {
                listaAbonos(response);
            }
            setLoading(false);
            setOpacity(1);
        } else {
            setLoading(true);
            setOpacity(1)
        }

    }
    
    const findPayments = () => {
        const rifa = JSON.parse(localStorage.getItem('datosRifa'));
        if (searchValue.trim() !== "") {
            setLoading(true);
            setOpacity(0.4)
            searchPayments(typeSearch, searchValue, rifa.id, foundPayments);
        } else {
            const error = () => toast.error("¡Se debe ingresar un valor de busqueda!");
            error();
        }
    }
    return (
        <>
            <ToastContainer autoClose={2000} />
            <Card style={{ width: '42rem', marginTop: '15px', opacity: opacity }}  >
                <Card.Body>
                    <Col xs="auto">
                        <div>

                            <Form.Group>
                                <Row className="justify-content-md-center defaultTitle" >
                                    <Col>
                                        <Form.Check
                                            defaultChecked={true}
                                            type="radio"
                                            label="Cédula"
                                            name="formHorizontalRadios"
                                            id="cedula"
                                            onChange={(event) => { setTypeSearch(event.target.id); deleteListAbonos(); setSearchValue(''); }}

                                        />
                                    </Col>
                                    <Col>
                                        <Form.Check
                                            type="radio"
                                            label="Boleta"
                                            name="formHorizontalRadios"
                                            id="boleta"
                                            onChange={(event) => { setTypeSearch(event.target.id); deleteListAbonos(); setSearchValue(''); }}
                                        />
                                    </Col>
                                    <Col>
                                        <Form.Check
                                            type="radio"
                                            label="Celular"
                                            name="formHorizontalRadios"
                                            id="celular"
                                            onChange={(event) => { setTypeSearch(event.target.id); deleteListAbonos(); setSearchValue(''); }}
                                        />
                                    </Col>
                                </Row>
                            </Form.Group>

                            <InputGroup className="mb-2">
                                <FormControl
                                    value={searchValue}
                                    placeholder="Buscar"
                                    arial-label="Amount (to the nearest dollar)"
                                    type="number"
                                    onChange={(e) => { setSearchValue(e.target.value); deleteListAbonos() }}
                                />
                                <InputGroup.Prepend>
                                    <InputGroup.Text>
                                        <FontAwesomeIcon onClick={() => findPayments()} className="mr-2 align-self-center" icon={faSearch} />
                                    </InputGroup.Text>
                                </InputGroup.Prepend>

                            </InputGroup>
                        </div>
                    </Col>
                </Card.Body>
            </Card>

            {loading && <LoadingMovilGo />}
        </>
    );


}