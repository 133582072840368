import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { PureComponent } from 'react';
import { Card, Col, FormControl, InputGroup, Pagination, Row, Button } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { getBingo, searchClientBingo } from '../../services/bingo.service';
import { Transaction } from '../../services/product.service';
import { LoadingMovilGo } from '../../shared/components/loadingMovilGo';
import { ManagmentErrors } from '../../shared/components/managmentErrors';
import { Capitalize, FormatMoney } from '../../utilities/formats';
import { jsPdfGenerator } from '../../shared/components/pdfTransaction';
import { ModalNotificationTransaction } from '../../shared/components/modalNotificationTransaction';

export class SuperBingo extends PureComponent {
    constructor() {
        super();
        this.state = {
            identification: '',
            celular: '',
            apellidos: '',
            nombre: '',
            loading: true,
            opacity: 1,
            found: false,
            register: false,
            count: 1,
            disabled: true,
            dateStart: "",
            reward: "",
            modalNotification: false,
        }
        this.response = [];
    }

    componentDidMount() {
        if (this.props.bingo) {
            getBingo(this.props.bingo[0].id, (res, flag) => {
                if (flag) {
                    const newDate = res.dateStart.split("T")[0].split("-");
                    this.setState({
                        dateStart: res ? newDate[2] + "-" + newDate[1] + "-" + newDate[0] : "",
                        reward: res ? FormatMoney(res.reward) : "",
                        loading: false
                    });
                } else {
                    this.setState({ loading: false })
                }

            })
        }



    }

    foundClient = (response, flag) => {
        if (this.state.identification.trim() === "") {
            return;
        }

        if (flag) {
            if (response && response.data && response.message === "Cliente Listado con exito") {
                const message = () => toast.info("¡Usuario encontrado!");
                message();
                this.setState({
                    loading: false,
                    opacity: 1,
                    found: true,
                    register: false,
                    count: 1,
                    disabled: false,
                    celular: response.data.cusPhone,
                    nombre: response.data.cusName,
                    apellidos: response.data.cusLastName,
                });
            } else {
                const message = () => toast.error("¡Usuario no encontrado, por favor registrarlo !");
                message();
                this.setState({
                    loading: false,
                    opacity: 1,
                    found: true,
                    register: true,
                    count: 1,
                    disabled: true,
                    celular: "",
                    nombre: "",
                    apellidos: ""
                });

            }
        } else {
            const message = () => toast.error("¡Usuario no encontrado, por favor registrarlo !");
            message();
            this.setState({
                loading: false,
                opacity: 1,
                found: true,
                register: true,
                count: 1,
                disabled: true,
                celular: "",
                nombre: "",
                apellidos: ""
            });
        }

    }

    findClient = () => {
        const errorDigitar = () => toast.error("¡No se le olvide digitar un número de cédula!");
        if (this.state.identification.trim() !== "") {
            this.setState({ loading: true, opacity: 0.4 });
            searchClientBingo(this.props.bingo[0].id, this.state.identification, this.foundClient);
        } else {
            errorDigitar();
        }
    }

    getResponse = (flag, response) => {
        if (flag) {
            this.response = response;
            this.response["identification"] = this.state.identification;
            this.response["nombre"] = this.state.nombre;
            this.response["apellidos"] = this.state.apellidos;
            this.response["celular"] = this.state.celular;
            this.response["count"] = this.state.count;

            const success = () => toast.success("Gana Bingo comprado exitosamente");
            success();
            this.setState({
                loading: false,
                opacity: 1,
                identification: '',
                celular: '',
                apellidos: '',
                nombre: '',
                found: false,
                register: false,
                count: 1,
            })
            this.setState({ loading: false, opacity: 1, modalNotification: true });
        } else {
            if (response.errores) {
                const message = ManagmentErrors(response.errores.id);
                const error = () => toast.error(message);
                error();
            } else {
                const message = "Error desconocido";
                const error = () => toast.error(message);
                error();
            }
            this.setState({
                loading: false,
                opacity: 1,
                identification: '',
                celular: '',
                apellidos: '',
                nombre: '',
                found: false,
                register: false,
                count: 1,
            })

        }
    }

    sendData = () => {
        if (this.state.celular.length < 10) {
            const message = () => toast.error("¡El número de telefono es demasiado corto!");
            message();
            return;
        }
        this.setState({ loading: true })
        const product = {
            product_id: this.props.bingo[0].id,
            atributes: {
                bingo_cedula: this.state.identification,
                bingo_numero_celular: this.state.celular,
                bingo_cliente_nombre: this.state.nombre,
                bingo_apellido_nombre: this.state.apellidos,
                bingo_tripletas: this.state.count,
                precio: this.props.bingo[0].precio * this.state.count
            }
        }
        Transaction(product, this.getResponse);
    }

    add = () => {
        if (this.state.count >= 1 && this.state.count < 5) {
            this.setState({ count: this.state.count + 1 })
        }
    }

    subtract = () => {
        if (this.state.count > 1) {
            this.setState({ count: this.state.count - 1 })
        }
    }

    closeModal = (flag) => {
        this.setState({ modalNotification: flag });
        // console.log(
        //     this.state.identification,
        //     this.state.nombre,
        //     this.state.apellidos,
        //     this.state.celular
        // )
        const data = [
            "Factura No: " + this.response.valida.id,
            "Fecha: " + this.response.valida.fecha,
            "No.Aprobacion: " + this.response.valida.numero_aprobacion,
            "Producto: GANA BINGO",
            "Cedula: " + this.response.identification,
            "Cliente: " + this.response.nombre + " " + this.response.apellidos,
            "Celular: " + this.response.celular,
            "Valor: " + FormatMoney(this.props.bingo[0].precio * this.response.count)
        ]
        // console.log(data);
        jsPdfGenerator(data);
    }

    render() {
        return (
            <>
                <style>
                    {`
                        .my-auto {
                            margin-top: auto;
                            margin-bottom: auto;
                        } 
                        .reloadsCard {
                            box-shadow: 0px 2px 4px 1px rgba(0,0,0,0.17);
                            border-radius: 1em;
                            margin: 5px;
                        }
                ` }
                </style>
                <ToastContainer />
                <div className="container" style={{ opacity: this.state.opacity }}>
                    <h2 className={'defaultTitle'}>Bingo</h2>
                    <h4>Fecha de sorteo {this.state.dateStart}</h4>
                    <h4>Premio {FormatMoney(this.state.reward)}</h4>
                    <Row className="justify-content-md-center">
                        <Card className='reloadsCard' style={{ minWidth: '40%' }}>
                            <Card.Body>
                                <Row className="justify-content-md-center">
                                    <Col className="my-auto">
                                        <InputGroup className="mb-2">
                                            <FormControl
                                                placeholder='Cédula'
                                                arial-label="Amount (to the nearest dollar)"
                                                value={this.state.identification}
                                                // maxLength={10}
                                                onChange={(event) => this.setState({ identification: event.target.value.replace(/\D/, '') })}
                                            />
                                            <InputGroup.Prepend style={{ cursor: 'pointer' }}>
                                                <InputGroup.Text onClick={() => this.findClient()}>
                                                    <FontAwesomeIcon className="mr-2 align-self-center" icon={faSearch} />
                                                </InputGroup.Text>
                                            </InputGroup.Prepend>
                                        </InputGroup>
                                    </Col>
                                </Row>
                                {this.state.found && <Row className="justify-content-md-center">
                                    <b>{Capitalize(this.state.nombre) + " " + Capitalize(this.state.apellidos.split(" ")[0]) + " " + Capitalize(this.state.apellidos.split(" ")[1] ?? "")}</b>
                                </Row>
                                }
                                {this.state.register &&
                                    <Row className="justify-content-md-center">
                                        <Col className="my-auto">
                                            <InputGroup className="mb-2">
                                                <FormControl
                                                    placeholder='Ingrese el Nombre'
                                                    arial-label="Amount (to the nearest dollar)"
                                                    value={this.state.nombre}
                                                    onChange={(event) => this.setState({ nombre: event.target.value })}
                                                />
                                            </InputGroup>
                                            <InputGroup className="mb-2">
                                                <FormControl
                                                    placeholder='Ingrese los Apellidos'
                                                    arial-label="Amount (to the nearest dollar)"
                                                    value={this.state.apellidos}
                                                    onChange={(event) => this.setState({ apellidos: event.target.value })}
                                                />
                                            </InputGroup>
                                            <InputGroup className="mb-2">
                                                <FormControl
                                                    placeholder='Numero de Celular'
                                                    arial-label="Amount (to the nearest dollar)"
                                                    value={this.state.celular}
                                                    maxLength={10}
                                                    onChange={(event) => this.setState({ celular: event.target.value.replace(/\D/, '') })}
                                                />
                                            </InputGroup>
                                        </Col>
                                    </Row>}

                            </Card.Body>
                            {this.state.found && <Row className="justify-content-md-center">
                                <b style={{ fontSize: '22px' }}>
                                    {FormatMoney(this.props.bingo[0].precio * this.state.count)}
                                </b>
                            </Row>}
                            {this.state.found && <div className="d-flex justify-content-center">

                                <Row className="justify-content-md-center">
                                    <Pagination>
                                        <Pagination.Prev onClick={this.subtract}><b>-</b></Pagination.Prev >
                                        <Pagination.Item>{this.state.count}</Pagination.Item>
                                        <Pagination.Next onClick={this.add}><b>+</b></Pagination.Next>
                                    </Pagination>
                                </Row>
                            </div>}
                            {this.state.found && <Card.Footer>
                                <Button
                                    disabled={!this.state.disabled || (
                                        this.state.celular &&
                                        this.state.apellidos &&
                                        this.state.nombre &&
                                        this.state.identification) ?
                                        false : true
                                    }
                                    onClick={this.sendData}
                                >
                                    Comprar
                                </Button>
                            </Card.Footer>}
                        </Card>
                    </Row>
                </div>
                {this.state.modalNotification && <ModalNotificationTransaction closeModal={(flag) => this.closeModal(flag)} type={"Bingo"} message={"Compra realizada exitosamente"} />}
                {this.state.loading && <LoadingMovilGo />}
            </>
        )
    }
}