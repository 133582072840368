import React, { PureComponent } from 'react';
import { DetailBox } from './components/detailBox';
import { getBox } from '../../services/caja.service';
import { Row } from 'react-bootstrap';
import { TabsBox } from './components/tabsBox';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LoadingMovilGo } from '../../shared/components/loadingMovilGo';

class Caja extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            caja: { "balanceFinal": 0, "balanceInicial": 0, "credito": 0, "debito": 0, "ganancia": 0, "estado": "No disponible", "fecha": "--", "nombre_caja": "--" },
            viewMoves: false,
            movimientos: [],
            key: '',
            cargar: true
        }
        this.products = this.props.products
        this.datesBox = {};
    }

    initializeData = (data, flag) => {

        const error = () => toast.error("¡Falló la conexión con el servidor!");
        if (flag) {
            // console.log("detalles de la CAJA variable data", data)
            // console.log("detalles de la CAJA variable flag", flag)
            this.setState({ caja: data.caja, cargar: false });
        } else {
            const date = JSON.stringify(data.message);
            const otroDate = JSON.stringify(date.slice(1, 14))
            const comprueba = JSON.stringify("XML-RPC fault");

            //console.log("detalles de la CAJA variable data", data)
            /*  console.log("detalles de la CAJA variable data message", data.message)
             console.log("detalles de la CAJA variable data slice(1, 14)", data.slice(1, 14))
             console.log("detalles de la CAJA variable flag", flag) */

            if (otroDate === comprueba) {
                error();
            }
        }
    }
    initializeMoves = (data, flag) => {
        if (flag) {
            this.setState({ movimientos: data.movimientos, viewMoves: true });
        } else {
            alert(data.message);
        }

    }
    componentDidMount = () => {
        getBox(2, 0, null, this.initializeData);
    }

    render() {

        return (
            <div>
                {this.state.cargar && <LoadingMovilGo />}
                <ToastContainer />
                <h6 className='defaultTitle' style={{ fontSize: 45 }}>Caja</h6>
                <DetailBox detailBox={this.state.caja} />
                <div className='container' style={{ marginTop: 25 }}>
                    <Row className="justify-content-md-center" >
                        <TabsBox
                            error={(errorMessage) => {
                                const error = () => toast.error(errorMessage);
                                error();
                            }}
                            products={this.products}
                            loading={(flag) => this.setState({ cargar: flag })} />
                    </Row>
                </div>
            </div>
        )
    }
}
export default Caja;