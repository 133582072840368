import React,{useState,useEffect,Fragment} from 'react';
import PropTypes from 'prop-types';
import { Row, Button, Card, Container,FormControl} from 'react-bootstrap';
import Select from "react-select";

export const ClientInfo = (props) => {
    const {data,dataContact,closeAction,infoContact,confirmAction} = props;
    
    const[optionsDepartamentos,setOptionsDepartamentos]  = useState([])
    const [optionsActividades,setOptionsActividades] = useState([])
    const [opcionesCiudades,setOpcionesCiudades] = useState([])
    const [opcionesTipoDocument,setOpcionesTipoDocument]=useState([])
    let ciudades=data.soat.ciudadesSoat
    let departamentos=data.soat.deptosSoat
    let actividades=data.soat.actividadSoat
    let tipoDocumentoCliente=[{label:"Cedula Ciudadania", value:1},{label:"Cedula de Extranjeria", value:2},{label:"Nit", value:3},{label:"Tarjeta de identidad", value:4},{label:"Pasaporte", value:5}]
    const [tipoDepartamento,setTipoDepartamento]=useState({label:"-- Departamento --",value:-1})
    const [tipoCiudad,setTipoCiudad]=useState({label:"-- Ciudad --",value:-1,stateId:-1});
    const [Contacto, setContacto] = useState({FirstNames:"",LastNames:"",Address:{Name:""},DocumentNumber:""});
    const [firstNameError, setFirstNameError] = useState('');
    const [lastNameError, setLastNameError] = useState('');
    const [documentError, setDocumentError] = useState('');
    const [address,setAddress]=useState("");
    const [addressError, setAdressError] = useState('');
    const [tipoDocumento,setTipoDocumento]=useState({label:"-- Tipo de Documento --", value:-1});
    const [actividadesEconomicas,setActividadesEconomicas]=useState({label:"-- Tipo de Actividad --", value:-1});
    const [flagMunicipio,setFlagMunicipio]=useState(false)
    const [flagDepartamento,setFlagDepartamento]=useState(false)
    const [flagActividad,setFlagActividad]=useState(false)
    const [flagDocument,setFlagDocument]=useState(false)
    const [flagDocumentEdit,setFlagDocumentEdit]=useState(false)
    const [flagDocumentNotEdit,setFlagDocumentNotEdit]=useState(false)
    
    function isObjEmpty(obj) {
        //console.log("ENTRA7:")
        for (var prop in obj) {
          if (obj.hasOwnProperty(prop)) return false;
        }
          
        return true;
    }
    
    const loadData=()=>{
        //console.log("loadData - client-info: ", dataContact)
        if(isObjEmpty(dataContact)===false){
            //console.log("esto ingreaso al datacontac")
            //aqui se inicialiazan los valores en caso que se regresendel modal sendPolice
            setContacto({...Contacto,
                FirstNames:dataContact.firstName,
                Contacto,
                LastNames:dataContact.lastName,
                Contacto,
                DocumentNumber:dataContact.document});
             
            setAddress(dataContact.adress)
            
            let documentoSeleccionado={label:"",value:""}
            let documentoslist=[];

            tipoDocumentoCliente.forEach(elements =>{
                if(parseInt(dataContact.DocumentTypeId)===parseInt(elements.value)){
                    documentoSeleccionado={label:elements.label, value:elements.value}
                }
                documentoslist.push({ label: elements.label, value: elements.value })
            })

            setTipoDocumento(documentoSeleccionado)
            setOpcionesTipoDocument(documentoslist)
            let departamentoSeleccionado={label:"",value:""}
            let opcionesDepartamentoslist=[];

            departamentos.forEach(elements =>{
                if(parseInt(dataContact.StateId)===parseInt(elements.stateId)){
                    departamentoSeleccionado={label:elements.nombre,value:elements.stateId}
               }
                opcionesDepartamentoslist.push({ label: elements.nombre, value: elements.stateId })
                opcionesDepartamentoslist.sort((a,b) =>{
                    return (a.label < b.label) ? -1 : 1
                })        
            })
            setTipoDepartamento(departamentoSeleccionado); 
            setOptionsDepartamentos(opcionesDepartamentoslist);

            let actividadSeleccionada={label:"",value:""}
            let opcionesActividadList=[]          
            actividades.forEach(elements =>{
                if(parseInt(dataContact.CiiuId)===parseInt(elements.ciiuId)){
                    actividadSeleccionada={ label:" "+ elements.descripcion+" ", value: elements.ciiuId }
                }
                opcionesActividadList.push({ label: elements.descripcion, value: elements.ciiuId })
                opcionesActividadList.sort((a,b) =>{
                    return (a.label < b.label) ? -1 : 1
                })     
            })
            setActividadesEconomicas(actividadSeleccionada)
            setOptionsActividades(opcionesActividadList)

            let ciudadSelecionada={label:"",value:""}
            let opcionesCiudades=[]
            ciudades.forEach(elements =>{
                if((parseInt(dataContact.StateId)===parseInt(elements.stateId)&&
                    (parseInt(dataContact.city)===parseInt(elements.cityId)))){
                      
                    ciudadSelecionada={ label:elements.cityName, value: elements.cityId,stateId:elements.stateId}
                }
                if(parseInt(dataContact.StateId)===parseInt(elements.stateId)){                       
                    opcionesCiudades.push({ label: elements.cityName, value: elements.cityId,stateId:elements.stateId })
                    opcionesCiudades.sort((a,b) =>{
                        return (a.label < b.label) ? -1 : 1
                    })
                }
            })    
            setTipoCiudad(ciudadSelecionada)
            setOpcionesCiudades(opcionesCiudades)
        }else if(data.soat.respuesta_cliente){
            //console.log("esto ingreaso al respuesta cliente")
            // se inicilizan datos en caso que vengan datos del soat
                    setContacto(data.soat.respuesta_cliente);
                    setAddress(data.soat.respuesta_cliente.Address.Name)
                    let documentoSeleccionado={label:"",value:""}
                    let documentoslist=[];
                    tipoDocumentoCliente.forEach(elements =>{
                        if(parseInt(data.soat.respuesta_cliente.DocumentTypeId)===parseInt(elements.value)){
                            documentoSeleccionado={label:elements.label, value:elements.value}
                        }
                        documentoslist.push({ label: elements.label, value: elements.value })

                    })
                    setTipoDocumento(documentoSeleccionado)
                    setOpcionesTipoDocument(documentoslist)
                    let departamentoSeleccionado={label:"",value:""}
                    let opcionesDepartamentoslist=[];
                    departamentos.forEach(elements =>{
                        if(parseInt(data.soat.respuesta_cliente.Address.StateId)===parseInt(elements.stateId)){
                            departamentoSeleccionado={label:elements.nombre,value:elements.stateId}
                        }
                        opcionesDepartamentoslist.push({ label: elements.nombre, value: elements.stateId })
                        opcionesDepartamentoslist.sort((a,b) =>{
                                return (a.label < b.label) ? -1 : 1
                        })        
                    })
                    setTipoDepartamento(departamentoSeleccionado); 
                    setOptionsDepartamentos(opcionesDepartamentoslist);
                    let actividadSeleccionada={label:"",value:""}
                    let opcionesActividadList=[]
                    actividades.forEach(elements =>{
                            if(parseInt(data.soat.respuesta_cliente.CiiuId)===parseInt(elements.ciiuId)){
                                actividadSeleccionada={ label:" "+ elements.descripcion+" ", value: elements.ciiuId }
                            }
                            opcionesActividadList.push({ label: elements.descripcion, value: elements.ciiuId })
                            opcionesActividadList.sort((a,b) =>{
                                return (a.label < b.label) ? -1 : 1
                            })     
                    })
                    setActividadesEconomicas(actividadSeleccionada)
                    setOptionsActividades(opcionesActividadList)
                    let ciudadSelecionada={label:"",value:""}
                    let opcionesCiudades=[]
                    ciudades.forEach(elements =>{
                            if((parseInt(data.soat.respuesta_cliente.Address.StateId)===parseInt(elements.stateId)&&
                            (parseInt(data.soat.respuesta_cliente.Address.CityId)===parseInt(elements.cityId)))){
                                ciudadSelecionada={ label:elements.cityName, value: elements.cityId,stateId:elements.stateId }
                            }
                            if(parseInt(data.soat.respuesta_cliente.Address.StateId)===parseInt(elements.stateId)){                       
                                opcionesCiudades.push({ label: elements.cityName, value: elements.cityId,stateId:elements.stateId })
                                opcionesCiudades.sort((a,b) =>{
                                    return (a.label < b.label) ? -1 : 1
                                })
                            }
                    })                    
                    setTipoCiudad(ciudadSelecionada)
                    setOpcionesCiudades(opcionesCiudades)
        }
        else{
            //console.log("esto ingreaso al no respuesta cliente")
             // se inicilizan datos en caso que NO vengan datos del soat
                    let documentoslist=[];
                    tipoDocumentoCliente.forEach(elements =>{
                        documentoslist.push({ label: elements.label, value: elements.value })
                    })
                    setOpcionesTipoDocument(documentoslist)
                    let opcionesDepartamentoslist=[];
                    departamentos.forEach(elements =>{
                        opcionesDepartamentoslist.push({ label: elements.nombre, value: elements.stateId })
                        opcionesDepartamentoslist.sort((a,b) =>{
                                return (a.label < b.label) ? -1 : 1
                        })        
                    }) 
                    setOptionsDepartamentos(opcionesDepartamentoslist);
                    let opcionesActividadList=[]
                    actividades.forEach(elements =>{
                            
                            opcionesActividadList.push({ label: elements.descripcion, value: elements.ciiuId })
                            opcionesActividadList.sort((a,b) =>{
                                return (a.label < b.label) ? -1 : 1
                            })     
                    })
                    setOptionsActividades(opcionesActividadList)
        }

        if(data.soat.respuesta_cliente){            //console.log("esto ingreaso al respuesta cliente")
            // se inicilizan datos en caso que vengan datos del soat
            //console.log("ENTRA 6: ")
            setFlagDocumentNotEdit(true)
         }else{
            setFlagDocumentEdit(true) 
         }
    }
    
    const verifyFields=()=>{
        
        //console.log("verifi tipo ciudad",tipoCiudad)
        //console.log("verifi",tipoCiudad.stateId,tipoDepartamento.value)
        if((Contacto.FirstNames !== "" || !!Contacto.FirstNames ) &&
        (Contacto.LastNames !== "" || !!Contacto.LastNames) &&
        (parseInt(tipoCiudad.value) !== -1 || !!tipoCiudad.value) &&
        (Contacto.DocumentNumber !== "" || !!Contacto.DocumentNumber) && (address !== "" || !!address) &&
        (parseInt(actividadesEconomicas.value) !== -1 ||actividadesEconomicas.value !== "" ||actividadesEconomicas.value !== undefined) &&
        (parseInt(tipoDepartamento.value) !== -1 || !!tipoDepartamento.value) &&
        (parseInt(tipoDocumento.value) !== -1 || !!tipoDocumento.value ) &&
        Contacto.FirstNames.match(/[^a-zA-Z0-9\s/]/) === null &&
        Contacto.LastNames.match(/[^a-zA-Z0-9\s/]/) === null &&
        parseInt(tipoCiudad.stateId) === parseInt(tipoDepartamento.value)
      ) {
        sendData();
      }else if(Contacto.FirstNames===""){
                alert("Error, "+"El nombre es obligatorio")
            }else if(Contacto.FirstNames.match(/[^a-zA-Z0-9\s/]/)!==null){
                alert("Error, "+"No se permiten caracteres especiales en el nombre")
            }else if(Contacto.LastNames===""){
                alert("Error, "+"El apellido es obligatorio")
            }else if(Contacto.LastNames.match(/[^a-zA-Z0-9\s/]/)!==null){
                alert("Error, "+"No se permiten caracteres especiales en el apellido")
            }else if(Contacto.DocumentNumber===""||Contacto.DocumentNumber.length > 10){
                alert("Error, "+"El documento es obligatorio")
            }else if(address===""|| address === null){
                alert("Error, "+"La direccion es obligatoria")              
            }else if(address.match(/[^a-zA-Z0-9\s/-]/)!==null){
                alert("Error, "+"No se permiten caracteres especiales en la direccion")
            }else if(tipoDocumento.value===-1){
                setFlagDocument(true)
            }else if(tipoDepartamento.value===-1){
                setFlagDepartamento(true)
            }else if(tipoCiudad.value===-1){
                setFlagMunicipio(true)
            }else if(actividadesEconomicas.value===-1||actividadesEconomicas.value === ''||actividadesEconomicas.value === undefined){
                setFlagActividad(true)
            }else if(parseInt(tipoCiudad.stateId)!==parseInt(tipoDepartamento.value)){
                alert("Error, "+"La ciudad no coincide con el departamento")
            }
            if (parseInt(tipoCiudad.stateId) !== parseInt(tipoDepartamento.value)) {
                alert('¡Error!', 'La ciudad no coincide con el departamento.');
              }
    }

    const sendData=()=>{
            infoContact({firstName:Contacto.FirstNames,
                lastName:Contacto.LastNames,
                city:tipoCiudad.value,
                document:Contacto.DocumentNumber,
                adress:address,
                CiiuId:actividadesEconomicas.value,
                StateId:tipoDepartamento.value,
                DocumentTypeId:tipoDocumento.value})
            confirmAction(false)
    }

    const initialiceMunicipio=(selectedDepto)=>{
        //console.log("INITIALICEmUNICIPIO")
        setOpcionesCiudades([])
        let opcionesCiudades=[]  
        ciudades.forEach(elements =>{                
                if(parseInt(selectedDepto.value)===parseInt(elements.stateId)){
                    opcionesCiudades.push({ label: elements.cityName, value: elements.cityId,stateId:elements.stateId })
                    opcionesCiudades.sort((a,b) =>{
                        return (a.label < b.label) ? -1 : 1
                    })
                }  
            
        })  
            setOpcionesCiudades(opcionesCiudades)
    }

    useEffect(()=>{
        //console.log("ENTRA 5: ")
       loadData(); 
    },[])

    //console.log("TIPOCIUDAD",tipoCiudad)
    
    return (
        <div style={{ backgroundColor: 'white' }}>            
            <div style={styles.container}>
                <Container>
                {/* PENDIENTE COMO SOPORTAR EFECTO BACK EN WEB  */}
                    <Row className="justify-content-md-center">
                        <Card className='reloadsCard' style={{ minWidth: '95%',borderColor:'#078FA5' }}>
                            <Card.Body>
                               <div style={{ borderBottomWidth: 1, backgroundColor: '#07A2BA',textAlign: 'center' }}>
                                    <p style={{fontWeight: 'bold'}} >Datos del Cliente</p>
                                </div>
                                <div style={styles.rowContainer} className="row">
                                    <div style={{width:'50%'}}>
                                        <p style={styles.title}>Nombre</p>
                                        <FormControl
                                               value={Contacto.FirstNames}
                                               onChange={e => setContacto({...Contacto,FirstNames:e.target.value})}                                                
                                            />
                                    </div>
                                    <div style={{width:'48%' , marginLeft:'2%',fontWeight: 'bold',align:'center'}}>
                                        <p /* style={{width:'48%', marginLeft:'2%'}} */>Apellidos</p>
                                        <FormControl
                                               value={Contacto.LastNames}
                                               onChange={e => setContacto({...Contacto,LastNames:e.target.value})}                                                
                                            />
                                    </div>
                                </div>
                                <div style={styles.rowContainer} className="row">
                                    {flagDocumentNotEdit&&<div>
                                        <div  style={{width:'100%'}}>
                                            <p style={styles.title}>Tipo de Documento</p>
                                            <p>{tipoDocumento.label}</p>  
                                        </div>
                                        <div  style={{width:'100%'}}>
                                            <p style={styles.title}>Documento</p>
                                            <p>{Contacto.DocumentNumber}</p> 
                                        </div>
                                    </div>}
                                    {flagDocumentEdit&&<div style={{width:'100%'}}>
                                        <p style={styles.title}>Tipo de Documento</p>
                                        <Fragment>
                                            <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            value={tipoDocumento}
                                            isDisabled={false}
                                            isLoading={false}
                                            isClearable={true}
                                            isRtl={false}
                                            isSearchable={true}
                                            name="color"
                                            options={opcionesTipoDocument}
                                            onChange={(value) => { setTipoDocumento(value) }}
                                            />
                                        </Fragment>
                                        {flagDocument&&
                                        <p  /* style={{color:'red'}} */>Este campo es obligatorio</p>} 
                                        <div style={{width:'100%'}}>
                                        <p /* style={{width:'50%',fontWeight: 'bold',align:'center'}} */>Documento</p>
                                        <FormControl                                              
                                               arial-label="Amount (to the nearest dollar)"
                                               value={Contacto.DocumentNumber}                                        
                                               maxLength={10}
                                               onChange={e=>{ setContacto({...Contacto,DocumentNumber:e.target.value.replace(/\D/, '')})}}                                              
                                            />
                                        </div>                                       
                                    </div>}
                                    
                                </div>
                                <div>
                                    <div style={{width:'100%'}}>
                                        <p /* style={{width:'48%', marginLeft:'2%',fontWeight: 'bold',align:'center'}} */>Departamento</p>
                                        <Fragment>
                                            <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            value={tipoDepartamento}
                                            isDisabled={false}
                                            isLoading={false}
                                            isClearable={true}
                                            isRtl={false}
                                            isSearchable={true}
                                            name="color"
                                            options={optionsDepartamentos}
                                            onChange={(value) => { setTipoDepartamento(value )  
                                                initialiceMunicipio(value) }}
                                            />
                                        </Fragment>
                                        {flagDepartamento&&<p /* style={{color:'red'}} */>Este campo es obligatorio</p>}
                                    </div>
                                </div>
                                <div>
                                    <div style={{width:'100%'}}>
                                        <p /* style={{width:'48%', marginLeft:'2%',fontWeight: 'bold',align:'center'}} */>Ciudad</p>
                                        <Fragment>
                                            <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            value={tipoCiudad}
                                            isDisabled={false}
                                            isLoading={false}
                                            isClearable={true}
                                            isRtl={false}
                                            isSearchable={true}
                                            name="color"
                                            options={opcionesCiudades}
                                            onChange={(value) => { setTipoCiudad(value )}}
                                            />
                                        </Fragment>                                
                                        {flagMunicipio&&<p /* style={{color:'red'}} */>Este campo es obligatorio</p>}
                                    </div>
                                </div>
                                <div>
                                    <div style={{width:'100%'}}>
                                        <p /* style={{width:'48%', marginLeft:'2%',fontWeight: 'bold',align:'center'}} */>Actividad Economica</p>
                                        <Fragment>
                                            <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            value={actividadesEconomicas}
                                            isDisabled={false}
                                            isLoading={false}
                                            isClearable={true}
                                            isRtl={false}
                                            isSearchable={true}
                                            name="color"
                                            options={optionsActividades}
                                            onChange={(value) => { setActividadesEconomicas(value ) }}
                                            />
                                        </Fragment> 
                                        {flagActividad&&<p /* style={{color:'red'}} */>Este campo es obligatorio</p>}
                                    </div>
                                </div>
                                <div>
                                    <div style={{width:'100%'}}>
                                        <p /* style={{width:'48%', marginLeft:'2%',fontWeight: 'bold',align:'center'}} */>Direccion</p>
                                        <FormControl
                                            value={address}
                                            onChange={e=>{ setAddress(e.target.value)}}                                              
                                        />
                                        {flagActividad&&<p /* style={{color:'red'}} */>Este campo es obligatorio</p>}
                                    </div>
                                </div>
                                <div style={{ margin: 5, borderWidth: 1, padding: 5, borderColor: '#078FA5', borderRadius: 10 }}>                    
                                <div style={{textAlign: 'center'}}>
                                    <Button
                                        onClick={verifyFields}
                                        style={{ backgroundColor: '#07A2BA', marginTop: '2%',widt:'50%'}}
                                    >Confirmar
                                    </Button>
                                </div>    
                                
                                </div>
                            </Card.Body>
                        </Card>
                    </Row>
                </Container> 

                
            </div>
        </ div>
    )
}

ClientInfo.propTypes = {
    vehicle: PropTypes.object,
    closeAction: PropTypes.func
}

ClientInfo.defaultProps = {
    vehicle: {},
    closeAction: () => { }
};

const styles = {
    title: {
        fontWeight: 'bold',
        align:'center'
    },
    container: {
        paddingHorizontal: 10,
        maxHeight: "100%",
        //backgroundColor: '#078FA5'
    },
    titleSummary: {
        alignSelf: 'center',
        fontWeight: 'bold',
        fontSize: 18,
        color: '#07A2BA'
    },
    colContainer: {
        width: "32%"
    },
    rowContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginVertical: 5,
        width:"100%",
        paddingLeft:15
    }
};