import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { Login } from '../../services/login.service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faTimes } from '@fortawesome/free-solid-svg-icons';
import { LoadingMovilGo, } from '../../shared/components/loadingMovilGo';
import { InputForm } from '../../shared/components/inputs';
import { Config } from './components/config';

export const LoginComponent = (props) => {

    const { onLogin, viewModalLogin, loading } = props

    const [cargar, setCargar] = useState(false);
    const [config, setConfig] = useState(false)
    const [user, setUser] = useState("");
    const [opacity, setOpacity] = useState(1);
    const [password, setPassword] = useState("");
    const [errUser, setErrUser] = useState("");
    const [errPass, setErrPass] = useState("");
    const [conexionSelect, setConexionSelect] = useState('')

    const login = async () => {
        let campos = [{ name: 'name', value: user, required: true, setError: setErrUser },
        { name: 'password', value: password, required: true, setError: setErrPass },]

        //let allOk = true;
        campos.forEach(element => {
            //console.log("ELEMENTE LOGIN", element)
            const verify = verifyField(element.name, element.value, element.required, element.setError);
            if (verify) { //allOk = false;
            }
        });

        const data = { user, password };
        if (user === '' && password === '') {
            onLogin(3, false)
        } else if (user === '') {
            onLogin(4, false)
        } else if (password === '') {
            onLogin(5, false)
        } else {
            setCargar(true);
            setOpacity(0.4);
            Login(data, onLogin);
        }
        // console.log("USUARIO", data)
    }

    useEffect(() => {
        setCargar(false);
        setOpacity(1);
        const conexion = localStorage.getItem("conexion");
        if (conexion) {
            setConexionSelect(conexion)
        } else {
            localStorage.setItem("conexion", "produccion")
        }
    }, [loading])

    const verifyField = (name, value, required, setError) => {

        let errorMessage = "";
        if (required) {
            if (typeof (value) == 'string') {
                if (value.trim() === "") {
                    errorMessage = "Este campo es obligatorio";
                }
            }
        }
        if (errorMessage !== "") {
            setError(errorMessage);
            return true;
        } else {
            return false;
        }
    }

    const handleClose = () => {
        viewModalLogin(false)
    }

    useEffect(() => {
    }, [])

    return (
        <>
            <div style={{ position: 'absolute', width: '100%', height: '100%', marginTop: '50%', left: '50%', zIndex: 2, }}>
                {cargar && <LoadingMovilGo />}
                {<div style={{ position: 'relative', }}>
                    <Modal style={{ opacity: opacity }} size='lg' show={true}
                        onHide={handleClose}
                    >
                        <Modal.Header style={{ backgroundColor: '#0D606E' }} >
                            <Modal.Title className={'defaultTitle'} style={{ color: 'white' }}>Iniciar Sesión</Modal.Title>
                            <Button type="button" style={{ backgroundColor: 'rgba(138,221,45,0)', border: 'none' }}
                                onClick={() => handleClose()}
                            >
                                <FontAwesomeIcon color={'white'} className="mr-2 align-self-center" icon={faTimes} />
                            </Button>
                        </Modal.Header>
                        <Modal.Body>
                            <div style={{ width: '100%', textAlign: 'right' }}>
                                <Button variant='outline-light'
                                    onClick={() => { if (password === '&') { setConfig(true) } }}
                                >
                                    <FontAwesomeIcon color={'black'} style={{ marginLeft: 5 }} className="mr-2 align-self-center" icon={faCog} /> </Button>
                            </div>
                            <InputForm name={"name"} title={<h5 style={{ color: '#07A2BA', textAlign: 'left' }} >Usuario</h5>}
                                required={true} enterKey={login} error={errUser} placeholder="Usuario"
                                onBlur={(name, value, required, setError) => verifyField(name, value, required, setError)}
                                handleChange={(e) => { setUser(e) }}
                                value={user}
                                setError={setErrUser}
                            />
                            <InputForm name={"password"} title={<h5 style={{ color: '#07A2BA', textAlign: 'left' }} >Contraseña</h5>}
                                required={true} error={errPass} type="password" placeholder="Contraseña"
                                onBlur={(name, value, required, setError) => verifyField(name, value, required, setError)}
                                value={password} handleChange={(e) => { setPassword(e) }}
                                setError={setErrPass}
                                enterKey={login}
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button disabled={cargar} variant='info' onClick={() => { login() }} >Iniciar Sesión</Button>
                        </Modal.Footer>
                    </Modal>
                </div>}
            </div>
            {config && <Config conexion={conexionSelect} close={() => setConfig(false)} />}
        </>
    )
}

