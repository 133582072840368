import React, { useEffect, useState } from 'react';
import { Table, Button, Container, Row, Col } from 'react-bootstrap';
import { ItemMoves, ItemError, ItemSummaryBox } from '../../screens/caja/components/item';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Excel } from './Excel';
import { getBox } from '../../services/caja.service';
import list from '../../assets/list/list.css';



export const ListMoves = (props) => {
    const { moves = [], products, rigthPage, leftPage, contPage, date, loading } = props
    const [isSmallScreen, setIsSmallScreen] = useState(false);

    const [exportEx, setExportEx] = useState(true)
    const [itemMoves, setItemMoves] = useState("");
    //const [fecha, setFecha] = useState(date);

    //console.log("fecha del excel",date)
    const searchMoves = () => {
        loading(true)
        getBox(0, 0, date, (data, flag) => {
            //console.log("data del excel ",data.movimientos.length)
            if (flag) {

                setItemMoves(data.movimientos.length)
            }
            loading(false)
        });
        //  console.log("esto es el item ",itemMoves)
    }

    useEffect(() => { searchMoves(); }, []);

    useEffect(() => {
        if (!exportEx) {
            const interval = setInterval(() => {
                setExportEx(true);
            }, 1000 * parseInt(itemMoves));
            return () => clearInterval(interval);
        }
    }, [exportEx]);

    useEffect(() => {
        movesTable();
        excelExport();
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 768); // Establece el estado según el ancho de la ventana
          };
      
          // Agrega el evento de escucha del cambio de tamaño de la ventana
          window.addEventListener('resize', handleResize);
      
          // Limpia el evento de escucha al desmontar el componente
          return () => {
            window.removeEventListener('resize', handleResize);
          };
    }, [exportEx])

    const excelExport = () => {
        if (!exportEx)
            return (
                <Excel
                    fecha={date}
                />
            )
    }

    const movesTableSmallScreen = () => {
        //console.log(exportEx)
        if (exportEx) {
            return (
                <>
                    <Container className='contenedor_tabla'>
                        {moves.map((obj, i) => {
                                    
                            return (
                            <React.Fragment key={i}>

                                <Row>
                                <Col>
                                    <Table className='tabla' style={{ marginTop: 30 }} striped bordered hover responsive="sm" variant="info">
                                    <thead>
                                        <tr>
                                        <th>ID</th>
                                        <th>Descripción</th>
                                        <th>Valor</th>
                                        <th>Tipo</th>
                                        </tr>
                                    </thead>
                                    <tbody >
                                        
                                        <ItemMoves parteTabla={true} itemMoves={obj} products={products}/>
                                    </tbody>
                                    </Table>
                                </Col>
                                </Row>

                                <Row>
                                <Col>
                                    <Table striped bordered hover responsive="sm" variant="info">
                                    <thead>
                                        <tr>
                                        <th>Aprobación</th>
                                        <th>Celular</th>
                                        <th>Comisión</th>
                                        <th>Fecha</th>
                                        <th>Hora</th>
                                        <th>---</th>
                                        </tr>
                                    </thead>
                                    <tbody >
                                        
                                        <ItemMoves parteTabla={false} itemMoves={obj} products={products}/>
                                    </tbody>
                                    </Table>
                                </Col>
                                </Row>
                            </React.Fragment>
                            );
                        })}
                    </Container>


                </>
            )
        }
    }

    const movesTable = () => {
        //console.log(exportEx)
        if (exportEx) {
            return (<Table striped bordered hover variant="info" >
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Descripción</th>
                        <th>Valor</th>
                        <th>Tipo</th>
                        <th>Aprobación</th>
                        <th>Celular</th>
                        <th>Comisión</th>
                        <th>Fecha</th>
                        <th>Hora</th>
                        <th>-----</th>
                    </tr>
                </thead>
                <tbody>
                    {moves.map((obj, i) => (
                        <ItemMoves key={i} itemMoves={obj} products={products} />
                    ))}

                </tbody>
            </Table>)
        }
    }

    if (isSmallScreen){
        return (
            <>
                <div className='contenedor_tabla' style={{ marginTop: 15 }}>
                    <div className="row">
                        <div className="col">

                        </div>
                        <div className="col">
                            <h3>Movimientos</h3>
                        </div>
                        <div className="col" >

                            <div className='row' style={{ marginLeft: '40%' }}>
                                <div className="col" >
                                    <label style={{ fontSize: 19 }}>Página: {contPage}</label>
                                </div>
                                <div className="col">

                                    <Button variant="link" onClick={leftPage}>
                                        <FontAwesomeIcon color={'black'} icon={faChevronLeft} />
                                    </Button>
                                    <Button variant="link" onClick={rigthPage}>
                                        <FontAwesomeIcon color={'black'} icon={faChevronRight} />
                                    </Button>

                                </div>
                            </div>

                        </div>
                    </div>
                    {/* <div className="col">
                        <Button className="btn btn-success float-left m-3" onClick={() => { setExportEx(!exportEx) }}>
                            Generar Excel
                        </Button>
                        {excelExport()}
                    </div> */}
                    {movesTableSmallScreen()}
                </div>
            </>
        )

    } else {
        return (
            <>
                <div style={{ marginTop: 15 }}>
                    <div className="row">
                        <div className="col">

                        </div>
                        <div className="col">
                            <h3>Movimientos</h3>
                        </div>
                        <div className="col" >

                            <div className='row' style={{ marginLeft: '40%' }}>
                                <div className="col" >
                                    <label style={{ fontSize: 19 }}>Página: {contPage}</label>
                                </div>
                                <div className="col">

                                    <Button variant="link" onClick={leftPage}>
                                        <FontAwesomeIcon color={'black'} icon={faChevronLeft} />
                                    </Button>
                                    <Button variant="link" onClick={rigthPage}>
                                        <FontAwesomeIcon color={'black'} icon={faChevronRight} />
                                    </Button>

                                </div>
                            </div>

                        </div>
                    </div>
                    {/* <div className="col">
                        <Button className="btn btn-success float-left m-3" onClick={() => { setExportEx(!exportEx) }}>
                            Generar Excel
                        </Button>
                        {excelExport()}
                    </div> */}
                    {movesTable()}
                </div>
            </>
        )
    }
}


export const ListErrors = (props) => {
    const { errors = [], rigthPage, leftPage, contPage } = props
    console.log("obj del error", errors)
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const [objetoError, setObjetoError] = useState([]);
    const [objetoKey, setObjetoKey] = useState('');
    console.log("pantalla pequeña", isSmallScreen)

    useEffect(() => {
        const handleResize = () => {
          setIsSmallScreen(window.innerWidth < 768); // Establece el estado según el ancho de la ventana
        };
    
        // Agrega el evento de escucha del cambio de tamaño de la ventana
        window.addEventListener('resize', handleResize);
    
        // Limpia el evento de escucha al desmontar el componente
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

      

      if (isSmallScreen){

                return (
                    <>
                        <div style={{ marginTop: 15 }} >
                            <div className="row">
                                <div className="col">

                                </div>
                                <div className="col">
                                    <h3>Errores</h3>
                                </div>
                                <div className="col" >
                                    <div className='row' style={{ marginLeft: '40%' }}>
                                        <div className="col" >
                                            <label style={{ fontSize: 19 }}>Página: {contPage}</label>
                                        </div>
                                        <div className="col">

                                            <Button variant="link" onClick={leftPage}>
                                                <FontAwesomeIcon color={'black'} icon={faChevronLeft} />
                                            </Button>
                                            <Button variant="link" onClick={rigthPage}>
                                                <FontAwesomeIcon color={'black'} icon={faChevronRight} />
                                            </Button>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <Container className='contenedor_tabla'>
                                {errors.map((obj, i) => {
                                    

                                    return (
                                    <React.Fragment key={i}>
                                        <Row>
                                        <Col>
                                            <Table className='tabla' style={{ marginTop: 30 }} striped bordered hover responsive="sm" variant="info">
                                            <thead>
                                                <tr>
                                                <th>ID</th>
                                                <th>Nombre</th>
                                                <th>Valor</th>
                                                <th>Celular</th>
                                                </tr>
                                            </thead>
                                            <tbody >
                                                
                                                <ItemError parteTabla={true} itemError={obj} />
                                            </tbody>
                                            </Table>
                                        </Col>
                                        </Row>
                                        <Row>
                                        <Col>
                                            <Table striped bordered hover responsive="sm" variant="info">
                                            <thead>
                                                <tr>
                                                <th>Hora</th>
                                                <th>Tipo</th>
                                                <th>Observación</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <ItemError parteTabla={false} itemError={obj} />
                                            </tbody>
                                            </Table>
                                        </Col>
                                        </Row>
                                    </React.Fragment>
                                    );
                                })}
                                </Container>




                        </div>
                    </>
                )
            }else {
                return (
                    <>
                        <div style={{ marginTop: 15 }}>
                            <div className="row">
                                <div className="col">
            
                                </div>
                                <div className="col">
                                    <h3>Errores</h3>
                                </div>
                                <div className="col" >
                                    <div className='row' style={{ marginLeft: '40%' }}>
                                        <div className="col" >
                                            <label style={{ fontSize: 19 }}>Página: {contPage}</label>
                                        </div>
                                        <div className="col">
            
                                            <Button variant="link" onClick={leftPage}>
                                                <FontAwesomeIcon color={'black'} icon={faChevronLeft} />
                                            </Button>
                                            <Button variant="link" onClick={rigthPage}>
                                                <FontAwesomeIcon color={'black'} icon={faChevronRight} />
                                            </Button>
            
                                        </div>
                                    </div>
                                </div>
                            </div>
            
                            <Table striped bordered hover variant="info">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Nombre</th>
                                        <th>Valor</th>
                                        <th>Celular</th>
                                        <th>Hora</th>
                                        <th>Tipo</th>
                                        <th>Observación</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {errors.map((obj, i) => (
                                        <ItemError key={i} itemError={obj} />
                                    ))}
            
                                </tbody>
                            </Table>
                        </div>
                    </>
                )
            
            }
        }
        

export const ListSummaryBox = (props) => {
    const { summaryBox = [] } = props
    return (
        <>
            <div style={{ marginTop: 15 }}>
                <h3>Resumen caja</h3>
                <Table striped bordered hover variant="info">
                    <thead>
                        <tr>
                            <th>Producto</th>
                            <th>Cantidad</th>
                            <th>Valor</th>
                        </tr>
                    </thead>
                    <tbody>
                        {summaryBox.map((obj, i) => (
                            <ItemSummaryBox key={i} itemSummary={obj} />
                        ))}

                    </tbody>
                </Table>
            </div>
        </>
    )
}

