import React, { PureComponent, Fragment } from 'react';
import { ListOperator } from '../../shared/components/list-operator';
import { Summary } from './components/summary';
import { ListPrices } from '../../shared/components/list-prices';
import { Container, Row, Col, Form, Card, Button, Image, Modal } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { Transaction } from '../../services/product.service';
import { LoadingMovilGo } from '../../shared/components/loadingMovilGo';
import { ModalNotificationTransaction } from '../../shared/components/modalNotificationTransaction';
import { ToastContainer, toast } from 'react-toastify';
import { ManagmentErrors } from '../../shared/components/managmentErrors';
import { jsPdfGenerator } from '../../shared/components/pdfTransaction';
import { FormatMoney } from '../../utilities/formats';
import Select from "react-select";
import { searchCertification, searchSnr } from '../../services/certification.service'

class Certificados extends PureComponent {


    constructor(props) {
        super(props);
        this.certificados = this.props.certificados;
        this.state = {
            Certificados: [],
            selectCertification: { id: -1, image_medium: null, name: '' },
            // selectedRecargaSport: { id: -1, title: 'None', color: 'white', },
            snrLicense: "",
            snrOffice: "",
            phone: "",
            email: "",
            document: '',
            //buttonCertification: [<View key={'key' + 1}></View>],
            handleClose: false,
            checkPhone: false,
            checkColor: 'white',
            errorDocument: '',
            errorPhone: '',
            errorEmail: '',
            errorLicense: '',
            errorOffice: '',
            payplace: {},
            package: {},
            modalInfoSnrLicense: false,
            optionPlaces: {},
            optionTowns: {},
            optionCode: {},
            //modalInfoVisible:false,
            modalInfoPay: false,
            placeSelected: { label: 'Seleccione un Departamento', value: -1 },
            townSelected: { label: 'Seleccione un Municipio', value: "" },
            codeSelected: { label: 'Seleccione un código', value: "" },
            loadplaces: false,
            loadTowns: false,
            selectedOperator: { id: -1, image_medium: null, name: '' },
            loading: true,
            visible: false,
            active: true,
            /* selectedMunicipio: {},
              Certificados: [],
              selectedPrice: '', 
              codigo: '',
              modalNotification: false, opacity: 1*/
        }
        this.places = [];
        this.towns = [];
        this.informacion = {};

        // this.products;        
        //this.time;
        /*  this.listCertificados = [];
         this.response = [];
         this.phone = '';
         this.valor = '';
         this.operador = '';
         this.clean = '' */
    }


    componentDidMount = () => {
        /* this.pressCertification(); */
        if (this.certificados) {
            //console.log("COMPONENT: ", this.certificados)
            this.setState({ loading: false })
        }

    }

    selectOperator = (certificado) => {
        // console.log("PUSH")
        // console.log("certificado: ", certificado)
        this.cleanFields();
        this.setState({ selectCertification: certificado });

    }

    pressCertification = (certification) => {
        //console.log("PASO1", certification.id)

        this.setState({
            loading: true,
            selectCertification: {
                id: certification.id,
                title: (certification.name),
                /* title: Capitalize(certification.name), */
                color: 'rgba(7,162,186,0.7)'
            }
        });
        searchCertification(certification.id, this.getPlacePay)
        this.setState({ active: false })
    }

    getPlacePay = (res, flag) => {

        if (flag) {
            this.setState({ loading: false })
            res.forEach((element) => {
                if (this.places[element.group]) {
                    this.places[element.group].push(element);
                } else {
                    this.places[element.group] = [];
                    this.places[element.group].push(element);
                }
            })
          
            this.loadPlaces(this.places)

        } else {
            this.setState({ loading: false })
            alert("Error", "No se obtuvieron lugares de pago");
        }
    }

    loadPlaces = (places/* , town */) => {
        const keys = Object.keys(places);//const keys2 = Object.keys(town);
        let deptos = []
        keys.forEach((element) => {
            //console.log("DEPTOS: ",element)
            deptos.push({ value: element, label: element })
        })
        //ASIGNACIÓN DEL VALOR DE "options" A LA VARIABLE "optionPlaces"
        this.setState({ optionPlaces: deptos/* , optionTowns:municipios */ })

    }

    initialiceTown = (places) => {
        // console.log("TRAE",this.places[places.value])
        let municipios = []
        /* let codigo = [] */

        /* if(places != null){ */
        this.places[places.value].forEach((element) => {
            municipios.push({ value: element.code, label: element.name })
            /* codigo.push({value:element.code,label:element.name}) */
        })

        this.setState({ optionTowns: municipios/* , optionCode:codigo */ })
        /* }else{alert("PRUEBA")} */
    }

    snrLicenseResumen = () => {
        this.setState({ loading: true })
        //console.log("DATOS- SNR code-licen-id",/* CODIGO: */this.state.townSelected.value,this.state.snrLicense,this.state.selectCertification.id)
        searchSnr(this.state.selectCertification.id, this.state.townSelected.value, this.state.snrLicense, this.snrLicense)
    }

    snrLicense = (flag, res) => {
        //console.log("responde del snrLicenseFFF",flag,res)
        if (flag) {
            //console.log("ENTRO AL IF")
            this.setState({ modalInfoPay: true, loading: true })
        } else {
            this.setState({ loading: false })
            alert("Error", "algo salio mal");
        }
    }

    cleanFields = () => {
        this.setState({

        })
    }

    handleClose = () => {
        this.setState({
            modalInfoPay: false, active: true,
            placeSelected: { label: 'Seleccione un Departamento', value: -1 },
            townSelected: { label: 'Seleccione un Municipio' }
        })
        /* this.setState({active:true})
        this.setState({active:true}) */
    }

    handleShow = () => {
        this.setState({ modalInfoPay: true })
    }

    render() {



        return (

            <>
                <style>
                    {` .my-auto {
                    margin-top: auto;
                    margin-bottom: auto;
                } .reloadsCard {
                    box-shadow: 0px 2px 4px 1px rgba(0,0,0,0.17);
                    border-radius: 1em;
                    margin: 5px;  } ` }
                </style>

                <ToastContainer />

                <div className="container" style={{ opacity: this.state.opacity }}>
                    <h2 className={'defaultTitle'}><b>Certificados</b></h2>
                    <Row className="justify-content-md-center">
                        <Col>
                            <ListOperator
                                onClick={this.pressCertification}
                                operators={this.certificados}
                            />
                        </Col>
                    </Row>
                    <Card className='reloadsCard'>
                        <Card.Body>
                            <Container className="" fluid='md'>
                                <div>
                                    {this.state.selectedOperator.image_medium &&
                                        <Image src={'data:image/jpeg;base64,' + this.state.selectedOperator.image_medium}
                                            style={{ maxHeight: '80px' }} />}
                                </div>
                                <Row>
                                    <Col md={12} xs={12} lg={6} className="my-auto">
                                        <h4 className={'defaultTitle'}>Certificado de Tradición</h4>
                                        <Form.Group className="mb-3" >


                                            <Select className="basic-single" classNamePrefix="select"
                                                isDisabled={this.state.active} isLoading={false} isClearable={true}
                                                isRtl={false} isSearchable={true} name="color"
                                                value={this.state.placeSelected}
                                                options={this.state.optionPlaces}
                                                onChange={
                                                    (value) => {
                                                        this.setState({ placeSelected: value });
                                                        if (value != null) { this.initialiceTown(value) }
                                                    }
                                                }
                                            />
                                        </Form.Group>

                                        {/* <Form.Group >  */}
                                        <Fragment>
                                            <Select className="basic-single" classNamePrefix="select"
                                                isDisabled={this.state.active} isLoading={false} isClearable={true}
                                                isRtl={false} isSearchable={true} name="color"
                                                value={this.state.townSelected}
                                                options={this.state.optionTowns}
                                                onChange={(value) => { this.setState({ townSelected: value }); }}
                                            />
                                        </Fragment>
                                        {/* </Form.Group> */}

                                        <Form.Group >
                                            <Form.Control placeholder='snrLicense'
                                                value={this.state.snrLicense}
                                                onChange={(event) =>
                                                    this.setState({ snrLicense: event.target.value })}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={12} xs={12} lg={6}>
                                        <h4 className={'defaultTitle'}>Resumen de compra</h4>
                                        <Card>
                                            <Summary
                                                departamento={this.state.placeSelected.label}
                                                municipio={this.state.townSelected.label}
                                                codigo={this.state.townSelected.value}
                                                snrLicense={this.state.snrLicense}
                                            // snrOffice={this.state.snrOffice}
                                            />
                                        </Card>
                                    </Col>
                                </Row>
                            </Container>
                        </Card.Body>
                        {!this.state.loading &&
                            <Card.Footer className="text-muted">
                                <Row>
                                    <Col>
                                        <Button
                                            size="lg"
                                            disabled={!this.state.placeSelected.label ||
                                                !this.state.townSelected.label ||
                                                !this.state.townSelected.value ||
                                                !this.state.snrLicense//||
                                                //!this.state.snrOffice
                                            }
                                            onClick={this.snrLicenseResumen}
                                        //variant={'info'}
                                        //onClick={this.sendData}
                                        > Buscar </Button>
                                    </Col>
                                </Row>
                            </Card.Footer>}
                    </Card>

                </div>

                <Modal show={this.state.modalInfoPay}>
                    <Modal.Header closeButton onClick={this.handleClose}>
                        <Modal.Title >Certificado</Modal.Title>
                    </Modal.Header>
                    <div style={{ backgroundColor: this.state.selectCertification.color, padding: 6, borderRadius: 10 }}>
                        <Modal.Body>
                            <div class="input-group input-group-lg">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="inputGroup-sizing-lg">Celular:</span>
                                </div>
                                <input type="text" class="form-control" aria-label="Large"
                                    aria-describedby="inputGroup-sizing-sm" value={this.state.phone}>
                                </input>
                            </div>
                            <br></br>
                            <div class="input-group input-group-lg">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="inputGroup-sizing-lg">Identificación:</span>
                                </div>
                                <input type="text" class="form-control" aria-label="Large"
                                    aria-describedby="inputGroup-sizing-sm" value={this.state.document}>
                                </input>
                            </div>
                            <br></br>
                            <div class="input-group input-group-lg">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="inputGroup-sizing-lg">E-mail:</span>
                                </div>
                                <input type="text" class="form-control" aria-label="Large"
                                    aria-describedby="inputGroup-sizing-sm" value={this.state.email}>
                                </input>
                            </div>
                        </Modal.Body>
                    </div>
                    <Modal.Footer>
                        <div style={{ width: '100%', }}>
                            <div style={{ textAlign: 'center' }}>
                                <Button /* disabled={activeCargar}  
                                    /* onClick={() => carryBag()}  */
                                    className='m-2' size="lg" variant="info" >
                                    Pagar
                                </Button>
                                <Button className='m-2'
                                    style={{ backgroundColor: 'transparent', borderColor: '#ccc', color: '#696969' }}
                                    onClick={this.handleClose}
                                >
                                    Cancelar
                                </Button>
                            </div>
                        </div>
                    </Modal.Footer>
                </Modal>
                {/*  {this.state.modalNotification && <ModalNotificationTransaction closeModal={(flag) => this.closeModal(flag)} type={"Pin"} message={"Recarga de " + this.state.selectedOperator.name + " realizada exitosamente"} />}
                {this.state.loading && <LoadingMovilGo />} */}
            </>
        )
    }
}



export default Certificados;