import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';

import Recargas from './screens/recargas/recargas';
import MenuServices from './screens/menu-services/menu-service';
import PaquetesComponent from './screens/paquetes/paquetes-component';
import RifasComponent from './screens/rifas/rifas-component';
import RecargasPines from './screens/recargas-pines/recargas-pines';
import Abonos from './screens/rifas/components/abonar';
import Compras from './screens/rifas/components/comprar';
import RecargasDeportivas from './screens/recargas-deportivas/recargas-deportivas';
import Caja from './screens/caja/caja';
import CajaFecha from './screens/cajafecha/cajafecha'
import { Desencrypt } from './utilities/cipher';
import HomeComponent from './screens/home/home-component';
import { Soat } from './screens/soat/soat';
import { SoatBuscar } from './screens/soat/buscar/buscar';
import { SoatComprar } from './screens/soat/comprar/comprar';
import { ShowRifa } from './screens/rifas/components/showRifa';
import { TermsAndConditions } from './screens/rifas/components/terms-and-conditions';
import { Political } from './screens/rifas/components/political';
import { Recaudo } from './screens/recaudo/recaudo';
import { SuperBingo } from './screens/super-bingo/super-bingo';
import Certificados from './screens/certificaciones/certificados';
//import Prestamos  from './screens/prestamos/prestamos';
//import  PrestamosBuscar  from './screens/prestamos/buscar/busca';
import { PrestamosBuscar } from './screens/prestamos/busca';
import { Desembolso } from './screens/prestamos/componentes/desembolso';
import {Pago} from './screens/prestamos/componentes/pago';

//import { PrestamosPagar } from './screens/prestamos/comprar/comprar';

const Routes = (props) => {
    const { products } = props;
    const data = Desencrypt();   

    if (data) {
        return (
            <Switch>
                <Route exact path="/" component={HomeComponent} />
                <Route exact sensitive path='/Servicios' component={MenuServices} />
                <Route exact sensitive path='/Caja' component={() => <Caja products={products} />} />
                <Route exact sensitive path='/CajaFecha' component={() => <CajaFecha products={products} />} />
                
                {/* PENDIENTE consumir o identificar como viene el logo de los certificados(products.recargasDeportivas, se cambia)*/}
                <Route exact sensitive path='/Certificados' component={() => <Certificados certificados={products.certificados} />} /> 

               {/*  <Route exact sensitive path='/Mitecnova' component={() => <Prestamos mitecnova={products.mitecnova} />} />  */}
                <Route exact sensitive path='/Prestamos/Desembolsar' component={() => <Desembolso mitecnova={products.mitecnova} />} />
                <Route exact sensitive path='/Prestamos/Pagar' component={() => <Pago mitecnova={products.mitecnova} />} />
                <Route exact sensitive path='/Prestamos/Busca' component={() => <PrestamosBuscar mitecnova={products.mitecnova} />} />
                
               {/*  <Route exact sensitive path='/Prestamos/Comprar' component={() => <PrestamosPagar mitecnova={products.mitecnova} />} /> */}
                
                <Route exact sensitive path='/Recargas' component={() => <Recargas recargas={products.recarga} />} />
                <Route exact sensitive path='/Rifas/Abonos' component={Abonos} />
                <Route exact sensitive path='/Rifas/Compras' component={Compras} />
                <Route exact sensitive path='/Paquetes' component={() => <PaquetesComponent listCharges={products.recarga} listPackages={products.paquete} />} />
                <Route exact sensitive path='/Rifas' component={() => <RifasComponent rifas={products.rifas} />} />
                <Route exact sensitive path='/Recargas-Deportivas' component={() => <RecargasDeportivas recargasDeportivas={products.recargasDeportivas} />} />
                <Route exact sensitive path='/Pines' component={() => <RecargasPines pinPines={products.pinPines} paquetesPines={products.paquetesPines} />} />
                
                <Route exact sensitive path='/Soat' component={() => <Soat soats={products.soats} />} />
                <Route exact sensitive path='/Soat/Buscar' component={() => <SoatBuscar soats={products.soats} />} />
                <Route exact sensitive path='/Soat/Comprar' component={() => <SoatComprar soats={products.soats} />} />
                
                <Route exact sensitive path='/Recaudo' component={() => <Recaudo />} />
                <Route exact sensitive path='/Super-Bingo' component={() => <SuperBingo bingo={products.bingo} />} />
                <Route exact sensitive path='/T/:t' component={ShowRifa} />
                <Route exact path="/terminos" component={TermsAndConditions} />
                <Route exact path="/Political" component={Political} />
                <Route exact path="*" component={HomeComponent} />


            </Switch>
        )
    } else {
        return (
            <Switch>
                <Route exact sensitive path='/T/:t' component={ShowRifa} />
                <Route exact path="/Political" component={Political} />
                <Route exact path="/terminos" component={TermsAndConditions} />
                <Route exact path="*" component={HomeComponent} />
            </Switch>
        )
    }

}

export default withRouter(Routes);