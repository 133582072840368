
export const OdooConfig = async () => {


        const config = {
                //pruebas
               /*  host: 'https://pruebasmovilgo.movilgo.com.co',
                port: '',
                database: 'pruebasmovilgo',
                encryptKey: 'zEA2tiTWsWABVz7El8OBxfLN7K5X1Qni', */

                //produccion Republica Dominicana
                host: 'https://movilgoadmin.movilgo.com.co',
                port: '',
                database: 'republicadominicana',
                encryptKey: 'zEA2tiTWsWABVz7El8OBxfLN7K5X1Qni'

                
                //produccion Cambiar la url home
                /* host: localStorage.getItem("conexion") === 'pruebas' ? 'http://45.56.65.237' : 'https://movilgoadmin.movilgo.com.co',
                port: localStorage.getItem("conexion") === 'pruebas' ? '8069' : '',
                database: localStorage.getItem("conexion") === 'pruebas' ? 'pruebasmovilgo' : 'movilgo',
                encryptKey: 'zEA2tiTWsWABVz7El8OBxfLN7K5X1Qni' */
                //
        }

        return config;
}
export const version = '1.3'
//pruebas
/* export const Url_Home='https://vendedorpruebas.movilgo.com.co/Rifas/' //http://45.79.43.96:8099/Rifas
export const Url_pago='http://45.79.43.96:8098/'
export const idCardoso='581' //Id padre Cardoso
export const idCardosoVendedor = '647'
export const idMobilgoVendedor = '648' */

//produccion Colombia
/* export const Url_Home='https://vendedor.movilgo.com.co/Rifas/'
export const Url_pago='https://movilgo.com.co/'
export const idCardoso='581' //Id padre Cardoso
export const idCardosoVendedor = '642'
export const idMobilgoVendedor = '643' */

//produccion republica dominicana
export const Url_Home='https://rdvendedor.movilgo.com.co/Rifas/'
export const Url_pago= 'https://rdcliente.movilgo.com.co/' 
export const idCardoso='581' //Id padre Cardoso
export const idRDominic='649' //Id Padre RepDom
export const idCardosoVendedor = '642'
export const idMobilgoVendedor = '643'
export const idRDominicVendedor = '647'
export const idRDominicFinal = '648'