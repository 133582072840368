import React, {/*  PureComponent,useEffect ,*/useEffect, useState/* , Fragment, useRef */ } from 'react';
import { Button, Card, Row, Container, FormControl, Modal, /* InputGroup,  */ModalBody } from 'react-bootstrap';
//import { Container, Row, Col, Form, Card, Button, Image} from 'react-bootstrap';
//import { DateToString } from '../../utilities/formats';
//import { LoadingMovilGo } from '../../shared/components/loadingMovilGo';
//import { Prestamos } from '../prestamos';
//import { Link } from 'react-router-dom';
import { searchPrestamo } from '../../services/nova.service';
import Select from "react-select";
import { Pago } from './componentes/pago';
import { Desembolso } from './componentes/desembolso';
import { LoadingMovilGo } from '../../shared/components/loadingMovilGo';
import { ModalNotificationTransaction } from '../../shared/components/modalNotificationTransaction';
import { jsPdfGenerator } from '../../shared/components/pdfTransaction';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ManagmentErrors } from '../../shared/components/managmentErrors';

export const PrestamosBuscar = (props) => {

   /* const [cedula, setCedula] = useState("");
   const [id_solicitud, setId_solicitud] = useState(""); */
   /* const [cedula, setCedula] = useState("105456925");
   const [id_solicitud, setId_solicitud] = useState("6079"); */
   const [cedula, setCedula] = useState("987085244");
   const [id_solicitud, setId_solicitud] = useState("6081");
   const [tipo, setTipo] = useState("");
   const [produc_id, setProduc_id] = useState('');
   const [showDesembolso, setShowDesembolso] = useState(false);
   const [showPago, setShowPago] = useState(false);
   const [loading, setLoading] = useState(false);
   const [data, setData] = useState({});
   const [notification, setNotification] = useState(false);
   const [dataPago, setDataPago] = useState();
   const [dataInfo, setDataInfo] = useState();
   const [deuda, setDeuda] = useState();
   const [abonado, setAbonado] = useState(0);

   var cedulita = 0;

   const clearData = () => {
      setLoading(false);
      setShowDesembolso(false)
      setShowPago(false)
      setCedula('');
      setId_solicitud('');
   };

   const searchDisburse = (datatipo) => {

      setLoading(true)
      setTipo(datatipo)

      const newLocal = JSON.stringify(props.mitecnova).replace(/\\/g, '');
      let str = newLocal;
      str = newLocal.replace('[', '');
      str = str.replace(']', '');
      const productNova = JSON.parse(str);

      setProduc_id(productNova.id)

      //console.log("NOVA", productNova.id)

      if (datatipo === "venta") {
         //searchedData()
         searchPrestamo(productNova.id, datatipo, id_solicitud, cedula, searchedData)
      } else {
         searchPrestamo(productNova.id, datatipo, id_solicitud, cedula, searchedData)
      }
   }

   const searchedData = (res, flag, tipo2) => {

      //console.log("----> INFORMACION cedula, id_solicitud", cedula, id_solicitud)
      if (flag) {
         // console.log("1")
         if (res.errores) {
            // console.log("2")
            const error = () => toast.error(res.errores.observacion);
            error()
            alert('Error, ' + res.errores.observacion);
            setLoading(false)
         } else {
            //console.log("3")
            if (res.solictud_ids.length === 0) {
               //console.log("4")
               //alert( 'Error, Por favor verifique la información ingresada' )   
               const success = () => toast.error("Error, Por favor verifique la información ingresada");
               success();
               setLoading(false)
            } else {
               //console.log("5")
               if (tipo2.toString() === 'venta') {
                  setData(res.solictud_ids[0])
                  setShowDesembolso(true);
               } else {
                  // console.log("6")
                  setData(res.solictud_ids[0])
                  setShowPago(true)
               }
            }
         }
      } else {
         /* console.log("7")
         setLoading(false)
         //setData(res.solictud_ids[0])
         setShowDesembolso(true); */
         /*          alert( 'Error, Por favor verifique los datos. Si el error persiste comuniquese con MovilGo',
                    [{text: 'Volver', onPress: () => clearData()}],
                  ); */
         //const message = ManagmentErrors("response.errores.id");
         //const notify = () => toast("Wow so easy!");

         //const message = ManagmentErrors();
         const error = () => toast.error("Error, Por favor verifique los datos. Si el error persiste comuniquese con MóvilGo");
         error();
      }
   };

   const saveInfoPay = (responsePago, info, abono) => {
      //console.log("recibo ", responsePago)
      //console.log("info ", info)
      // console.log("ABONO ", abono)
      setDataPago(responsePago)
      setDataInfo(info)
      if (info.data.valor_deuda) {
         // console.log("PAGO ", info.data.valor_deuda)
         setDeuda(info.data.valor_deuda)
         console.log("valor_deuda ", deuda)
      } else {
         // console.log("V dese ", info.data.solicitud_valoraprobado)
         setDeuda(info.data.solicitud_valoraprobado)
         // console.log("valoraprobado ", deuda)
         // //console.log("llega document antes", dataInfo.document)
         // //cedulita = info.document;
         // console.log("cedulita ", info.document);
         // setId_solicitud(info.data.id)
         // console.log(" llega id_solicitud ", id_solicitud)
      }
      setAbonado(abono)
      //closeModal()
   }

   const closeModal = (flag, response) => {
      setNotification(flag);

      // console.log("flag", flag, response)
      // console.log("cedula", cedula,)
      // console.log("DEUDA", deuda)
      // console.log("id_solicitud", id_solicitud)
      // console.log("--->dataPago", dataPago)    
      var data = []
      if (abonado) {
         data = [
            "Factura No: " + dataPago.valida.id,
            "Fecha:" + dataPago.valida.fecha,
            "No.Aprobacion:" + dataPago.valida.numero_aprobacion,
            "Id. Cliente: " + cedula,
            "Id. Solicitud: " + id_solicitud,
            "Valor del Abono: " + abonado,
            "Saldo: " + (deuda - abonado),
            // "Deuda: " + (deuda - abonado),
         ]
      }
      else {
         // console.log("FINcedula", cedula)
         // console.log("FINid_solicitud", id_solicitud)
         data = [
            "Factura No: " + dataPago.valida.id,
            "Fecha:" + dataPago.valida.fecha,
            "No.Aprobacion:" + dataPago.valida.numero_aprobacion,
            "Id. Cliente: " + dataInfo.document,
            "Id. Solicitud: " + dataInfo.data.id,
            "Valor del Desembolso: " + deuda,
            //"Saldo: " + (deuda - abonado),
         ]
         // console.log("FINcedula 2", cedula)
         // console.log("FINid_solicitud 2", id_solicitud)
      }

      //console.log("DATADATA", data)
      jsPdfGenerator(data);
      clearData();
   }
   return (
      <>
         {loading && <LoadingMovilGo />}
         <ToastContainer />
         <div style={{ backgroundColor: "white" }}>
            <div style={styles.container}>
               <Container style={{ marginTop: '5%' }}>
                  <h2>Consulta de Crédito</h2>     <br></br>
                  <Row className="justify-content-md-center">
                     <Card className="reloadsCard" style={{ minWidth: "40%", borderColor: "#078FA5" }} >
                        <Card.Body>
                           <h3>Ingrese el Id</h3>
                           <FormControl value={id_solicitud} placeholder="Id de la solicitud"
                              onChange={e => { setId_solicitud(e.target.value) }}
                           />
                           <br></br>
                           <h3>Ingrese n° Cédula</h3>
                           <FormControl placeholder="Cédula" value={cedula}
                              onChange={e => { setCedula(e.target.value) }}
                           />
                           <br></br>
                           <h3>¿Qué desea realizar?</h3>

                           <Button variant={'info'} className="mt-2"
                              onClick={() => { searchDisburse("venta") }}
                           >Desembolsar
                           </Button>

                           <Button style={{ marginLeft: '10%' }} variant={'info'} className="mt-2"
                              onClick={() => { searchDisburse('pago') }}
                           >Pagar
                           </Button>

                           <Modal style={{ height: '98%' }} show={showDesembolso}
                              onHide={() => { { setShowDesembolso(false) }; { setLoading(false) } }} backdrop="static" >
                              <Modal.Header closeButton>
                                 <Modal.Title>Realizar Desembolso</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                 <Desembolso
                                    data={data}
                                    loading={loading}
                                    document={cedula}
                                    productId={produc_id}
                                    closeAction={() => setShowDesembolso(false)}
                                    clean={() => clearData()}
                                    setLoading={(flag) => setLoading(loading)}
                                    finish={(flag) => setNotification(flag)}
                                    rst={(infoRecibo, info, abono) => (saveInfoPay(infoRecibo, info, abono))}
                                 //rst={(infoRecibo) => (saveInfoPay(infoRecibo))}
                                 />
                              </Modal.Body>
                           </Modal>

                           <Modal show={showPago} onHide={() => { { setShowPago(false) }; { setLoading(false) }/* clearData() */ }}
                              backdrop="static" >
                              <Modal.Header closeButton>
                                 <Modal.Title>Realizar Pago</Modal.Title>
                              </Modal.Header>
                              <ModalBody>
                                 <Pago
                                    data={data}
                                    loading={loading}
                                    document={cedula}
                                    productId={produc_id}
                                    closeAction={() => setShowPago(false)}
                                    clean={() => clearData()}
                                    setLoading={(flag) => setLoading(loading)}
                                    finish={(flag) => setNotification(flag)}
                                    rst={(infoRecibo, info, abono) => (saveInfoPay(infoRecibo, info, abono))}
                                 >
                                 </Pago>
                              </ModalBody>
                           </Modal>

                           {notification &&
                              <ModalNotificationTransaction
                                 /* closeModal={(flag) => closeModal(flag)} */
                                 closeModal={(flag) => closeModal(flag)}
                                 type={"Crédito"}
                                 message={"Crédito diligenciado exitosamente"} />
                           }

                        </Card.Body>
                     </Card>
                  </Row>
               </Container>
            </div>
         </div>
         <div /* style={styles.loading} */>
            {/* <ActivityIndicator
            size="large"
            color="#0000ff"
            animating={loading}></ActivityIndicator> */}
         </div>
      </>
   );
};

const styles = {
   title: {
      fontWeight: "bold",
      align: "center",
   },
   container: {
      paddingHorizontal: 10,
      maxHeight: "100%",
      //backgroundColor: '#078FA5'
   },
   titleSummary: {
      alignSelf: "center",
      fontWeight: "bold",
      fontSize: 18,
      color: "#07A2BA",
   },
   colContainer: {
      width: "32%",
   },
   rowContainer: {
      flexDirection: "row",
      justifyContent: "space-between",
      marginVertical: 5,
      width: "100%",
      paddingLeft: 15,
   },
};
