import Odoo from 'odoo-xmlrpc';

import { OdooConfig } from '../utilities/odoo-config';
import { Desencrypt } from '../utilities/cipher';

export const getBox = async (type, page, fecha, callback) => {

    const config = await OdooConfig();
    let finish = false;

    const args = [[0, { pagina: page, fecha, tipo: type }]]

    /* const args = [[ 0, { tipo: type, pagina: page, fecha} ]] */
    // console.log("CONTENIDO args GETBOX", args)     

    const data = await Desencrypt()
    const params = {
        model: "movilgo.webservice",
        method: "obtenerDatosCaja",
        args: args,
        kwargs: {}
    }

    setTimeout(() => {
        if (finish) {
            return;
        }
        alert("Tiempo limite excedidoo");
        callback(false, "");
        return;
    }, 30000);

    const client = new Odoo({
        url: config.host,
        port: config.port,
        db: config.database,
        username: data.user,
        password: data.password
    });


    client.connect((err, response) => {
        //console.log("--->connect", err, response)
        if (err) {
            return callback(err, false)
        } else {
            client.execute_kw(params.model, params.method, params.args, (err, response) => {
                //console.log("contenido del response del getbox", response)
                //    console.log("contenido del err del getbox", err)
                finish = true
                if (err) {
                    //console.log("--->Error", err)
                    callback(err, false);
                    return false;
                }
                //console.log("este es el repsonde del excel",response)
                /* callback(true, response ); */
                //console.log("salta al esntro desde caja.service")
                callback(response, true);

            });
        }

    })

}

export const getReport = async (fecha, callback) => {
    const config = await OdooConfig();
    let finish = false;
    const args = [[
        0, fecha
    ]]

    const data = await Desencrypt();
    const params = {
        model: "movilgo.webservice",
        method: "obtenerResumenCaja",

    }
    setTimeout(() => {
        if (finish) {
            return;
        }
        alert("Tiempo limite excedidoo");
        callback(false, "");
        return;
    }, 30000);

    const client = new Odoo({
        url: config.host,
        port: config.port,
        db: config.database,
        username: data.user,
        password: data.password
    });
    //console.log("args de reporte ventas",args)

    client.connect((err, response) => {
        if (err) {
            return callback(response, false)
        } else {
            client.execute_kw(params.model, params.method, args, async (err, response) => {
                finish = true
                if (err) {
                    callback(err, false);
                    return false;
                }
                callback(response, true);
            });
        }
    })

}


