import React, { useEffect, useState } from 'react';
import { Card, Button, Container,  } from 'react-bootstrap';
import { FormatMoney } from '../../utilities/formats';
import "react-multi-carousel/lib/styles.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { HomeWrapper } from './style'

export const Carrusel = (props) => {

    /* console.log("PROPS CARRUSEL: ", props) */
    const { packages = [], onClick } = props;
    //console.log("PAQUETE: ", packages)

    const [cards, setCards] = useState([]);
    const size = useWindowSize();
    const [numberCards, setNumberCards] = useState(2);
    
    function useWindowSize() 
    {
        const [windowSize, setWindowSize] = useState({ width: undefined, height: undefined, });        
        useEffect(() => {
            function handleResize() {
                setWindowSize({
                    width: window.innerWidth,
                    height: window.innerHeight,
                });
            }
            window.addEventListener("resize", handleResize);
            handleResize();

            return () => window.removeEventListener("resize", handleResize);
        }, []);
        return windowSize;
    }

    useEffect(() => {
        if (size.width <= 768 && size.height <= 1024) {
            setNumberCards(1)
        }else if(size.width >= 768 && size.height >= 800){
            setNumberCards(2)
        }else{
            setNumberCards(3)
        }
    }, [size]);


    let settings = {
        arrows: true,
        dots: false,
        accesibility: true,
        infinite: false,
        speed: 300,
        slidesToShow: numberCards,
        slidesToScroll: 1,
        adaptiveHeight: false
    };

    useEffect(() => {      
        let aux = [];
        packages.forEach(element => {
            //console.log("ELEMENET FORE CAROUSEL", element)
            aux.push(
                <div key={element.id} className="col-xs-12" style={{ padding:'10'}}>
                    <Card className="text-center" border="info" style={{ margin: 'auto',minHeight:'21rem',maxWidth:'25rem' }}>
                        <Card.Body>
                            <Card.Text>
                                {element.name}
                            </Card.Text>
                            <Card.Text>
                                Valor {FormatMoney(element.precio)}
                            </Card.Text>
                            <Card.Text>
                                Vigenica {element.vigencia} Dias
                            </Card.Text>
                        </Card.Body>
                        <Card.Footer className="text-muted" >
                            <Button variant='info' 
                            onClick={() => 
                            onClick(element.name, element.precio, element.id)}>
                                Seleccionar
                            </Button>
                        </Card.Footer>
                    </Card>
                </div>
            );
        });
        setCards(aux);
    }, [packages, onClick]);

    return (
        <Container>
            <HomeWrapper>         
                <Slider {...settings} >
                    {cards}
                </Slider>
            </HomeWrapper>
        </Container>
    )
}