import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Button, Modal, Card } from 'react-bootstrap';
import { FormatMoney } from '../../../utilities/formats';
import { ListPrices } from '../../../shared/components/list-prices';
import NumberFormat from 'react-number-format';
import { Transaction } from '../../../services/product.service'
import { ManagmentErrors } from '../../../shared/components/managmentErrors';
import { useToasts } from 'react-toast-notifications'
import { toast } from 'react-toastify';
import { paySeller } from '../../../services/collector.service';
import { payCollector } from '../../../services/collector.service';

//import { OdooConfig } from '../../../utilities/odoo-config';
import { Desencrypt } from '../../../utilities/cipher';
//import Odoo from 'odoo-xmlrpc';

export const DetailBox = (props) => {

   //mensajes tipo alert
   const { addToast } = useToasts();
   // apertura y cierre de modales
   const [show, setShow] = useState(false);
   const [showPago, setModalPayment] = useState(false);
   //hooks asosciados a cargar comprobante de pago
   const [pay, setPay] = useState();
   const [descriptionPay, setDescriptionPay] = useState("")
   const [imgPrevious, setImgPrevious] = useState("");
   //const [tipo, setTipo] = useState("documento_pago_cliente");
   //hooks asociados a cargar bolas
   const [selectedPrice, setSelectedPrice] = useState('');
   const [visible, setVisible] = useState(false);
   const [activeCargar, setActiveCargar] = useState(true);
   const [loading, setLoading] = useState(false);
   // Asociado al tipo de login o usuario
   const [userlog, setUserlog] = useState('');

   const modalPayment = () => setModalPayment(true);
   const handleShow = () => setShow(true);
   const handleClose = () => {
      setShow(false);
      setActiveCargar(true);
      setModalPayment(false);
      setPay(0); setDescriptionPay(""); setImgPrevious(null)
   };

   const { detailBox } = props

   useEffect(() => {
      checkPermission();
   }, [])

   const checkPermission = async () => {
      //const config = await OdooConfig();
      const data = await Desencrypt();
      if (data) {
         // console.log("DetailBox - DATA", data)
         setUserlog(localStorage.getItem('Permission'))
      }
   }

   const selectPrice = (price) => {
      if (typeof (price) === 'string' && price.toLowerCase() === 'otro valor') {
         setVisible(true); setSelectedPrice(''); setActiveCargar(false);
      }
      else {
         setSelectedPrice(price); setVisible(false); setActiveCargar(false);
      }
   }

   const navigateNext = (flag, response) => {
      //console.log("flag, response", flag, response)
      if (flag) {
         addToast("Carga bolsa exitosa", {
            appearance: 'success', autoDismiss: true,
         });
         setShow(false);
      } else {
         const message = ManagmentErrors(response.errores.id);
         addToast(message, {
            appearance: 'error', autoDismiss: true,
         });
         setShow(false);
      }
   }

   const carryBag = async () => {
      try {
         let idProduct = -1
         let id = localStorage.getItem("CargaBolsa");
         idProduct = parseInt(id)
         const product = {
            product_id: idProduct,
            atributes: {
               precio: parseInt(selectedPrice),
            }
         }
         await Transaction(product, navigateNext);
      } catch (error) {
         //console.log("error sendToOdoo", error);
         alert("Error" + "Error inesperado.");
      }
   }

   function DragArea() {
      //const[imgPrevious, setImgPrevious] = useState(null);
      const changeImg = (e) => {

         const reader = new FileReader();
         reader.readAsDataURL(e.target.files[0])
         reader.onload = (e) => {
            //console.log("e de la imagen", e.target.result)
            e.preventDefault();
            setImgPrevious(e.target.result);
         }
      }

      return (
         <div>
            {/* <div className='image-upload-wrap'> */}
            <div className='mb-3'>
               <input className='file-upload-input'
                  type="file"
                  multiple
                  accept="image/*"
                  onChange={e => { changeImg(e); }}
               >
               </input>
            </div>
            <div>
               <img src={imgPrevious} alt="" height="250px" width="350px" ></img>
            </div>
         </div>
      )
   }

   const sendPhoto = (pay) => {
      //console.log(" Entra paso 1", pay)
      /* console.log(" READER SENDPHOTO", imgPrevious) */

      if (pay > 999 /* && imgPrevious != '' */) {
         /* console.log("entra al if") */
         sendToOdoo(pay);
      } else {
         alert("Error, El valor debe ser mayor a $1000");
      }
      /* if (reader) {   //this.setState({ "loading": true }); //getPrintConfig(this.navigateToPrintConfig);*/
   }


   const sendToOdoo = async (pay) => {
      /* console.log("Entra paso 2 PAY", pay)
      console.log("usuario: ", userlog) */

      if (userlog == "vendedor") {
         //console.log("usuario: ", userlog)
         let json = imgPrevious 
         json = json.replace('data:image/jpeg;base64,', '')
         json = json.replace('data:image/jpg;base64,', '')
         json = json.replace('data:image/png;base64,', '')
         json = json.replace('', '')
         let consignacion = json;

         try {
            //console.log("Entra paso 2 TRY", pay)
            if (imgPrevious != '') {
               setLoading(true);
               const data = {
                  "amount": pay,
                  consignacion,
                  "descripcion": descriptionPay,
                  "tipo": "documento_pago_cliente" //tipo,//
               };
               //console.log(" DATA - sendToOdoo", data)
               await paySeller(data, navigateNextPhoto);
            } else {
               alert("Error, Aún no ha adjuntado su comprobante")
            }
         } catch (error) {
            //console.log("Entra paso 2 CATCH", pay)
            alert("Error", "Error inesperado.");
            setLoading(false);
            setDescriptionPay('');
            setPay(''); setImgPrevious(null);
         }
      } else {
         // console.log("usuario: COBRADOR ", userlog)
         // console.log("imagen ", imgPrevious)
         let json = imgPrevious
         json = json.replace('data:image/jpeg;base64,', '')
         json = json.replace('', '')
         let consignacion = json;
         try {
            if (imgPrevious != '') {
               setLoading(true);
               const data = {
                  "amount": pay,
                  consignacion,
                  /* "consignacion": imgPrevious, */
                  "descripcion": descriptionPay,
               };
               // console.log(" DATA - sendToOdoo", data)
               await payCollector(data, navigateNextPhoto);
            } else {
               alert("Error, Aún no ha adjuntado su comprobante")
            }
         } catch (error) {
            alert("Error, Error inesperado.");
            /* this.setState({ "loading": false }); */
         }
      }
   }

   const navigateNextPhoto = (flag, response) => {
      //console.log(" paso 3 - navigateNextPhoto", flag, response)
      if (flag) {
         setLoading(false);

         toast.success("Factura cargada correctamente");
         setModalPayment(false);
         setDescriptionPay('');
         setPay('');
         setImgPrevious(null);
      } else {
         /* alert("¡Error!" + response.data.message) */
         setLoading(false);
      }
   }


   return (
      <>
         < style> {`
                    .titleCol {
                        font-weight:bold;
                    } 
                `}
         </ style>
         <div style={{ marginTop: 20, }} className='container'>
            <Row>
               <Col> <h4 className='titleCol'>Nombre caja: </h4>
                  <h5>{detailBox.nombre_caja}</h5>
               </Col>
               <Col> <h4 className='titleCol'>Estado: </h4>
                  <h5>{detailBox.estado}</h5>
               </Col>
               <Col> <h4 className='titleCol'>Fecha: </h4>
                  <h5>{detailBox.fecha}</h5>
               </Col>
               {userlog !== "cobrador" &&
                  <Col>
                     <Button variant='info' onClick={handleShow}>Carga bolsa</Button>
                  </Col>}

            </Row>
            <Row>
               <Col> <h4 className='titleCol'>Balance inicial: </h4>
                  <h5>{FormatMoney(detailBox.balanceInicial)}</h5>
               </Col>
               <Col> <h4 className='titleCol'>Balance final: </h4>
                  <h5>{FormatMoney(detailBox.balanceFinal)}</h5>
               </Col>
               <Col> <h4 className='titleCol'> Total a cobrar: </h4>
                  <h5>{FormatMoney(detailBox.debito)}</h5>
               </Col>
               <Col> <h4 className='titleCol'>Total ganancia: </h4>
                  <h5>{FormatMoney(detailBox.ganancia)}</h5>
               </Col>
               <Col> <h4 className='titleCol'>Total a pagar: </h4>
                  <h5>{FormatMoney(detailBox.credito)}</h5>
                  {userlog !== "administrador" &&
                     <Button variant='info'
                        onClick={modalPayment}
                     >Subir Factura</Button>
                  }
               </Col>
            </Row>
         </div>

         {/* MODAL PARA CARGAR BOLSA */}
         <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
               <Modal.Title>Carga bolsa</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <div className="justify-content-md-center col-xs-12 mb-2"  >
                  <ListPrices clean={() => { }} onClick={(i) => selectPrice(i)} prices={Prices} /><br />
                  {visible &&
                     <NumberFormat className="form-control mt-1"
                        thousandSeparator={true} prefix='$ '
                        placeholder='$ Montos de Mil'
                        value={selectedPrice}
                        maxLength={13}
                        onChange={(event) => { setSelectedPrice(parseInt(event.target.value.replace("$", "").replace(",", "").replace(",", ""))); setActiveCargar(false) }}
                     />
                  }
               </div>
            </Modal.Body>
            <Modal.Footer>
               <div style={{ width: '100%', }}>
                  <div style={{ textAlign: 'center' }}>
                     <Button disabled={activeCargar} onClick={() => carryBag()}
                        className='m-2' size="lg" variant="info">
                        Cargar </Button>
                     <Button className='m-2'
                        style={{ backgroundColor: 'transparent', borderColor: '#ccc', color: '#696969' }}
                        onClick={handleClose}>
                        Cancelar </Button>
                  </div>
               </div>
            </Modal.Footer>
         </Modal>

         {/* MODAL CARGAR CONSTANCIA DE PAGO */}
         <Modal show={showPago} onHide={handleClose}>
            <Modal.Header closeButton>
               <Modal.Title>Cargar recibo de pago</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <Card className="text-center" >
                  <Card.Body>
                     <div className="form-group" tyle={{ padding: "0 30% 0 30%" }}>
                        <h5>Valor a pagar</h5>
                        <NumberFormat maxLength={11} prefix='$' value={pay}
                           className="form-control mt-1" thousandSeparator={true}
                           placeholder='$ Montos de Mil - Ingrese el valor consignado'
                           onChange={(event) => setPay(parseInt(event.target.value.replace("$", "").replace(",", "")))}
                        />
                     </div>
                     <div className="form-group" tyle={{ padding: "0 30% 0 30%" }}>
                        <h5>Descripción</h5>
                        <Form.Group >
                           <Form.Control placeholder='Ejemplo: Consignación realizada el dia...'
                              value={descriptionPay}
                              onChange={(event) => setDescriptionPay((event.target.value))}
                           />
                        </Form.Group>
                     </div>
                     <div>
                        <div> Adjunta imagen por favor</div>
                        <DragArea>
                           {/* Espacio en el cual se cargará la imagen */}
                        </DragArea>
                     </div>
                  </Card.Body>
               </Card>
            </Modal.Body>
            <Modal.Footer>
               <div style={{ width: '100%', }}>
                  <div style={{ textAlign: 'center' }}>
                     <Button //disabled={activeCargar} 
                        onClick={() => sendPhoto(pay)}
                        className='m-2' size="lg" variant="info" >
                        Enviar
                     </Button>
                     <Button className='m-2'
                        style={{ backgroundColor: 'transparent', borderColor: '#ccc', color: '#696969' }}
                        onClick={handleClose}>
                        Cancelar
                     </Button>
                  </div>
               </div>
            </Modal.Footer>
         </Modal>
      </>
   )
}

const Prices = [10000, 20000, 30000, 50000, 'Otro valor']


    // const [upLoad, setUpLoad] = useState(false);
    // const [selectFactura, setSelectFactura] = useState('');
    // const handleVewUpLoad = () => setUpLoad(true);
    // const handleCloseUpLoad = () => setUpLoad(false);



