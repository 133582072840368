import Odoo from 'odoo-xmlrpc';
import { OdooConfig } from '../utilities/odoo-config';
import { Desencrypt } from '../utilities/cipher';

export const searchPrestamo = async (product_id, tipo, id_solicitud, cedula, callback) => {

  /* console.log("product_id",product_id)
  console.log("tipo",tipo)
  console.log("id_solicitud",id_solicitud)
  console.log("cedula",cedula) */

  const args = [[0, product_id, id_solicitud, cedula, tipo]];
  /*   console.log("SERVICIO ARGS ver",args) */

  const config = await OdooConfig();//SESSION
  const user = await Desencrypt();
  //console.log("user",user)        
  const client = new Odoo({
    url: config.host,
    port: config.port,
    db: config.database,
    username: user.user,
    password: user.password
  });
  //console.log("client", client)
  const params = {
    model: 'movilgo.webservice',
    method: 'obtenerSolicitudCreditoMovilgo',
  }
  /* const args = [0, product_id, ids, cc, t]; */
  /* const newArgs = [0, product_id, id_solicitud, cedula, tipo];
  console.log("newArgs", newArgs  )
    let str = JSON.stringify(newArgs).replace(/\\/g, '');
      str = str.replace('\'', '\ ');             
    let args = str;   
    console.log("SERVICIO ARGS ver",args)             
   */

  client.connect((err, response) => {

    if (err) {
      //console.log("NOCONEXION:", err)       
      return/*  callback(response, false) */;
    }
    /* else {   */
    client.execute_kw(params.model, params.method, args, (err2, response2) => { /*  */
      /*  console.log("Response NOVA", response2)
       console.log("err NOVA", err2)*/

      if (err2) {
        /* callback(err2, false); */
        callback(err2, false, null);
        return false;
      }
      ///////start
      /*  if (response2) {
         if (response2.errores && response2.errores.id) {
           callback(false, response2);
             return false;
         }
         callback(true, response2);
         return true;
     }
     return false; */
      //////end
      //console.log("err y respons2", response2)
      /////solo estaba lo siguiente
      callback(response2, true, tipo);
    });
    /*  } */
  })
}
/*   export const searchPrestamo = async (product_id,tipo,id_solicitud,cedula,callback) => {
    
  
  const args = [0, product_id, id_solicitud, cedula, tipo];
   console.log("SERVICIO tipo ARGS",args )
  
  const config = await OdooConfig();
  const data = await Desencrypt();



  const params = {
    model: 'movilgo.webservice',
    method: 'obtenerSolicitudCreditoMovilgo',
  };

  const client = new Odoo({
    url: config.host,
    port: config.port,
    db: config.database,
    username: data.user,
    password: data.password
  });
  console.log("client e nova", client)
  client.connect((response, err ) => {
    console.log("responsedel concet", response)
    console.log("err del conect", err)
    if (err) {    
                
        return callback(response, false,tipo)
    } else {
        client.execute_kw(params.model, params.method, args, (err, response) => {
          console.log("response AQUIIIIIIIIIIIIIIIIIIIIIIIIIIIII", response);
            if (err) {
                
                console.log("ERRAAAAAAAAAA", err)
                
                callback(err, false,tipo);
                return false;
            }
            callback(response, true,tipo);
        });
    }
  })
};
 */
