import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { ListNumberTicket } from '../../../shared/components/list-prices';

export const ModalBoletas = (props) => {
    const { visible, closeModal, numbersTicket,luckyNumber, selectTicket,chooseTicket } = props
    const [active, setActive] = useState(true)
    console.log("joojjojo modal boletas",numbersTicket)
    return (
        <>
            <Modal
                show={visible}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header>
                    <Modal.Title className={'defaultTitle'}>{luckyNumber ? "Su numero de la suerte es":"Boletas Disponibles"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ListNumberTicket active={(flag) => setActive(flag)} selectTicket={(number) => selectTicket(number)} numbers={numbersTicket} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { closeModal(false); setActive(true) }}>
                        Cancelar
                        </Button>
                    <Button disabled={active} variant="info" onClick={() => { chooseTicket(false); setActive(true) }}>Elegir Boleta</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}