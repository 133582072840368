import React, { PureComponent } from 'react';
import { ListOperator } from '../../shared/components/list-operator';
import { Summary } from './components/summary';
import { ListPrices } from '../../shared/components/list-prices';
import { Container, Row, Col, Form, Card, Button, Image } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { Transaction } from '../../services/product.service';
import { LoadingMovilGo } from '../../shared/components/loadingMovilGo';
import { ModalNotificationTransaction } from '../../shared/components/modalNotificationTransaction';
import { ToastContainer, toast } from 'react-toastify';
import { ManagmentErrors } from '../../shared/components/managmentErrors';
import { jsPdfGenerator } from '../../shared/components/pdfTransaction';
import { FormatMoney } from '../../utilities/formats';

class RecargasDeportivas extends PureComponent {

    constructor(props) {
        super(props);
        this.recargasDeportivas = this.props.recargasDeportivas;
        this.state = {
            visible: false,
            loading: true,
            selectedOperator: { id: -1, image_medium: null, name: '' },
            recargasDeportivas: [],
            selectedPrice: '',
            phone: '',
            idClient: '',
            modalNotification: false, opacity: 1
        }
        this.listRecargasDeportivas = [];
        this.response = [];
        this.phone = '';
        this.valor = '';
        this.operador = '';
        this.clean = ''
    }

    //*
    getResponse = (flag, response) => {
        //similar al navigateNext
        // console.log("flag y response: ",flag, response)
        if (flag) {
            this.response = response;
            const success = () => toast.success("Recarga realizada exitosamente");
            success();
            this.setState({
                modalNotification: true, opacity: 1, idClient: '',
                selectOperator: { id: -1, image_medium: null, name: '' },
                phone: '',
                selectPrice: ''
            });
        } else {
            const message = ManagmentErrors(response.errores.id);
            const error = () => toast.error(message);
            error();
            this.setState({ opacity: 1 })
        }
    }

    componentDidMount = () => {
        if (this.recargasDeportivas) {
            this.setState({ loading: false, })
        }else{
            this.setState({ loading: false })
            const error = () => toast.error("No tienes este producto disponible");
            error();
        }
    }

    //*
    // Check the real id of the require values 
    sendData = async () => {
        this.phone = this.state.phone;
        this.valor = this.state.selectedPrice;
        this.operador = this.state.selectedOperator.name
        //SIMILAR al sendToOdoo
        const product = {
            product_id: this.state.selectedOperator.id,
            atributes: {
                numero: this.state.phone,
                precio: this.state.selectedPrice,
                document: this.state.idClient
            }
        }
        this.setState({ opacity: 0.4 })
        await Transaction(product, this.getResponse);
    }

    selectOperator = (operator) => {
        this.cleanFields();
        this.setState({ selectedOperator: operator });
    }

    //*
    //SIMILAR AL pressPrice del movil
    selectPrice = (price) => {
        if (typeof (price) === 'string' && price.toLowerCase() === 'otro valor') {
            this.setState({ visible: true, selectedPrice: '' });
        }
        else {
            this.setState({ selectedPrice: price, visible: false });
        }
    }

    closeModal = (flag) => {
        this.setState({ modalNotification: flag });

        const data = [
            "Factura No: " + this.response.valida.id,
            "Fecha:" + this.response.valida.fecha,
            "No.Aprobacion:" + this.response.valida.numero_aprobacion,
            "Producto: " + this.operador,
            "Celular: " + this.phone,
            "Valor: " + FormatMoney(this.valor)
        ]
        jsPdfGenerator(data);
    }

    cleanFields = () => {
        this.setState({
            clean: false,
            selectedPrice: ''
        })
    }

    render() {
        return (
            <>
                <style>
                    {`
                .my-auto {
                    margin-top: auto;
                    margin-bottom: auto;
                } 
                .reloadsCard {
                    box-shadow: 0px 2px 4px 1px rgba(0,0,0,0.17);
                    border-radius: 1em;
                    margin: 5px;
                }
                ` }
                </style>
                <ToastContainer />
                <div className="container" style={{ opacity: this.state.opacity }}>
                    <h2 className={'defaultTitle'}><b>Apuestas Deportivas</b></h2>
                    <Row className="justify-content-md-center">
                        <Col>
                            <ListOperator
                                onClick={(i) => this.selectOperator(i)}
                                operators={this.recargasDeportivas} />
                        </Col>
                    </Row>
                    <Card className='reloadsCard'>
                        <Card.Body>
                            <Container className="" fluid='md'>
                                <div>
                                    {this.state.selectedOperator.image_medium &&
                                        <Image src={'data:image/jpeg;base64,' + this.state.selectedOperator.image_medium}
                                            style={{ maxHeight: '80px' }} />}
                                </div>
                                <Row >
                                    <Col md={12} xs={12} lg={6} className="my-auto">
                                        <Form.Group >
                                            <h4 className={'defaultTitle'}>Número de Celular</h4>
                                            <Form.Control
                                                placeholder='Celular'
                                                arial-label="Amount (to the nearest dollar)"
                                                value={this.state.phone}
                                                maxLength={10}
                                                onChange={(event) => this.setState({ phone: event.target.value.replace(/\D/, '') })}
                                            />
                                        </Form.Group>
                                        <Form.Group >
                                            <h4 className={'defaultTitle'}>Número de Cédula</h4>
                                            <Form.Control
                                                placeholder='Cédula'
                                                arial-label="Amount (to the nearest dollar)"
                                                value={this.state.idClient}
                                                maxLength={10}
                                                onChange={(event) => this.setState({ idClient: event.target.value.replace(/\D/, '') })}
                                            />
                                        </Form.Group>

                                        <div className="justify-content-md-center col-xs-12 mb-2"  >
                                            <ListPrices clean={(flag) => this.setState({ clean: flag })} limpiar={this.state.clean} onClick={(i) => this.selectPrice(i)} prices={Prices} /><br />
                                            {this.state.visible &&

                                                <NumberFormat className="form-control mt-1"
                                                    thousandSeparator={true}
                                                    prefix='$ '
                                                    placeholder='$ Montos de Mil'
                                                    value={this.state.selectedPrice}
                                                    maxLength={9}
                                                    onChange={(event) => this.setState({ selectedPrice: parseInt(event.target.value.replace("$", "").replace(",", "").replace(",", "")) })}
                                                />
                                            }
                                        </div>

                                    </Col>

                                    <Col md={12} xs={12} lg={6}>

                                        <h4 className={'defaultTitle'}>Resumen de Compra</h4>
                                        <Card>
                                            <Summary
                                                operator={this.state.selectedOperator.name}
                                                price={this.state.selectedPrice}
                                                phone={this.state.phone}
                                                idClient={this.state.idClient}
                                            />
                                        </Card>
                                    </Col>
                                </Row>
                            </Container>
                        </Card.Body>

                        {!this.state.loading && <Card.Footer className="text-muted">
                            <Button
                                size="lg"
                                disabled={!this.state.phone ||
                                    !this.state.selectedOperator.name ||
                                    !this.state.selectedPrice ||
                                    !this.state.idClient}
                                variant={'info'}
                                onClick={this.sendData}
                            >
                                Finalizar
                            </Button>
                        </Card.Footer>}
                    </Card>

                </div>
                {this.state.modalNotification && <ModalNotificationTransaction closeModal={(flag) => this.closeModal(flag)} type={"Pin"} message={"Recarga de " + this.state.selectedOperator.name + " realizada exitosamente"} />}
                {this.state.loading && <LoadingMovilGo />}
            </>
        )
    }
}



const Prices = [
    1000,
    2000,
    3000,
    5000,
    10000,
    20000,
    'Otro valor'
]

export default RecargasDeportivas;