import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt,faEnvelope, faMobileAlt } from '@fortawesome/free-solid-svg-icons'
import movilgoFrente from '../../assets/movilgoFrente.png'
import Logo from '../../assets/logo.png';

const Footer = () => {
    return (
        <>
            <style>
                {`
                    
                    .movilgoFooter{
                        height: 6vh;                    
                    }
                    .rowFooter{
                        padding: 15px;
                        bottom: 0;
                        height: 100%;
                        width: 100%;
                        background-color: #0D606E;
                        color: white;
                        text-align: center;  
                        marginLeft: '10rem',
                        marginRight: '10rem',
                        textAlign: 'left'
                        position: fixed;
                    }
                `}
            </style>
            <div style={{
                margin: '2px 0em 0 1em',
            }}>
                <footer className="movilgoFooter mt-4">
                    < div className="row">

                        <div className="col-9" style={{

                            'backgroundColor': '#0D606E',
                            'border': '2px solid #0D606E',
                            'textAlign': 'center',
                            'padding': 15
                        }}
                        >
                            <h6 style={{ color: 'white ' }} className="align-self-center">
                                <FontAwesomeIcon style={{ color: 'white ' }} className="mr-2 align-self-center" icon={faMapMarkerAlt} />
                                Capitalia Centro Empresarial - Carrera 23 # 62 - 39 (AV. Santander) - OF. 404B
                            </h6>
                            <h6 style={{ color: 'white ' }} className="align-self-center">
                                <FontAwesomeIcon style={{ color: 'white ' }} className="mr-2 align-self-center" icon={faMobileAlt} />
                                316 819 4210
                            </h6>
                            <h6 style={{ color: 'white ' }} className="align-self-center">
                                <FontAwesomeIcon style={{ color: 'white ' }} className="mr-2 align-self-center" icon={faEnvelope} />
                                mercadeo@movilgo.com.co
                            </h6>

                            <div style={{ color: 'white ' }} className="footer-copyright  py-3 ">© 2020 Copyright:
                                <a style={{ color: 'white ' }} href="https://reactiva.com.co/"> Reactiva.com.co</a>
                            </div>
                        </div>

                        <div className="col-3 border border-info " style={{
                            backgroundColor: 'white', textAlign: 'center', justifyContent: 'center'
                        }} >
                            <div style={{ marginTop: '5vh' }}>
                                <img alt="" src={Logo}
                                    style={{
                                        right: '30%',
                                        width: '55%',
                                        position: 'absolute'
                                    }}
                                    className="img-responsive"
                                    alt="logo"
                                />
                                <img className="img-responsive"
                                    style={{
                                        position: 'absolute',
                                        top: '8%',
                                        left: '70%',
                                        width: '15%',
                                        transform: 'rotate(0deg) scale(0.8)'
                                    }}
                                    alt="Saquenme de latinoamerica"
                                    src={movilgoFrente}
                                />
                            </div>
                        </div>
                    </div>
                </footer>
            </div >
        </>
    );
}

export default Footer;