import React, { PureComponent } from 'react';
import { LoadingMovilGo } from '../../shared/components/loadingMovilGo';
import { CarruselRifas } from './components/carousel-rifas';
import { ToastContainer, toast } from 'react-toastify';
class RifasComponent extends PureComponent {

    constructor(props) {
        super(props);
        this.rifas = this.props.rifas;

        this.state = {
            loading: true,
            visible: false,
            selectedRifa: {
                id: -1,
                image_medium: null,
                name: '',
                precio: '',
                fecha_sorteo: '',

            }
        }
      
    }
    componentDidMount = () => {
        //console.log("this.rifas",this.rifas)
        if (this.props.rifas) {
            this.setState({ loading: false })
        }/* else{
            
            this.setState({ loading: false })
            const error = () => toast.error("No tienes este producto disponible");
            error();
        } */
    }
    render() {
        return (
            <>
                <style>
                    {`
                .my-auto {
                    margin-top: auto;
                    margin-bottom: auto;
                } 
                .reloadsCard {
                    box-shadow: 0px 2px 4px 1px rgba(0,0,0,0.17);
                    border-radius: 1em;
                    margin: 5px;
                }
                `}
                </style>
                {this.state.loading && <LoadingMovilGo />}
                <ToastContainer />
                <h1 className={'defaultTitle'}>Rifas</h1>

                <div style={{ padding: '30px 2% 0px 2%' }}>

                    <CarruselRifas rifas={this.rifas} />

                </div>
            </>

        )
    }
}

export default RifasComponent;