import React, { PureComponent } from 'react';
import { ListOperator/* , ListTypes  */ } from '../../shared/components/list-operator';
import { Summary } from './components/summary';
import { Container, Row, Col, Form, Card, Button, Image, Modal, InputGroup } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { Transaction } from '../../services/product.service';
import { LoadingMovilGo } from '../../shared/components/loadingMovilGo';
import { ToastContainer, toast } from 'react-toastify';
import { ManagmentErrors } from '../../shared/components/managmentErrors';
import { ListPricesPines } from './components/list-prices';
import { ModalNotificationTransaction } from '../../shared/components/modalNotificationTransaction';
import { jsPdfGenerator } from '../../shared/components/pdfTransaction';
import { FormatMoney } from '../../utilities/formats';
import { Carrusel } from '../../shared/components/carousel';
//import { jsPdfGenerator } from '../../shared/components/pdfTransaction';

class RecargasPines extends PureComponent {

    constructor(props) {
        super(props);
        this.categoryPin = [];
        this.eachObjPin = [];
        this.nameGroupPin = [];
        this.nameEachPin = {};
        this.paquetes = [];
        this.packName = [];
        this.state = {
            selectedType: '',
            price: [],
            name: '',
            idPackage: -1,
            visible: false,
            viewSummary: false,
            loading: true,
            opacity: 1,
            selectedPin: { id: -1, image_medium: null, name: '' },
            id_pin: -1,
            pines: [],
            operators: [],
            vigencia: 0,
            phone: '',
            pinPackage: '',
            selectedPrice: 0,
            buttonsPines: [<div key={'key' + 1}></div>],
            email: '',
            selectedProduct: {},
            //showAnotherValueInput: false,
            modalInfoPines: false,
            modalNotification: false,
            resumeView: <></>,
            listTypes: <></>,
            errorEmail: '',
            // stateButtons: [false, false, false, false, false, false],
            carrouselView: false,

        }
        this.namePacket = '';
        this.packPinByOperator = {};
        this.listePricesByOperator = [];
        //this.paquetesPines = this.props.paquetesPines;
        this.paquetesPines = [];
        this.pinesByOperator = [];
        this.response = [];
        this.phone = '';
        this.valor = '';
        this.operador = '';
        this.clean = ''
    }

    cleanField = () => {
        this.setState({
            clean: false,
            name: '',
            price: ''
        })
    }

    componentDidMount = () => {

        this.categoryPin = this.props.pinPines;
        this.eachObjPin = this.props.paquetesPines;

        //console.log("CATEGORYPIN",this.categoryPin)

        if (this.categoryPin || this.eachObjPin) {
            this.setState({ loading: false })
            this.initialice();
        }else{
            
            this.setState({ loading: false })
            const error = () => toast.error("No tienes este producto disponible");
            error();
        }
    }

    initialice = () => {

        let nameGroup = []
        this.categoryPin.forEach(element => {
            if (element.operador) {
                nameGroup.push(element.operador)
            }
        })

        //console.log("NAMEGROUP: ",nameGroup)
        this.nameGroupPin = nameGroup;

        let listKindPin = [];
        // console.log("EACHOBJ",this.eachObjPin)
        this.eachObjPin.forEach(element => {
            if (listKindPin[element.operador]) {
                listKindPin[element.operador].push(element);
            } else {
                listKindPin[element.operador] = [];
                listKindPin[element.operador].push(element);
            }
        })
        //console.log("GRUPOS", listKindPin)               
        this.setState({ listKindPin });
    }

    componentWillUnmount = () => {
        this.packPinByOperator = {};
    }

    sendData = async () => {
        //console.log("entra1:", this.state.idPackage)
        this.namePacket = this.state.name;
        this.phone = this.state.phone;
        this.valor = this.state.price;
        this.operador = this.state.operador
        this.setState({ loading: true, opacity: 0.4 })
        const product = {
            product_id: this.state.idPackage,
            atributes: {
                correo: this.state.email,
                numero: this.state.phone,
                precio: this.state.price
            }
        }
        await Transaction(product, this.getResponse);
    }

    getResponse = (flag, response) => {
        // console.log("este es el response de paquetes ",response)
        if (flag) {
            this.response = response
            const success = () => toast.success("Producto comprado exitosamente");
            /* this.setState({phone: '',email:'',checkPhone:false }); */
            success();
            this.setState({ loading: false, opacity: 1, modalNotification: true, phone: '', email: '', checkPhone: false, name: '', operador: '', price: '' })
        } else {
            const message = ManagmentErrors(response.errores.id);
            const error = () => toast.error(message);
            error();
            this.setState({ loading: false, opacity: 1, phone: '', email: '', checkPhone: false, name: '', operador: '', price: '' })
        }
    }

    selectOperator = (operator) => {
        //console.log("PASO 1 RECARGA OPERADOR", operator)
        this.cleanField();
        let paquetes = this.state.listKindPin[operator]
        let operador = operator
        this.setState({ paquetes })
        this.setState({ operador })
        this.setState({ carrouselView: true })
    }

    closeModal = (flag) => {
        this.setState({ modalNotification: flag });
        const data = [
            "Factura No: " + this.response.valida.id,
            "Fecha:" + this.response.valida.fecha,
            "No.Aprobacion:" + this.response.valida.numero_aprobacion,
            "Producto: Paquete  " + this.state.name,
            "Celular: " + this.phone,
            "Valor: " + FormatMoney(this.valor)
        ]
        jsPdfGenerator(data);
    }

    render() {
        return (
            <>
                <style>
                    {`
                .my-auto {
                    margin-top: auto;
                    margin-bottom: auto;
                } 
                .reloadsCard {
                    box-shadow: 0px 2px 4px 1px rgba(0,0,0,0.17);
                    border-radius: 1em;
                    margin: 5px;
                }
                ` }
                </style>

                <div className="contenedor" style={{ opacity: this.state.opacity }} >
                    <ToastContainer />
                    {/* <div className="container" style={{ opacity: this.state.opacity }}> */}
                    <div className="container" style={{ opacity: this.state.buttonsPines }}></div>

                    <h2 className={'defaultTitle'}>Pines</h2>
                    <Row className="justify-content-md-center">
                        {/* MUESTRA LISTADO DE PINES  */}
                        <Col>
                            {this.state.operators &&
                                <ListOperator onClick={(operators) =>
                                    this.selectOperator(operators.operador)}
                                    operators={(this.categoryPin)}
                                />
                            }
                        </Col>
                    </Row>
                    <Card className='reloadsCard'>
                        <Card.Body>
                            <Row className="justify-content-md-center">
                                {<Col xs lg="2">
                                    {this.state.selectedPin.image_medium &&
                                        <Image src={'data:image/jpeg;base64,' + this.state.selectedPin.image_medium}
                                            style={{ maxHeight: '80px' }} />}
                                </Col>}
                            </Row>
                            <hr />
                            <Row>
                                <Col xs={12} md={8} style={{ padding: "10px" }}>
                                    {this.state.carrouselView &&
                                        <Carrusel onClick={(namePacket, pricePacket, id) => {
                                            //console.log("variables: ",namePacket, pricePacket, id)
                                            this.setState({ name: namePacket, price: pricePacket, idPackage: id })
                                        }}
                                            packages={this.state.paquetes}
                                            operador={this.state.selectedPin.operador}
                                            type={this.state.selectedType} />
                                    }
                                </Col>

                                <Col xs={12} md={4}>
                                    <Form.Group >
                                        <h4 className={'defaultTitle'}>Celular</h4>
                                        <Form.Control
                                            className="text-center"
                                            placeholder='Celular'
                                            arial-label="Amount (to the nearest dollar)"
                                            value={this.state.phone}
                                            maxLength={10}
                                            onChange={(event) => this.setState({ phone: event.target.value.replace(/\D/, '') })}
                                        />
                                    </Form.Group>
                                    <Form.Group >
                                        <h4 className={'defaultTitle'}>Correo</h4>
                                        <Form.Control
                                            className="text-center"
                                            placeholder='Correo'
                                            arial-label="Amount (to the nearest dollar)"
                                            value={this.state.email}
                                            /* maxLength={10} */
                                            onChange={(event) => this.setState({ email: event.target.value/* .replace(/\D/, '') */ })}
                                        />
                                    </Form.Group>

                                    <Summary
                                        /* operator={this.state.operador} */
                                        phone={this.state.phone}
                                        email={this.state.email}
                                        price={this.state.price}
                                        pinPackage={this.state.name}
                                    />
                                </Col>
                            </Row>
                        </Card.Body>
                        <Card.Footer className="text-muted">
                            <Button size="lg" variant={'info'}
                                disabled={!this.state.name ||
                                    !this.state.phone ||
                                    !this.state.operador ||
                                    !this.state.price /* || 
                                        !this.state.email */}
                                onClick={this.sendData}
                            >Finalizar
                            </Button>
                        </Card.Footer>
                    </Card>
                    {/* </div> */}
                </div>

                {this.state.modalNotification && <ModalNotificationTransaction
                    closeModal={(flag) => this.closeModal(flag)}
                    type={"Pin"}
                    message={"Pin " + this.state.name + " comprado exitosamente"}
                />}
                {this.state.loading && <LoadingMovilGo />}
            </>
        )
    }
}

export default RecargasPines;
