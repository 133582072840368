import React, { useEffect, useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { ListMoves, ListErrors, ListSummaryBox } from '../../../shared/components/list';
import { getBox, getReport } from '../../../services/caja.service';

export const TabsBox = (props) => {
    const { loading, products, error } = props
    const [key, setKey] = useState('moves');
    const [pageMoves, setPageMoves] = useState(1);
    const [pageErrors, setPageErrors] = useState(1);
    const [moves, setMoves] = useState([]);
    const [summaryBox, setSummaryBox] = useState([]);
    const [listErrors, setListErrors] = useState([]);

    const initializeMoves = (data, flag) => {
        if (flag) {
            loading(false);
            setMoves(data.movimientos);
            /* console.log("tabsBosx, CAJA - data:", data) */
            if (data.movimientos.length === 0) {
                error("¡No se encontraron movimientos!")
                if (pageMoves === 1) {
                    setPageMoves(0)
                } else {
                    setPageMoves(pageMoves - 1)
                }
            }

        } else {
            loading(false);
            alert(data.message);
        }
    }

    const viewMoves = () => {

        if (pageMoves > 0) {
            loading(true);
            getBox(0, pageMoves,null ,initializeMoves);

        }

    }

    const initializeErrors = (data, flag) => {
        if (flag) {
            loading(false);
            setListErrors(data.movimientosError);
            if (data.movimientosError.length === 0) {
                error("¡No se encontraron errores registrados!")
                if (pageErrors === 1) {
                    setPageErrors(0)
                } else {
                    setPageErrors(pageErrors - 1)
                }
            }
        } else {
            loading(false);
            alert(data.message);
        }
    }

    const viewErrors = () => {
        if (pageErrors > 0) {
            loading(true);
            getBox(1, pageErrors,null ,initializeErrors);
        }
    }

    const initializeSummaryBox = (data, flag) => {
        if (flag) {
            loading(false);
            setSummaryBox(data.movimientos)
        } else {
            loading(false);
        }
    }

    const viewSummaryBox = () => {
        loading(true);
        getReport(null,initializeSummaryBox)
    }

    useEffect(() => {
        if (key === 'moves') {
            viewMoves();
        } else if (key === 'errors') {
            viewErrors();
        } else if (key === 'summaryBox') {
            viewSummaryBox();
        }

    }, [key, pageMoves, pageErrors])

    
    return (
        <>
            <div className='col-md-12'>
                <Tabs
                    className="tab"
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => { setKey(k) }}
                >
                    <Tab eventKey="moves" title="Movimientos">
                        {moves.length > 0 && 
                        <ListMoves 
                        contPage={pageMoves} 
                        rigthPage={() => setPageMoves(pageMoves + 1)} 
                        leftPage={() => { if (pageMoves > 1) { setPageMoves(pageMoves - 1) } }} 
                        products={products} 
                        moves={moves}
                        date={null}
                        loading={loading}/>}
                    </Tab>
                    <Tab eventKey="errors" title="Errores">
                        {listErrors.length > 0 && <ListErrors 
                        contPage={pageErrors} 
                        rigthPage={() => setPageErrors(pageErrors + 1)} 
                        leftPage={() => { if (pageErrors > 1) { setPageErrors(pageErrors - 1) } }} 
                        errors={listErrors} />}
                    </Tab>
                    <Tab eventKey="summaryBox" title="Resumen caja" >
                        {summaryBox.length > 0 && <ListSummaryBox 
                        summaryBox={summaryBox} />}
                    </Tab>
                </Tabs>
            </div>
        </>
    )
}