import React, { useState/*, useEffect*/ } from 'react';
//import PropTypes from 'prop-types';
import { Button, Card, Row, Container, FormControl } from 'react-bootstrap';
//import { FormatMoney } from '../../../utilities/formats';
import { Transaction } from '../../../services/product.service';
import { LoadingMovilGo } from '../../../shared/components/loadingMovilGo';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export const Desembolso = (props) => {

  //el de pago funciona // const { data, loading, productId, document, closeAction, clean, setLoading } = props;
  const [flagButton, setFlagButton] = useState(false)//esta asociado a la desactivación del botón de pago  
  const [loadinglocal, setLoadingl] = useState(false);

  const Desembolsar = () => {

    setLoadingl(true); //activa loading movilgo
    setFlagButton(true) //esta asociado a la desactivación del botón de pago

    try {
      const product = {
        //product_id: productId,        
        product_id: props.productId,
        atributes: {
          // valorPagar: data.solicitud_valoraprobado,
          //idsolicitud: data.id,
          //cedula: document,
          //cliente_nombre: data.partner_name,
          valorPagar: props.data.solicitud_valoraprobado,
          idsolicitud: props.data.id,
          cedula: props.document,
          cliente_nombre: props.data.partner_name,
          tipo: 'venta'
        }
      }
      // console.log("ENTRA rendija props: ", props)
      // console.log("------> DATOS PARA ENVIAR A TRANSACTION: ", product.atributes.cedula)

      //navigateNext()
      Transaction(product, navigateNext);
    } catch (error) {
      const errorr = () => toast.error("Error, Problemas al obtener datos de sesión");
      errorr();

      /* alert("Error", "Problemas al obtener datos de sesión"); */
    }
  };

  const navigateNext = (flag, response) => {
    // console.log("ENTRA flag: ", flag)
    // console.log("ENTRA response: ", response)


    //console.log("ENTRA props: ", props.data.id)
    if (flag) {
      //console.log("ENTRA 1")
      if (response.errores) {
        //console.log("ENTRA 2")
        if (response.errores.observacion) {
          // console.log("ENTRA 3")
          const error = () => toast.error('Error,' + response.errores.observacion);
          error();
          /* if(error){ this.clearData(); }  */
          /* alert('Error ',response.errores.observacion);  setLoading(false); */
        } else {
          //console.log("ENTRA 4")
          Error(response.errores.id)/* setLoading(false); */
        }
      } else {
        //console.log("ENTRA 5")
        props.setLoading(false);
        ////////////  
        const success = () => toast.success('Móvilgo, ¡Tu transacción fue exitosa!');
        success();
        /* if(error){ this.clearData(); }  */
        /* alert('Movilgo, ¡Tu transacción fue exitosa!'); */
        //console.log("ENTRA RENDIJAJAJA", props.closeAction())
        props.finish(true)
        props.closeAction();
        props.rst(response, props, null)
        props.clean();
      }

    } else {

      //console.log("ENTRA 6")
      /*props.rst(response, props) */

      //const error = () => toast.error('Error en la transacción');
      //props.finish(true)
      props.clean();
      props.closeAction();
      const error = () => toast.error(response.errores.observacion);
      error();
      //props.setLoading(false);
      /* alert('Error en la transacción'); */
    }
  };

  return (
    <>
      {loadinglocal && <LoadingMovilGo />}
      <Container >
        <ToastContainer />
        <p style={styles.titleSummary}>Datos del Crédito</p>
        <Row className="justify-content-md-center">
          <Card className='reloadsCard' style={{ minWidth: '40%' }}>
            <Card.Body>
              <div>
                <h3>Cliente</h3>
                {/* <p>{document}</p> */}
                <p>{props.document}</p>
              </div>
              <div>
                <h3>Solicitud</h3>
                {/* <p>{data.id}</p> */}
                <p>{props.data.id}</p>
              </div>
              <div>
                <h3>Valor aprobado</h3>
                {/* <p>{data.solicitud_valoraprobado}</p> */}
                <p>{props.data.solicitud_valoraprobado}</p>
              </div>
              <div>
                <h3>Fecha de solicitud</h3>
                {/* <p>{data.fecha_solicitud}</p> */}
                <p>{props.data.fecha_solicitud}</p>
              </div>
              <Button onClick={Desembolsar} variant={'info'} className="mt-2" disabled={flagButton}>
                Obtener
              </Button>
              <Button onClick={props.clean}/* {closeAction} */ className='mt-2'
                style={{ backgroundColor: 'transparent', borderColor: '#ccc', color: '#696969', marginLeft: '5%' }}
              >
                Cancelar
              </Button>
            </Card.Body>
          </Card>
        </Row>
      </Container>
    </>
  );
};


const styles = ({
  title: {
    fontWeight: 'bold',
  },
  container: {
    paddingHorizontal: 10,
    maxHeight: '100%',
    //backgroundColor: '#078FA5'
  },
  titleSummary: {
    alignSelf: 'center',
    fontWeight: 'bold',
    fontSize: 28,
    color: '#07A2BA',
  },
  colContainer: {
    width: '42%',
  },
  rowContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: 5,
  },
});
