//import AsyncStorage from '@react-native-community/async-storage';
//import {Alert, Linking} from 'react-native';
//import Odoo from 'react-native-odoo';
import Odoo from 'odoo-xmlrpc';
import { OdooConfig } from '../utilities/odoo-config';
import { Desencrypt } from '../utilities/cipher';


export const searchCertification = async (id, callback) => {

  const args = [[0, { product_id: id }]];

  const config = await OdooConfig();
  const data = await Desencrypt();

  //console.log("ARGS - searchCertification",args)

  const params = {
    model: 'movilgo.webservice',
    method: 'certificadoConsultaOficinas',
  };

  const client = new Odoo({
    url: config.host,
    port: config.port,
    db: config.database,
    username: data.user,
    password: data.password
  });

  client.connect((err, response) => {
    if (err) {
      return callback(response, false)
    } else {
      client.execute_kw(params.model, params.method, args, (err, response) => {
        if (err) {
          callback(err, false);
          return false;
        }
        callback(response, true);
      });
    }
  })
};

//PENDIENTE EDITARLO - no esta completado sobre la movil
export const searchSnr = async (id, code, license, callback) => {

  const args = [0, { product_id: id, snrOffice: code, snrLicense: license }];

  const config = await OdooConfig();
  const data = await Desencrypt();

  //console.log("searchSnr",args)
  const params = {
    model: 'movilgo.webservice',
    method: 'certificadoConsultaMatricula',
  };

  const client = new Odoo({
    url: config.host,
    port: config.port,
    db: config.database,
    username: data.user,
    password: data.password
  });

  client.connect((err, response) => {
    if (err) {
      return callback(response, false)
    } else {
      client.execute_kw(params.model, params.method, args, (err, response) => {
        if (err) {
          callback(err, false);
          return false;
        }
        callback(response, true);
      });
    }
  })
};
/* 
export const salesCertification = async (
  source_request,
  Numberplate,
  InsurancePolicyNumber,
  product_id,
  email,
  callback,
) => {
  const args = [
    0,
    {
      source_request,
      product_id,
      Numberplate,
      InsurancePolicyNumber,
      email,
      callback,
    },
  ];

  const session = await AsyncStorage.getItem('session');
  const client = new Odoo(JSON.parse(session));
  const params = {
    model: 'movilgo.webservice',
    method: 'soatDatosReimpresion',
    args: args,
    kwargs: {},
  };

  client.rpc_call('/web/dataset/call_kw', params, (err, response) => {
    if (err) {
      if (err.code === 100) {
        alert(
          'Error',
          'La sesión ha expirado, es necesario volver a loguearse.',
        );
        callback('close', false);
        return false;
      }
      console.log('El Error es: ', JSON.stringify(err));
      callback(err, false);
      return false;
    }

    callback(response, true);
  });
}; */

