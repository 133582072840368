import React from 'react';
//import { FormatMoney } from '../../../utilities/formats';

export const Summary = (props) => {
    const { departamento, municipio, codigo, snrLicense,snrOffice } = props

    return (
        <div> 
            <h3 className={'defaultTitle'}>Departamento:</h3>
            <h4>{departamento}</h4>
            <h3 className={'defaultTitle'}>Municipio:</h3>
            <h4>{municipio}</h4>
            <h3 className={'defaultTitle'}>Código:</h3>
            <h4>{codigo}</h4>
            <h3 className={'defaultTitle'}>snrLicense:</h3>
            <h4>{snrLicense}</h4>
            {/* <h3 className={'defaultTitle'}>snrOffice:</h3>
            <h4>{snrOffice}</h4> */}
        </div>
    );
}
