import React, { useEffect, useState } from 'react';
import { MDBDataTable } from 'mdbreact';
import { getDebts } from '../../services/recaudo.service';
import { Container, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEdit } from '@fortawesome/free-solid-svg-icons';
import { FormatMoney } from '../../utilities/formats';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ModalPay } from './components/modal.pay';
import { LoadingMovilGo } from '../../shared/components/loadingMovilGo';

export const Recaudo = () => {
    const [showPay, setShowPay] = useState(false);
    const [infoModal, setInfoModal] = useState({});
    const [debts, setDebts] = useState({});
    const [debtsC, setDebtsC] = useState([]);
    const [loading, setLoading] = useState(false);
    const [payAll, setPayAll] = useState(false);

   
    const updateItem = (id, value) => {
        let cloneDebts = debts;
        let deuda = 0;
        let vencido = 0;
        debtsC.forEach(element => {
            if (element.codigo === id) {
                deuda = element.deuda;
                vencido = element.vencido;
                return;
            }
        });
        let data = {
            columns: cloneDebts.columns, rows: []

        }
        for (let index = 0; index < cloneDebts.rows.length; index++) {
            if (cloneDebts.rows[index].codigo === id) {
                if (parseInt(deuda) === parseInt(value)) {
                    var myFish = cloneDebts.rows
                    data.rows=myFish;
                } else {
                    cloneDebts.rows[index].deuda = FormatMoney(parseInt(deuda) - parseInt(value));
                    cloneDebts.rows[index].vencido = FormatMoney(parseInt(vencido) - parseInt(value));
                    data.rows=cloneDebts.rows;
                    return;
                }

            }
        }
        setDebts(data);
    }

    useEffect(() => {
        setLoading(true);
        getDebts((data, flag) => {

            if (flag) {
                setLoading(false);
                let dat = {}
                let rows = []
                let rowsito = []
                data.deudores.forEach(element => {
                    rowsito.push({
                        codigo: element.cliente_id,
                        deuda: (element.deuda),
                        vencido: (element.vencido),
                    })
                    rows.push({
                        codigo: element.cliente_id,
                        nombre: (element.nombre),
                        deuda: FormatMoney(element.deuda),
                        vencido: FormatMoney(element.vencido),
                        acciones:
                            <div>
                                <Button variant="outline-success" size="sm" style={{ marginRight: 10 }} 
                                    onClick={() => { setPayAll(true); setInfoModal(element); setShowPay(true) }} >
                                    <FontAwesomeIcon style={{ width: 40 }} icon={faCheck} />
                                </Button>
                                <Button
                                    variant="outline-info"
                                    size="sm" style={{ marginLeft: 10 }}
                                    onClick={() => { setInfoModal(element); setShowPay(true); setPayAll(false) }} >
                                    <FontAwesomeIcon style={{ width: 40 }} icon={faEdit} />
                                </Button>
                            </div>
                    })
                });
                dat = {
                    columns: [
                        {
                            label: 'Código',
                            field: 'codigo',
                        },
                        {
                            label: 'Nombre',
                            field: 'nombre',
                            sort: 'asc'
                        },
                        {
                            label: 'Deuda',
                            field: 'deuda',
                            sort: 'asc'
                        },
                        {
                            label: 'Vencido',
                            field: 'vencido',
                            sort: 'asc'
                        },
                        {
                            label: 'Acciones',
                            field: 'acciones',
                        },

                    ],
                    rows: rows
                }
                setDebtsC(rowsito);
                setDebts(dat)

            } else {
                setLoading(false);
            }
        });
    }, [])

    return (
        <>
            {loading && <LoadingMovilGo />}
            <Container>
                <ToastContainer />
                <MDBDataTable
                    responsive
                    striped
                    bordered
                    small
                    entries={15}
                    entriesOptions={[15, 30, 50, 100]}
                    data={debts}
                    infoLabel={["Mostrando", "a", "de", "campos"]}
                    paginationLabel={["Anterior", "Siguiente"]}
                    entriesLabel="Mirar campos"
                    searchLabel="Buscar"
                />
            </Container>
            <ModalPay
                data={infoModal}
                show={showPay}
                close={() => setShowPay(false)}
                abono={(id, value) => updateItem(id, value)}
                pay={payAll}
            />
        </>
    );
}
