import React, { PureComponent } from 'react';
import { ListOperator } from '../../shared/components/list-operator';
import { Summary } from './components/summary';
import { ListPrices } from '../../shared/components/list-prices';
import { Container, Row, Col, Form, Card, Button, Image } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { Transaction } from '../../services/product.service';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ManagmentErrors } from '../../shared/components/managmentErrors';
import { LoadingMovilGo } from '../../shared/components/loadingMovilGo';
import { ModalNotificationTransaction } from '../../shared/components/modalNotificationTransaction';
import { FormatMoney } from '../../utilities/formats';
import { jsPdfGenerator } from '../../shared/components/pdfTransaction';

class Recargas extends PureComponent {
    constructor(props) {
        super(props);
        this.recargas = this.props.recargas;
        this.state = {
            visible: false,
            selectedOperator: { id: -1, image_medium: null, operador: '', },
            recargas: [],
            selectedPrice: '',
            phone: '',
            loading: true,
            opacity: 1,
            modalNotification: false
        }
        this._isMounted = false;
        this.listRecargas = [];
        this.response = [];
        this.phone = '';
        this.valor = '';
        this.operador = '';
        this.clean = ''
    }

    cleanFile = () => {
        this.setState({
            visible: false,
            selectedOperator: {
                image_medium: null,
                operador: '',
            },
            selectedPrice: '',
            phone: '',
            clean: false
        });
    }

    getResponse = (flag, response) => {
        //console.log("data de la recarga CAMILA",flag, response)
        if (flag) {
            this.response = response;
            const success = () => toast.success("Recarga comprada exitosamente");
            success();
            this.setState({ loading: false, opacity: 1, modalNotification: true });
        } else {
            /* const message = ManagmentErrors(response.errores.id); */
            const message = ManagmentErrors("MARIACAMILA");
            const error = () => toast.error(message);
            error();
            this.setState({ loading: false, opacity: 1 });
        }
    }

    componentDidMount = () => {
        //this._isMounted = true;
        //console.log("did",this.recargas)
        if (this.recargas) {
            this.setState({ recargas: this.recargas, loading: false })
        }
    }

    componentWillUnmount = () => {
        this._isMounted = false;
    }

    sendData = async () => {
        this.phone = this.state.phone;
        this.valor = this.state.selectedPrice;
        this.operador = this.state.selectedOperator.operador
        this.setState({ loading: true, opacity: 0.4 });
        const product = {
            product_id: this.state.selectedOperator.id,
            atributes: {
                numero: this.state.phone,
                precio: this.state.selectedPrice
            }
        }

        await Transaction(product, this.getResponse);
        this.cleanFile();
    }

    selectOperator = (operator) => {
        this.cleanFile();
        this.setState({ selectedOperator: operator });
    }

    selectPrice = (price) => {
        if (typeof (price) === 'string' && price.toLowerCase() === 'otro valor') {
            this.setState({ visible: true, selectedPrice: '' });
        }
        else {
            this.setState({ selectedPrice: price, visible: false });
        }
    }

    closeModal = (flag) => {
        this.setState({ modalNotification: flag });

        const data = [
            "Factura No: " + this.response.valida.id,
            "Fecha:" + this.response.valida.fecha,
            "No.Aprobacion:" + this.response.valida.numero_aprobacion,
            "Producto: Recarga " + this.operador,
            "Celular: " + this.phone,
            "Valor: " + FormatMoney(this.valor)
        ]
        jsPdfGenerator(data);
    }

    render() {
        return (
            <>
                <style>
                    {`
                .my-auto {
                    margin-top: auto;
                    margin-bottom: auto;
                } 
                .reloadsCard {
                    box-shadow: 0px 2px 4px 1px rgba(0,0,0,0.17);
                    border-radius: 1em;
                    margin: 5px;
                }
                .contenedor{
                    padding: 0px 10vw 0px 5vw;
                    
                }
                ` }
                </style>

                <div className="contenedor" style={{ opacity: this.state.opacity }} >

                    <ToastContainer />
                    <h2 className={'defaultTitle'}>Recargas</h2>
                    <Row className="justify-content-md-center">

                        <Col>
                            <ListOperator onClick={(operator) =>
                                this.selectOperator(operator)}
                                operators={this.state.recargas} />
                        </Col>
                    </Row>
                    <Card className='reloadsCard'>
                        <Card.Body>
                            <Container className="" fluid='md'>
                                <div>
                                    {this.state.selectedOperator.image_medium && <Image src={'data:image/jpeg;base64,' + this.state.selectedOperator.image_medium} style={{ maxHeight: '80px' }} />}
                                </div>
                                <Row >
                                    <Col md={12} xs={12} lg={6} className="my-auto">
                                        <Form.Group >
                                            <h4 className={'defaultTitle'}>Número de Celular</h4>
                                            <Form.Control
                                                placeholder='Celular'
                                                arial-label="Amount (to the nearest dollar)"
                                                value={this.state.phone}
                                                maxLength={this.state.selectedOperator.operador === "directv" ? 12 : 10}
                                                onChange={(event) => this.setState({ phone: event.target.value.replace(/\D/, '') })}
                                            />
                                        </Form.Group>
                                        <div className="justify-content-md-center col-xs-12 mb-2"  >
                                            <ListPrices clean={(flag) => this.setState({ clean: flag })} limpiar={this.state.clean} onClick={(i) => this.selectPrice(i)} prices={Prices} /><br />
                                            {this.state.visible &&
                                                <NumberFormat className="form-control mt-1"
                                                    thousandSeparator={true}
                                                    prefix='$ '
                                                    placeholder='$ Montos de Mil'
                                                    value={this.state.selectedPrice}
                                                    maxLength={9}
                                                    onChange={(event) => this.setState({ selectedPrice: parseInt(event.target.value.replace("$", "").replace(",", "")) })}
                                                />
                                            }
                                        </div>
                                    </Col>
                                    <Col md={12} xs={12} lg={6}>
                                        <h4 className={'defaultTitle'}>Resumen de Compra</h4>
                                        <Card>
                                            <Summary
                                                operator={this.state.selectedOperator.operador}
                                                price={this.state.selectedPrice}
                                                phone={this.state.phone}
                                            />
                                        </Card>
                                    </Col>
                                </Row>
                            </Container>
                        </Card.Body>
                        <Card.Footer className="text-muted">
                            <Row>
                                <Col>
                                    <Button size="lg" variant={'info'} onClick={this.cleanFile}>Cancelar</Button>
                                </Col>

                                <Col>
                                    <Button disabled={!this.state.phone || !this.state.selectedOperator.operador || !this.state.selectedPrice}
                                        size="lg" variant={'info'}
                                        onClick={this.sendData}>Finalizar</Button>
                                </Col>
                            </Row>
                        </Card.Footer>
                    </Card>

                </div>

                {this.state.modalNotification && <ModalNotificationTransaction
                    closeModal={(flag) => this.closeModal(flag)}
                    type={"Recarga"}
                    message={"Recarga realizada exitosamente"} />}
                {this.state.loading && <LoadingMovilGo />}
            </>
        )
    }
}

const Prices = [
    1000,
    2000,
    3000,
    5000,
    10000,
    20000,
    'Otro valor'
]

export default Recargas;