import Odoo from 'odoo-xmlrpc';
import { OdooConfig } from '../utilities/odoo-config';
import { Desencrypt } from '../utilities/cipher';

export const paySeller = async (data, call) => {

    //console.log("DATA SERVICE PAYSELLER: ", data)
    const config = await OdooConfig();
    const user = Desencrypt();
    //console.log("user",user)             
    let finish = false;
    const args = [[0, data]];

    const params = {
        model: "movilgo.webservice",
        /* method: "transferenciaCaja", */
        method: "subirPagoFactura",
    }

    setTimeout(() => {
        if (finish) {
            return;
        }
        alert("Tiempo limite excedidoo");
        call(false, "");
        return;
    }, 100000);

    const odoo = new Odoo({
        url: config.host,
        port: config.port,
        db: config.database,
        username: user.user,
        password: user.password
    });
    //console.log("odoo",odoo)

    odoo.connect((errLogin, responseLogin) => {
        //console.log("----->CONEXIÓN ODOO PAY")
        //console.log("----errLogin", errLogin)
        //console.log("----responseLogin ", responseLogin)

        if (errLogin) {
            return;
        }

        odoo.execute_kw(params.model, params.method, args, (err, response) => {
            //console.log("----->RESPUESTAS ODOO")
            //console.log("----res", err)
            //console.log("----response", response)
            finish = true;
            if (err) {
                /* call(err,false); */
                call(false, err);
                return false;
            }
            if (response) {
                //console.log("----response.errores", response.errores)
                if (response.errores && response.errores.id) {
                    call(false, response);
                    return false;
                }
                call(true, response);
                return true;
            }
            return false;
        });
    })

    /* export const searchDebts = async (callback) => {
    const args = [
        0
    ]
    const session = await AsyncStorage.getItem("session");
    const client = new Odoo(JSON.parse(session));
    const params = {
        model: "movilgo.webservice",
        method: "listarDeudaRuta",
        args: args,
        kwargs: {}
    }
    client.rpc_call('/web/dataset/call_kw', params, (err, response) => {
        if (err) {
            callback(err,false);
            
            return false;
        }
        callback(response,true);
    });
 }
 */
    /* export const payDebt = async (amount, partner_id, call) => {
    const session = await AsyncStorage.getItem("session");
    const client = new Odoo(JSON.parse(session));
    const args = [
        0, { amount, partner_id }
    ]
    const params = {
        model: "movilgo.webservice",
        method: "pagarDeuda",
        args: args,
        kwargs: {}
    }
    client.rpc_call('/web/dataset/call_kw', params, (err, response) => {
        if (err) {
            
            call(false, response);
            return false;
        }
 
        if (response) {
            if (response.errores && response.errores.id) {
                const err = {
                    table: "Errors",
                    product: {
                        id: response.errores.id
                    }
                }
                getElementByIdDataBase(err).then(results => {
                    if (results) {
                        Alert.alert(Error, results.comment);
                    } else {
                        Alert.alert(Error, "Movilgo no ha parametrizado el error, Comunicate con nosotros");
                    }
 
                }).catch(error => console.log(error));
                call(false, response);
                return false;
            }
            ToastAndroid.showWithGravity(
                "Transacción realizada.",
                ToastAndroid.SHORT,
                ToastAndroid.CENTER
            );
            call(true, response,amount);
            return true;
        }
        return false;
    });
 } */
    /* client.rpc_call('/web/dataset/call_kw', params, (err, response) => {
        if (err) {
            
            call(false, err);
            return false;
        }
        
        if (response) {
            if (response.errores && response.errores.id) {
                const err = {
                    table: "Errors",
                    product:{
                        id: response.errores.id
                    }
                }
                getElementByIdDataBase(err).then( results =>{
                    if(results){             
                        Alert.alert(Error, results.comment);
                    }else{
                        Alert.alert(Error, "Movilgo no ha parametrizado el error, Comunicate con nosotros");
                    }
                    
                }).catch(error => console.log(error));
                call(false, response);
                return false;
            }
            
            // ToastAndroid.showWithGravity(
            //     "Transacción realizada.",
            //     ToastAndroid.SHORT,
            //     ToastAndroid.CENTER
            // );
            call(true, response);
            return true;
        }
        return false;
    }); */
}

export const payCollector = async (data, call) => {
    console.log("DATA SERVICE payCollector: ", data)

    const config = await OdooConfig();
    const user = Desencrypt();
    let finish = false;
    const args = [[0, data]];

    const params = {
        model: "movilgo.webservice",
        method: "transferenciaCaja",
        /* method: "subirPagoFactura", */
    }

    setTimeout(() => {
        if (finish) {
            return;
        }
        alert("Tiempo limite excedidoo");
        call(false, "");
        return;
    }, 100000);

    const odoo = new Odoo({
        url: config.host,
        port: config.port,
        db: config.database,
        username: user.user,
        password: user.password
    });

    odoo.connect((errLogin, responseLogin) => {

        if (errLogin) {
            return;
        }
        odoo.execute_kw(params.model, params.method, args, (err, response) => {

            finish = true;
            if (err) {
                /* call(err,false); */
                call(false, err);
                return false;
            }
            if (response) {
                if (response.errores && response.errores.id) {
                    call(false, response);
                    return false;
                }
                call(true, response);
                return true;
            }
            return false;
        });
    })
}