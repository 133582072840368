import React from 'react'

export const Political = () => {
    return (
        <div style={{width:'100%',height:'100%', alignItems:'center', justifyContent:'center'}}>
            <div style={{ position: 'absolute', width: '100%', height: '80%' }}>
                <object
                    data={require('../../../assets/POLITICA DE PRIVACIDAD_Movil Go.pdf')}
                    type="application/pdf"
                    width="100%"
                    height="100%"
                >
                </object>
            </div>
        </div>


    )
}