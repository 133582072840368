import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { ItemMoves } from '../../screens/caja/components/item';
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { getBox } from '../../services/caja.service';

export const Excel = (props) => {
    const { search, fecha } = props
    const [moves, setMoves] = useState([]);
    //console.log("fecha  del excel",fecha)
    const searchMoves = () => {
        getBox(0, 0, fecha, (data, flag) => {
            // console.log("data del excel ",data)
            if (flag) {
                setMoves(data.movimientos)
            }
        });

    }
    useEffect(() => {
        searchMoves();
    }, [search])

    return (
        <>
            <div style={{ marginTop: 15 }}>

                <ReactHTMLTableToExcel
                    id="ExcelExport"
                    className="btn btn-info float-left m-3"
                    table="elements"
                    filename="MovilGo Movimientos"
                    sheet="Pagina 1"
                    buttonText="Excel Export"
                    on
                />

                {
                    <Table striped bordered hover variant="info" id="elements">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Descripción</th>
                                <th>Valor</th>
                                <th>Tipo</th>
                                <th>Aprobación</th>
                                <th>Celular</th>
                                <th>Comisión</th>
                                <th>Fecha</th>
                                <th>Hora</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {moves.map((obj, i) => (
                                <ItemMoves key={i} itemMoves={obj} />
                            ))}

                        </tbody>
                    </Table>
                }
            </div>
        </>
    )

}