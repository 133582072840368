import React, { useEffect, useState } from 'react'
import { Modal, Card,Button } from 'react-bootstrap';
import quienesSomos from '../../assets/quienes-somos.jpg';
import nuestraApp from '../../assets/nuestra-app.jpg';
import GANADOR from '../../assets/PROMOCIONAL.jpg';
import CarruselHome from './components/carruselhome';
import rifa from '../../assets/home/RIFA.jpg';
import home from '../../assets/home/home.css';
const HomeComponent = () => {
    const [show,setShow]=useState(false)
    useEffect(()=>{
        setShow(true)

    },[])
    return (
            <>
                <div  >
                    <h1 style={{ textAlign: 'center' }}>Bienvenido a <b>MóvilGO</b></h1>
                        <div className='container'>
                                <CarruselHome/>
                        </div>
                    <div className="row" style={{backgroundColor: '#0D606E',marginTop:'10%'}}>
                        <div className="container" style={{marginLeft:'2%'}}>
                        <img style={styles.img2}  className="img-responsive" src={GANADOR} alt='' />  
                        </div>
                        
                    </div>
                    <div className="row" style={{ marginTop: 40,padding: 20 }}>
                        <div className="col-md my-auto" style={{ padding: 10 }}>
                            <h1 style={{ textAlign: 'center' }}>¿Quienes Somos?</h1>
                            <p style={{ textAlign: 'center', fontSize: 18 }}>
                                Somos una empresa que ofrece soluciones tecnológicas 
                                de alto nivel, mejora e implementa sistemas de
                                información, dando soluciones a problemas de la vida cotidiana,
                                con soporte técnico especializado al público objetivo.
                            </p>
                        </div>
                        <div className="col-md" >
                            <div>
                                <img style={styles.img} src={quienesSomos} alt='' />
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{ marginTop: 40,   backgroundColor: '#0D606E', }}>
                        <div className="col-md ">
                            <img style={styles.img2} className="img-responsive" src={nuestraApp} alt='' />
                        </div>
                        <div className="col-md my-auto" style={{ padding: 10 }}>
                            <h1 style={{ textAlign: 'center',color:'white' }}>Nuestra App</h1>
                            <p style={{ fontSize: 18,color:'white' }}>
                                Contamos con una aplicación móvil para facilitarle el trabajo a
                                la hora de realizar ventas, rifas, consultas y mucho más...
                            </p>
                        </div>
                    </div>
                    
                </div>
               {/*  <Modal show={show} onHide={()=>setShow(false)}  style={{width:'100%'}}>
                    <Modal.Body style={{width:1240, marginLeft:-420,height:700}}>
                        <Button onClick={()=>setShow(false)} style={{height: '100%',marginLeft:'1%',marginTop:'-1%', width: 1210,backgroundColor:'transparent',borderWidth:'transparent'}}>
                            <Card.Img src={rifa}  style={{ height: 850, width: 1320,marginLeft:'-2%',marginTop:'-1%'}} alt="Card image" />
                        </Button>
                            
                    </Modal.Body>
                </Modal> */}
            </>
        
    )
}

const styles = {
 img:{padding: 5, width: '80%', borderStyle: 'solid', borderWidth: '5px', borderColor: '#F8F9FA',  borderRadius: '3%',
 },
 img2:{padding: 5,  borderStyle: 'solid', borderWidth: '5px', borderColor: '#07A2BA', borderRadius: '3%', 'width': "70%"
 },
}

export default HomeComponent;