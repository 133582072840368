import React from 'react';
import { FormatMoney,Capitalize } from '../../../utilities/formats';

export const Summary = (props) => {
    const { phone, operator, price } = props

    return (
        <div> 
            <h4 className={'defaultTitle'}>Operador:</h4>
            <h4>{Capitalize(operator)}</h4>
            <h4 className={'defaultTitle'}>Celular:</h4>
            <h4>{phone}</h4>
            <h4 className={'defaultTitle'}>Valor de recarga:</h4>
            <h4>{FormatMoney(price)}</h4>
        </div>
    );
}
