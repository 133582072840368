import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { Transaction } from '../../../services/product.service';
import { ListPrices } from './list';
import { ToastContainer, toast } from 'react-toastify';
import { ManagmentErrors } from '../../../shared/components/managmentErrors';
import { FormatMoney } from '../../../utilities/formats';
import { LoadingMovilGo } from '../../../shared/components/loadingMovilGo';
import { ModalNotificationTransaction } from '../../../shared/components/modalNotificationTransaction';
import { jsPdfGenerator } from '../../../shared/components/pdfTransaction';

export const ModalAbonar = (props) => {
    const { visible, closeModal, dataTicket,navigate } = props
    const [active, setActive] = useState(true);
    const [visibleN, setVisibleN] = useState(false);
    const [loading, setLoading] = useState(false);
    const precio = [dataTicket.deuda, dataTicket.deuda / 2, "Otro valor"];
    const [modalNotification, setModalNotification] = useState(false);
    const [price, setPrice] = useState(0);

    const callbackPayment = (flag, response) => {

        //console.log("esto es el rifa response  abono abono",response)
        if (flag) {
            const rifa = JSON.parse(localStorage.getItem('datosRifa'));
            //console.log("esto es el rifa de abono abono",rifa)
            const success = () => toast.success("Abono realizado exitosamente");
            success();
            setModalNotification(true)
            closeModal();
            setLoading(false);
            setVisibleN(false);
            setPrice('');

            let data = [
                "Factura No: " + response.valida.id,
                "Fecha:" + response.valida.fecha,
                "No.Aprobacion:" + response.valida.numero_aprobacion,
                "Producto: Abono " + rifa.name,
                "Resolucion: " + rifa.resolucion,
                "Fecha del sorteo: " + rifa.sorteo,
                "Número de boleta: " + dataTicket.boleta,
                "Cliente: " + dataTicket.nombre,
                "Valor total: " + FormatMoney(rifa.precio),
                "Valor abonado: " + FormatMoney(parseInt(price)),
                "Valor por pagar: " + FormatMoney(rifa.precio - price),
            ]

            jsPdfGenerator(data);
            navigate('/Servicios')
            
        } else {
            const message = ManagmentErrors(response.errores.id);
            const error = () => toast.error(message);
            error();
            closeModal();
            setLoading(false);
            setVisibleN(false);
            setPrice('');
        }
    }
    const paytmentTicket = () => {
        if (price <= dataTicket.deuda) {
            setLoading(true);
            const idP = JSON.parse(localStorage.getItem('datosRifa'));
            const product = {
                product_id: idP.id,
                atributes: {
                    numeroBoleta: dataTicket.boleta,
                    valorPagar: parseInt(price),
                    partner_id: dataTicket.partner_id,
                    vendedor_externo_id: dataTicket.vendedor_externo_id,
                    nombre_cliente: dataTicket.nombre
                }
            }
            Transaction(product, callbackPayment)
        } else {
            const warn = () => toast.warning("¡Atención, no puede abonar " + FormatMoney(price) + " porque su deuda es de " + FormatMoney(dataTicket.deuda));
            warn();
            setActive(true);
            setVisibleN(false);
            setPrice('')
        }
    }
    const otherValue = (precio) => {
        if (precio === "Otro valor") {
            setVisibleN(true);
            setActive(false);
        } else
            setVisibleN(false);
        setActive(false);
    }

    return (

        <>
            <ToastContainer />

            <Modal
                centered
                show={visible}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header>
                    <Modal.Title className={'defaultTitle'}>Abonar a la rifa</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <div>
                            <div style={{ textAlign: 'center' }}>
                                {loading && < LoadingMovilGo />}
                            </div>
                            <ListPrices onClick={(precio) => { setPrice(precio); otherValue(precio) }} prices={precio} />
                            {visibleN && <NumberFormat className="form-control mt-1"
                                thousandSeparator={true}
                                prefix='$ '
                                placeholder='$ Montos de Mil'
                                value={price}
                                maxLength={9}
                                onChange={(event) => setPrice(parseInt(event.target.value.replace("$", "").replace(",", "")))}
                            />}
                        </div>
                        <Form.Label style={{ textAlign: 'center' }}>Abonar: {FormatMoney(price)}</Form.Label>
                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { closeModal(); setVisibleN(false); setPrice(0) }}>
                        Cancelar
                    </Button>
                    <Button disabled={active} variant="primary" onClick={() => paytmentTicket()} >Abonar</Button>
                </Modal.Footer>
            </Modal>
            {modalNotification && <ModalNotificationTransaction closeModal={(flag) => setModalNotification(false)} type={"Abono"} message={"Abono realizado exitosamente"} />}
        </>


    )
}