import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Transaction } from '../../../services/product.service';
//import { PrestamosBuscar } from '../busca';
import { Button, Card, Row, Container, FormControl, Modal, InputGroup } from 'react-bootstrap';
import { FormatMoney } from '../../../utilities/formats';
import MenuServices from '../../menu-services/menu-service';
import { LoadingMovilGo } from '../../../shared/components/loadingMovilGo';
import { ModalNotificationTransaction } from '../../../shared/components/modalNotificationTransaction';
import { jsPdfGenerator } from '../../../shared/components/pdfTransaction';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//import { dataPrestamo } from '../busca';
//import { ModalNotificationTransaction } from '../../shared/components/modalNotificationTransaction';
//import { cleanup } from '@testing-library/react';
//import {FormatMoney} from '../../../shared/utilities/formats';

export const Pago = (props) => {
  //const {/* data, */loading ,/* productId,document,closeAction, */clean,setLoading } = props;  
  const [pago, setPago] = useState("");
  const [valorPagoError, setValorPagoError] = useState('');
  const [flagButton, setFlagButton] = useState(false)//esta asociado a la desactivación del botón de pago  
  const [flagError, setFlagError] = useState(false)
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState(false);
  /*   const clearData = () => {
    }; */

  const deuda = () => {
    if (parseInt(props.data.valor_deuda) === 0) {
      const info = () => toast.info('MóvilGo, Estos  datos no tienen deuda pendiente');
      info()
      if (info) {
        props.clean()
      }
      /*   alert('MovilGo, Estos  datos no tienen deuda pendiente',
        [{text: 'Ok', onPress: () => {props.closeAction()}}]) */
    }
  }

  const verifyFields = (flag) => {
    setLoading(true);
    setFlagButton(true) //esta asociado a la desactivación del botón de pago

    try {
      const product = {
        product_id: props.productId,
        atributes: {
          valorPagar: pago,
          idsolicitud: props.data.account_move_id,
          cedula: props.document,
          cliente_nombre: props.data.partner_name,
          tipo: 'pago'
        }
      }
      //console.log("Variable product PAGO",product)              
      Transaction(product, navigateNext);

    } catch (error) {
      const errorr = () => toast.error("Error, Problemas al obtener datos de sesión");
      errorr();
      //alert("Error, Problemas al obtener datos de sesión");
      //this.setState({ "loading": false });
    }
    /*  } */
  };

  /*  const Error = (id) => {
     console.log("esto ingreso al error") 
     getTableFromDB(ERROS_SCHEMA, true)
       .then((response) => {
 
         response.forEach(element => {
            console.log("response de los errores",element) 
           if(element.id===id){
             Alert.alert('Error ',element.comment);
           }          
         });
         //this.setState({soats: response});        
       })
       .catch((err) => console.error(err));
   }; */



  const navigateNext = (flag, response) => {
    // console.log("respuesta del PAGO ", response) //console.log("flag del pago ",flag) 
    // console.log("----->PAGO", pago)
    if (flag) {
      if (response.errores) {
        if (response.errores.observacion) {
          const error = () => toast.error('Error,' + response.errores.observacion);
          error();   /* alert('Error ',response.errores.observacion);  //setLoading(false);*/
        } else { // setLoading(false);
          Error(response.errores.id)
        }
      } else {   //setLoading(false);                
        const success = () => toast.success('Móvilgo, ¡Tu transacción fue exitosa!');
        success();  /* alert('Movilgo, ¡Tu transacción fue exitosa!');*/
        props.finish(true)
        props.closeAction();
        props.rst(response, props, pago)
        //setNotification(true);
      }
    } else {
      if (response) {
        if (response.errores) {
          const error = () => toast.error('Error,' + response.errores.observacion);
          error(); //alert('Error', response.errores.observacion/* , [ {text: 'Volver', onPress: () => clearData()},] */ //);
        } else {
          const error = () => toast.error('Error en la transacción');
          error();  //alert('Error', 'Error en la transaccion '/* , [ {text: 'Volver', onPress: () => clearData()},] */ //);
        }
      }
    }
  };

  useEffect(() => {
    // console.log("---> CONTENIDO PROPS", props)
    // const values = props
    // dataPrestamo(values)
    deuda();
  }, []);

  return (
    <>
      {loading && <LoadingMovilGo />}
      <Container >
        <ToastContainer />
        <p style={styles.titleSummary}>Datos del pago</p>
        <Row className="justify-content-md-center">
          <Card className='reloadsCard' style={{ minWidth: '40%' }}>
            <Card.Body>
              {/* Modal con la información del pago a realizar */}
              <div style={styles.marco} /* {margin: 5, borderWidth: 1, padding: 5, borderColor: '#078fa4', borderRadius: 10}} */>
                <div>
                  <div>
                    <h3>Cliente</h3>
                    <p>{props.data.partner_name} </p>
                    {/* <p>{data.partner_name} </p> */}
                    <h3>Solicitud</h3>
                    <p>{props.data.account_move_id}</p>
                    {/* <p>{data.account_move_id}</p> */}
                    <h3>Documento</h3>
                    <p>{props.document} </p>
                    <h3>Saldo pendiente</h3>
                    <p>{FormatMoney(props.data.valor_deuda)}</p>
                    <h3>Saldo a cancelar</h3>
                    <FormControl value={pago} placeholder="Valor"
                      onChange={e => { setPago(e.target.value) }}
                    />
                  </div>
                  {/* {<div style={styles.rowContainer}>
                    <div style={styles.colContainer}> </div> <div style={styles.colContainer}> </div>                
                  </div>} */}
                  <div> {flagError && (<p style={{ color: 'red' }}>{valorPagoError}</p>)} </div>
                </div>
                <div>
                  <Button onClick={verifyFields} variant={'info'} className="mt-2"
                    style={{ marginLeft: '15%' }} disabled={flagButton}>
                    Pagar
                  </Button>
                  <Button onClick={props.clean} className='mt-2'
                    /* onClick={()=>{{props.closeAction; props.clean}}} 
                    onClick={props.closeAction}  */
                    style={{ backgroundColor: 'transparent', borderColor: '#ccc', color: '#696969', marginLeft: '5%' }}
                  >
                    Cancelar
                  </Button>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Row>
      </Container>
    </>
  );
};

Pago.propTypes = {
  vehicle: PropTypes.object,
  closeAction: PropTypes.func,
};
Pago.defaultProps = {
  vehicle: {},
  closeAction: () => { },
};

const styles =/*  StyleSheet.create */({

  marco: {
    margin: 5,
    borderWidth: 1,
    padding: 5,
    borderColor: '#078fa4',
    borderRadius: 10
  },
  title: {
    fontWeight: 'bold',
  },
  container: {
    paddingHorizontal: 10,
    maxHeight: '100%',
    //backgroundColor: '#078FA5'
  },
  titleSummary: {
    alignSelf: 'center',
    fontWeight: 'bold',
    fontSize: 28,
    color: '#07A2BA',
  },
  colContainer: {
    width: '32%',
  },
  rowContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: 5,
  },
});
