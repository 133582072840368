import React, { PureComponent } from 'react';
import { Row, Image } from 'react-bootstrap';
import ReactShadowScroll from 'react-shadow-scroll';
import { SearchBoletas } from './search-boleta';
import { ListBallotsUser } from './list';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ModalAbonar } from './modal-abonar';

class Abonos extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            cedula: '',
            selectedPrice: '',
            boleta: '',
            visibleBoletas: false,
            listaAbonos: [],
            cont: 0,
            prices: [],
            visiblePayTicket: false,
            selectedPayment: {}
        }
        this.listaAbonos = {};
    }

    componentDidMount = () => {
        
        this.price();
    }

    selectPrice = (price) => {
        if (typeof (price) === 'string' && price.toLowerCase() === 'otro valor') {
            this.setState({ visible: true, selectedPrice: '' });
        }
        else {
            this.setState({ selectedPrice: price, visible: false });
        }
    }


    setListAbonos = (list) => {
        const noEncontre = () => toast.error("¡No se encontraron resultados !");
        this.listaAbonos = list;

        if (this.listaAbonos.abonos.length === 0) {
            noEncontre();
        }
        
        this.setState({ listaAbonos: list, cont: this.listaAbonos.abonos.length });
    }

    price = () => {
        const rifa = JSON.parse(localStorage.getItem('datosRifa'));
        
        const prices = [
            rifa.precio,
            rifa.precio / 2,
            "Otro valor"
        ];
        this.setState({ prices })
    }
    render() {
        const rifa = JSON.parse(localStorage.getItem('datosRifa'));
        return (
            <>
                <style>
                    {` .box{
                            width: 120%;
                            heigth: 25%;
                            overflow: hidden;
                        } `
                    }
                </style>
                <ToastContainer autoClose={2000} />
                <h2 style={{marginTop:'5%'}} className={'defaultTitle'}>Abonos</h2>
                <div style={{ padding: '10px 10% 10px 10%', }}>
                    <Row className="justify-content-md-center" >
                        <div className='box' style={{ maxHeight: '200px', maxWidth: '80rem' }}>
                        </div>
                        <Row style={{ maxWidth: '100%' }} className="justify-content-md-center">
                            <h4 className={'defaultTitle'}>{rifa.name}</h4>
                        </Row>
                    </Row>
                    <Row className="justify-content-md-center" >
                        <SearchBoletas deleteListAbonos={() => { this.setState({ listaAbonos: {}, cont: 0 }) }} 
                            listaAbonos={(list) => this.setListAbonos(list)} />
                    </Row>
                    <Row className="justify-content-md-center">
                        <div className="col-xs-12 mt-2">
                            {this.state.cont > 0 && <ReactShadowScroll isShadow={false} scrollWidth={10} >
                                <div style={{ height: '400px', width: '42rem' }}>
                                    <ListBallotsUser listaAbonos={this.state.listaAbonos.abonos} 
                                        setPayment={(data) => this.setState({ selectedPayment: data })} 
                                        setVisible={() => this.setState({ visiblePayTicket: true })} />
                                </div>
                            </ReactShadowScroll>}
                        </div>
                    </Row>
                    <ModalAbonar dataTicket={this.state.selectedPayment} 
                        visible={this.state.visiblePayTicket} 
                        closeModal={() => this.setState({ visiblePayTicket: false, cont: 0 })} 
                        navigate={(route)=>this.props.history.push(route)}/>
                        
                </div>
            </>
        )
    }

}

export default Abonos;