import React, { PureComponent } from 'react';
import { DetailBox } from './components/detailBox';
import { getBox } from '../../services/caja.service';
import { Row } from 'react-bootstrap';
import { TabsBox } from './components/tabsBox';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LoadingMovilGo } from '../../shared/components/loadingMovilGo';
//import DateTimePicker from 'react-datetime-picker'; 

class CajaFecha extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            caja: { "balanceFinal": 0, "balanceInicial": 0, "credito": 0, "debito": 0, "ganancia": 0, "estado": "No disponible", "fecha": "--", "nombre_caja": "--" },
            viewMoves: false,
            movimientos: [],
            key: '',
            cargar: false,
            alamanaque: false,
            date: '',
            initmove: false,
            move: [],
            err: [],
            resum: []
        }
        this.products = this.props.products
        this.datesBox = {};
        /* console.log("Variable productos inicializada al abrir el vinculo", this.state) */
        //la variable this.state, me muestra inicialmente los valores quemados en la pantalla 
        //son los valores de esta variable los que debo setiar
    }

    initializeData = (data, flag) => {//INFORMACIÓN QUE OBTIENE DEL CALLBACK

        //console.log("initializeData",flag,data)
        const error = () => toast.error("¡Falló la conexión con el servidor!");

        if (flag) {
            if (data.errores) {
                alert('Error', 'Se presento un error con los datos de esta caja');
            } else {

                this.setState({ caja: data.caja, cargar: false, initmove: true });
                //console.log("data del callback del servidor",data, "y flag", flag)
            }

            //caja ya tiene info de caja del servidor
            //carga, cambia el estado a false
            //initmove, cambia a true
        }
        else {
            const date = JSON.stringify(data.message);
            const otroDate = JSON.stringify(date.slice(1, 14))
            const comprueba = JSON.stringify("XML-RPC fault");

            if (otroDate === comprueba) {
                error();
            }

            if (!flag) {

                alert('Error', data.message);
                this.setState({ loading: false });
                return;
            } else {
                if (data.errores) {
                    alert('Error', "No hay dato de esa fecha ");
                    this.setState({
                        caja: {
                            nombre_caja: '---',
                            estado: '---',
                            fecha: '---',
                            balanceInicial: '0',
                            balanceFinal: '0',
                            debito: '0',
                            ganancia: '0',
                            credito: '0'
                        },
                        loading: false,

                    });
                } else {
                    this.setState({ caja: data.caja, loading: false, });
                }
            }
        }
    }
    /* 
        initializeMoves = (data, flag) => {
            if (flag) {
                this.setState({ movimientos: data.movimientos, viewMoves: false });
                
            } else {
                alert(data.message);
                
            }
           
        } */
    componentDidMount = () => {
        this.setState({ alamanaque: true })
    }
    senttoodo = () => {
        this.setState({ initmove: false, cargar: true })
        // console.log("envio",this.state.date)
        getBox(0, 0, this.state.date, this.initializeData)
    }


    render() {
        /* const[selectDate, SetSelectDate]=useState(new Date()); */
        return (
            <div>

                {this.state.cargar && <LoadingMovilGo />}
                <ToastContainer />
                <h6 className='defaultTitle' style={{ fontSize: 45 }}>Caja por Fecha</h6>
                {this.state.alamanaque &&
                    <div>
                        <h4 className='titleCol'>Fecha: </h4>
                        <div className='container' >
                            <input
                                type="date"
                                value={this.state.date}
                                onChange={e => {
                                    this.setState({ date: e.target.value },
                                        this.senttoodo);
                                }}
                            />
                            {/*  <DateTimePicker 
                            autoFocus ={true}
                            value= {this.state.date} 
                            onChange={e => { 
                                this.setState( {date:e.target.value},this.senttoodo ); }
                                } 
                            />*/}
                            {/* <DateTimePicker value= {selectDate} onChange={SetSelectDate}/> */}
                        </div>
                    </div>
                }
                <DetailBox detailBox={this.state.caja} />

                <div className='container' style={{ marginTop: 25 }}>
                    {this.state.initmove &&
                        <Row className="justify-content-md-center" >
                            <TabsBox
                                error={(errorMessage) => {
                                    const error = () => toast.error(errorMessage);
                                    error();
                                }}
                                products={this.products}
                                loading={(flag) => this.setState({ cargar: flag })}
                                fecha={this.state.date}
                                pageMoves={1} />
                        </Row>}
                </div>
            </div>


        )
    }
}
export default CajaFecha;