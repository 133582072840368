import Odoo from 'odoo-xmlrpc';
import { OdooConfig, idCardoso } from '../utilities/odoo-config';

let listProducts = {};
export const Login = async (data, call) => {
    const config = await OdooConfig();
    const odoo = new Odoo({
        url: config.host,
        port: config.port,
        db: config.database,
        username: data.user,
        password: data.password
    });
//console.log("config",config)
console.log("odoo",odoo)
    odoo.connect((err, response) => {
        if (err) {
            call(1, false)
        } else {
            setData(odoo, call, data);
            data['id'] = response
            console.log("odoo connect",response)
        }
    })
}

export const setData = async (odoo, call, user) => {
    odoo.connect((err, response) => {
        if (err) {
            return call(response, false)
        } else {
            const args = [[
                0,
                { version: 1.3, product_write_date: null, error_write_date: null, documento_write_date: null }
            ]]
            const paramsOdoo = {
                model: "movilgo.webservice",
                method: "asignarDatos",
            };
            return odoo.execute_kw(paramsOdoo.model, paramsOdoo.method, args, async (err, response) => {
                if (err) {
                    call(2, false, err);
                    return false;
                } else {
                    if (response.parent_id == idCardoso) {
                        localStorage.setItem("NombrePadre","cardoso")
                       /* console.log("id del padre del if cardoso") */
                    }

                    let data = {};
                    if (response.permiso === 'cobrador') {
                        data = {
                            user: user,
                            products: {}
                        }
                        if (response.errores) {
                            SaveErrores(response.errores)
                        }
                        localStorage.setItem("Permission", "" + response.permiso);
                        call(data, true)
                    } else {
                            console.log("el response de asignarDatos: ", response);
                            localStorage.setItem("idPadre", response.parent_id)
                        if (response.productos) {
                            SaveProducts(response.productos);
                        }
                        if (response.errores) {
                            SaveErrores(response.errores)
                        }
                        localStorage.setItem("Permission", "" + response.permiso);
                        data = {
                            user: user,
                            products: listProducts
                        }
                        if (response) {
                            call(data, true)
                        } else {
                            call(0, false)
                        }
                    }

                }
            });
        }
    })
}

const SaveErrores = async (errores) => {
    localStorage.setItem("Errores", JSON.stringify(errores));
}

const SaveProducts = async (products) => {
    /* console.log("PRODUCTS - login.service", products) */
    listProducts = {};
    /* console.log("listProductslistProducts", listProducts) */
    products.forEach(element => {
        /* console.log("ELEMENT INICIAL", element) */
        if (element.active) {
            if (element.categ_id_name === "Recargas") {
                if (listProducts.recarga) {
                    listProducts.recarga.push(element)
                } else {
                    listProducts['recarga'] = [];
                    listProducts.recarga.push(element)
                }
            } 
            else if (element.categ_id_name === "Todo incluido" ||
                element.categ_id_name === "Voz" ||
                element.categ_id_name === "Datos" ||
                element.categ_id_name === "Voz y Datos" ||
                element.categ_id_name === "TV" ||
                element.categ_id_name === "Aplicaciones") {
                if (listProducts.paquete) {
                    listProducts.paquete.push(element);
                } else {
                    listProducts['paquete'] = [];
                    listProducts.paquete.push(element)
                }
            } else if (element.categ_id_name === "Rifas") {

                if (listProducts.rifas) {
                    listProducts.rifas.push(element)
                } else {
                    listProducts['rifas'] = [];
                    listProducts.rifas.push(element)
                }
            } else if (element.categ_id_name === "TV") {
                if (listProducts.tv) {
                    listProducts.tv.push(element)
                } else {
                    listProducts['tv'] = [];
                    listProducts.tv.push(element)
                }
            } else if (element.categ_id_name === "Pines") {
                //console.log("PINES1 imagenes: ",listProducts.pines)
                if (listProducts.pinPines) {
                    listProducts.pinPines.push(element)
                } else {
                    listProducts['pinPines'] = [];
                    listProducts.pinPines.push(element);
                }

            } //CONTENIDO DE PAQUETES DE PINES
            else if (element.tipo === "pines") {
                //console.log("PINES2 contenido: ",listProducts.paquetesPines)                
                if (listProducts.paquetesPines) {
                    listProducts.paquetesPines.push(element)
                } else {
                    listProducts['paquetesPines'] = [];
                    listProducts.paquetesPines.push(element);
                }
            } else if (element.categ_id_name === "Recargas deportivas") {
                if (listProducts.recargasDeportivas) {
                    listProducts.recargasDeportivas.push(element)
                } else {
                    listProducts['recargasDeportivas'] = [];
                    listProducts.recargasDeportivas.push(element)
                }
            } else if (element.categ_id_name === "Soat") {
                //console.log("SOAT: ", element)
                if (listProducts.soats) {
                    listProducts.soats.push(element)
                } else {
                    listProducts['soats'] = [];
                    listProducts.soats.push(element)
                }
            } else if (element.categ_id_name === "Mitecnova") {
//                console.log("MITECNOVA: ", element)
                if (listProducts.mitecnova) {
                    listProducts.mitecnova.push(element)
                } else {
                    listProducts['mitecnova'] = [];
                    listProducts.mitecnova.push(element)
                }
            } else if (element.categ_id_name === "Certificados") {
                //console.log("CERTIFICADOS: ",listProducts.certificados)

                if (listProducts.certificados) {
                    listProducts.certificados.push(element)
                    
                } else {
                    listProducts['certificados'] = [];
                    listProducts.certificados.push(element)
                    //console.log("certificadosave", listProducts.certificados)
                }
            } else if (element.name === "Carga Bolsa") {
                localStorage.setItem("CargaBolsa", element.id);
            } else if (element.categ_id_name === "Bingo") {
                if (listProducts.bingo) {
                    listProducts.bingo.push(element)
                } else {
                    listProducts['bingo'] = [];
                    listProducts.bingo.push(element)
                }
            }
        }
    })

}


