import React from 'react';
import { ButtonImage } from '../../../shared/components/buttons-services';

export const ListServices = (props) =>{

    const {services = [], onClick }=props;

    return (
        <div>

            {services.map((service, i) => {
                return (
                    <ButtonImage key={i} onClick={() => onClick(service.source)} image={service.image} />
                )
            })
            }

        </div>
      
    )
}